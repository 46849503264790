import React, { useEffect, useState } from "react";
import Header from "./HomeComp/Header";
import ProfileSidebar from "./components/ProfileSidebar";
import {
  N_freezeAccount,
  N_getUserProfile,
  N_resetPassword,
  N_sendOTPMobile,
  N_sendUpdateEmail,
  N_setGoogleAuth,
  N_setGoogleAuthOTP,
  N_verifyOTPMobile,
  N_verifyUpdateEmail
} from "./redux/helpers/api_functions_new";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./components/Loader";
import { Link } from "react-router-dom";
import { AiOutlineEdit, AiOutlineUserDelete } from "react-icons/ai";
import { BsShieldCheck } from "react-icons/bs";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FBTCins, FaSortDown, FaSortUp, FaKey } from "react-icons/fa";
import { Sparklines, SparklinesLine } from "react-sparklines";
import Footer from "./HomeComp/Footer";
import { NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { user_logout } from "./redux/actions/authActions";
import {
  MdDisabledVisible,
  MdOutlineMail,
  MdPhoneIphone
} from "react-icons/md";
import { FcGoogle, FcIphone } from "react-icons/fc";
import { LuKey } from "react-icons/lu";
import { RiDeleteBinLine } from "react-icons/ri";
import { PiWarningBold } from "react-icons/pi";
var QRCode = require("qrcode");
export default function AccountSecurity(props) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState();
  const [notp, setNOtp] = useState();
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isNOTPSent, setIsNOTPSent] = useState(false);
  const [isMOTPSent, setIsMOTPSent] = useState(false);
  const [motp, setMOtp] = useState();
  const [url, setUrl] = useState();
  const [gotp, setGotp] = useState();
  const [userSecret, setUserSecret] = useState();

  const [isOTPVerified, setIsOTPVerified] = useState();
  const { user, profile } = useSelector((state) => state.AuthReducer);
  // const [loading, setLoading] = React.useState(true);
  // const [profile, setProfile] = React.useState({});
  // useEffect(() => {
  //   N_getUserProfile(user?.params ? user.params.user_id : user.user_id)
  //     .then((d) => {
  //       // console.log("userProfile",d?.params.profile_info);
  //       if (d.status === 200) {
  //         setProfile(d?.params.profile_info);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }, []);
  return (
    <>
      <Header {...props} />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <ProfileSidebar />
        </div>

        <main className="s-layout__content">
          <div className="dashboard_header">
            <h3 className="header_title">Security Settings</h3>
            <div>
              <span className="custom_badge bg-soft-success rounded-pill">
                <BsShieldCheck className="me-1" />
                Security:{" "}
                {profile?.authenticator == 2
                  ? "High"
                  : profile?.authenticator == 1
                  ? "Medium"
                  : "Low"}
              </span>
            </div>
          </div>
          <div className="container-fluid">
            <div className="account_page">
              {/*----------Two-Factor Authentication (2FA)  column---------- */}
              <div className="col-lg-12 mb-5">
                <div className="account_page_subheading mb-2">
                  Two-Factor Authentication (2FA)
                </div>
                <div className="text-lgray fs-12">
                  Choose [Google Verification + Email ] or [Mobile Phone ] to
                  ensure the safety of your assets.
                </div>
                <div className="row mt-4">
                  <section>
                    <div className="tablelike_box">
                      <div className="d-flex gap-1">
                        <div className="circle_backdrop">
                          <MdOutlineMail className="fs-4 text-pink" />
                        </div>
                        <div>
                          <h5 className="mb-0 fs-16">Email</h5>
                          <span className="text-lgray">
                            Used to verify withdrawals, API creation, etc.
                          </span>
                        </div>
                      </div>

                      <div className="call_action">
                        {profile?.email ? (
                          <div className="call_action_left">
                            <span>
                              <i class="fa-solid fa-circle-check text-green me-1"></i>
                            </span>
                            <span className="text-truncate">
                              {profile?.email?.split("").slice(0, 2).join("") +
                                "***@***" +
                                profile?.email?.substr(
                                  profile?.email.indexOf(".")
                                )}
                            </span>
                          </div>
                        ) : (
                          <div className="call_action_right">
                            <span>
                              <i class="fa-solid fa-circle-exclamation me-1 text-warning"></i>
                            </span>
                          </div>
                        )}

                        <div>
                          <a
                            className="btn btn-outline-pink"
                            data-bs-toggle="collapse"
                            href="#email_change"
                          >
                            Change
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="container">
                      <div className="collapse" id="email_change">
                        <div className="row my-4">
                          <div className="col-lg-5 m-auto">
                            <div className="jambotron shadow-sm">
                              <h4 className="text-center mb-3">
                                {" "}
                                Change Email
                              </h4>
                              <div class="alert alert-warning fs-12">
                                <div className="d-flex gap-3">
                                  <div>
                                    {" "}
                                    <i
                                      class="fa fa-info-circle fs-5 text-warning"
                                      aria-hidden="true"
                                    ></i>
                                  </div>

                                  <div className="">
                                    1. To ensure security, withdrawals, P2P
                                    transactions, and red envelopes will be
                                    temporarily unavailable for 24 hours after
                                    changing or unbinding an email.
                                    <br />
                                    2. Emails that have been unbound or changed
                                    in the past 30 days cannot be used to
                                    register new accounts
                                  </div>
                                </div>
                              </div>

                              <div class="">
                                {!isOTPVerified ? (
                                  <div class="mb-4">
                                    <label class="small text-muted">
                                      Email Verification Code
                                    </label>
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        value={otp}
                                        onChange={(e) => {
                                          setOtp(e.target.value);
                                        }}
                                      />
                                      <span class="input-group-text">
                                        <a
                                          className="text-lgray"
                                          onClick={() => {
                                            N_sendUpdateEmail(
                                              user?.params
                                                ? user.params.user_id
                                                : user.user_id,
                                              email,
                                              0
                                            ).then((res) => {
                                              if (res.status == 200) {
                                                setIsOTPSent(true);
                                                NotificationManager.success(
                                                  res.message
                                                );
                                              }
                                            });
                                          }}
                                        >
                                          {isOTPSent
                                            ? "Resend Code"
                                            : "Send Code"}
                                        </a>
                                        {isOTPSent ? (
                                          <a
                                            className="ms-1"
                                            onClick={() => {
                                              N_verifyUpdateEmail(
                                                user?.params
                                                  ? user.params.user_id
                                                  : user.user_id,
                                                email,
                                                otp,
                                                0
                                              ).then((res) => {
                                                if (res.status == 200) {
                                                  setIsOTPVerified(true);
                                                  NotificationManager.success(
                                                    res.message
                                                  );
                                                }
                                              });
                                            }}
                                          >
                                            Verify code
                                          </a>
                                        ) : null}
                                      </span>
                                    </div>
                                  </div>
                                ) : null}
                                {isOTPVerified ? (
                                  <div class="mb-4">
                                    <label for="Fiat" class="text-muted">
                                      New Email ID
                                    </label>{" "}
                                    <div class="input-group">
                                      <input
                                        type="email"
                                        class="form-control"
                                        value={email}
                                        onChange={(e) => {
                                          setEmail(e.target.value);
                                        }}
                                      />
                                      <span class="input-group-text">
                                        <a
                                          className="text-lgray"
                                          onClick={() => {
                                            if (email) {
                                              N_sendUpdateEmail(
                                                user?.params
                                                  ? user.params.user_id
                                                  : user.user_id,
                                                email,
                                                1
                                              ).then((res) => {
                                                if (res.status == 200) {
                                                  setIsNOTPSent(true);
                                                  NotificationManager.success(
                                                    res.message
                                                  );
                                                }
                                              });
                                            } else {
                                              NotificationManager.error(
                                                "Enter new Email"
                                              );
                                            }
                                          }}
                                        >
                                          {isNOTPSent
                                            ? "Resend Code"
                                            : "Send Code"}
                                        </a>
                                      </span>
                                    </div>
                                    <label for="Fiat" class="text-muted">
                                      OTP
                                    </label>{" "}
                                    <input
                                      type="number"
                                      class="form-control"
                                      value={notp}
                                      onChange={(e) => {
                                        setNOtp(e.target.value);
                                      }}
                                    />
                                  </div>
                                ) : null}
                                {isOTPVerified ? (
                                  <div className="d-grid">
                                    <button
                                      className="btn btn-pink"
                                      onClick={() => {
                                        if (email && notp) {
                                          N_verifyUpdateEmail(
                                            user?.params
                                              ? user.params.user_id
                                              : user.user_id,
                                            email,
                                            notp,
                                            1
                                          ).then((res) => {
                                            if (res.status == 200) {
                                              NotificationManager.success(
                                                res.message
                                              );
                                              setTimeout(() => {
                                                document.location.reload();
                                              }, 5000);
                                            }
                                          });
                                        } else {
                                          NotificationManager.error(
                                            "fill all data"
                                          );
                                        }
                                      }}
                                    >
                                      Update
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className="tablelike_box">
                      <div className="d-flex gap-1">
                        <div className="circle_backdrop">
                          <MdPhoneIphone className="fs-4 text-pink" />
                        </div>
                        <div>
                          <h5 className="mb-0 fs-16">Phone</h5>
                          <span className="text-lgray">
                            Used to verify account logins, withdrawals, API
                            creation, etc. Can be switched with Google
                            Verification (and vice versa). Click here to view
                            the list of supported countries/regions.
                          </span>
                        </div>
                      </div>

                      <div className="call_action">
                        {profile?.mobile_number ? (
                          <div className="call_action_left">
                            <span>
                              <i class="fa-solid fa-circle-check text-green me-1"></i>
                            </span>
                            <span>
                              **{profile?.mobile_number?.toString().slice(-4)}
                            </span>
                          </div>
                        ) : (
                          <div className="call_action_left">
                            <span>
                              <i class="fa-solid fa-circle-exclamation me-1 text-warning"></i>
                            </span>
                          </div>
                        )}

                        <div className="call_action_right">
                          <a
                            data-bs-toggle="collapse"
                            href="#phone_change"
                            className="btn btn-outline-pink"
                          >
                            Change
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="container">
                      <div className="collapse" id="phone_change">
                        <div className="row my-4">
                          <div className="col-lg-5 m-auto">
                            <div className="jambotron shadow-sm">
                              <h4 className="text-center mb-3">
                                {" "}
                                Change Phone
                              </h4>
                              <div class="">
                                {!isOTPVerified ? (
                                  <div class="mb-4">
                                    <label class="small text-muted">
                                      Email Verification Code
                                    </label>
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        value={otp}
                                        onChange={(e) => {
                                          setOtp(e.target.value);
                                        }}
                                      />
                                      <span class="input-group-text">
                                        <a
                                          className="text-lgray"
                                          onClick={() => {
                                            N_sendUpdateEmail(
                                              user?.params
                                                ? user.params.user_id
                                                : user.user_id,
                                              email,
                                              0
                                            ).then((res) => {
                                              if (res.status == 200) {
                                                setIsOTPSent(true);
                                                NotificationManager.success(
                                                  res.message
                                                );
                                              }
                                            });
                                          }}
                                        >
                                          {isOTPSent
                                            ? "Resend Code"
                                            : "Send Code"}
                                        </a>
                                        {isOTPSent ? (
                                          <a
                                            className="ms-1 custom_badge bg-soft-primary"
                                            onClick={() => {
                                              N_verifyUpdateEmail(
                                                user?.params
                                                  ? user.params.user_id
                                                  : user.user_id,
                                                email,
                                                otp,
                                                0
                                              ).then((res) => {
                                                if (res.status == 200) {
                                                  setIsOTPVerified(true);
                                                  NotificationManager.success(
                                                    res.message
                                                  );
                                                }
                                              });
                                            }}
                                          >
                                            Verify code
                                          </a>
                                        ) : null}
                                      </span>
                                    </div>
                                  </div>
                                ) : null}
                                {isOTPVerified ? (
                                  <div class="mb-4">
                                    <label for="Fiat" class="text-muted">
                                      New Phone Number
                                    </label>{" "}
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        value={phone}
                                        onChange={(e) => {
                                          setPhone(e.target.value);
                                        }}
                                      />
                                      <span class="input-group-text">
                                        <a
                                          className="text-lgray"
                                          onClick={() => {
                                            if (phone) {
                                              N_sendOTPMobile(
                                                user?.params
                                                  ? user.params.user_id
                                                  : user.user_id,
                                                phone
                                              ).then((res) => {
                                                if (res.status == 200) {
                                                  setIsMOTPSent(true);
                                                  NotificationManager.success(
                                                    res.message
                                                  );
                                                }
                                              });
                                            } else {
                                              NotificationManager.error(
                                                "Enter new Email"
                                              );
                                            }
                                          }}
                                        >
                                          {isMOTPSent
                                            ? "Resend Code"
                                            : "Send Code"}
                                        </a>
                                      </span>
                                    </div>
                                    <label for="Fiat" class="text-muted">
                                      OTP
                                    </label>{" "}
                                    <input
                                      type="number"
                                      class="form-control"
                                      value={motp}
                                      onChange={(e) => {
                                        setMOtp(e.target.value);
                                      }}
                                    />
                                  </div>
                                ) : null}
                                {isOTPVerified ? (
                                  <div className="d-grid">
                                    <button
                                      className="btn btn-pink"
                                      onClick={() => {
                                        if (phone && motp) {
                                          N_verifyOTPMobile(
                                            user?.params
                                              ? user.params.user_id
                                              : user.user_id,
                                            motp
                                          ).then((res) => {
                                            if (res.status == 200) {
                                              NotificationManager.success(
                                                res.message
                                              );
                                              setTimeout(() => {
                                                document.location.reload();
                                              }, 5000);
                                            }
                                          });
                                        } else {
                                          NotificationManager.error(
                                            "fill all data"
                                          );
                                        }
                                      }}
                                    >
                                      Update
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className="tablelike_box">
                      <div className="d-flex gap-1  ">
                        <div className="circle_backdrop">
                          <FcGoogle className="fs-4" />
                        </div>

                        <div>
                          <h5 className="mb-0 fs-16">Google Verification</h5>
                          <span className="text-lgray">
                            Used to verify account logins, withdrawals, API
                            creation, etc. Can be switched with SMS verification
                            (and vice versa). Instructions
                          </span>
                        </div>
                      </div>

                      <div className="call_action">
                        {profile.authenticator == 2 ? (
                          <div className="call_action_left">
                            <i class="fa-solid fa-circle-check text-green me-1"></i>
                            <span>Enable</span>
                          </div>
                        ) : (
                          <div className="call_action_left">
                            <i class="fa-solid fa-circle-exclamation me-1 text-warning"></i>
                            <span className="text-muted">Not Enabled</span>
                          </div>
                        )}

                        <div className="call_action_right">
                          <a
                            className="btn btn-outline-pink"
                            data-bs-toggle="collapse"
                            href="#google_change"
                          >
                            Change
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="collapse" id="google_change">
                        <div className="row my-4">
                          <div className="col-lg-5 m-auto">
                            <h4> Change Google Authenticator</h4>
                            <div className="jambotron shadow-sm">
                              <div class="cstm_form--">
                                {!isOTPVerified ? (
                                  <div class="mb-4">
                                    <label class="small text-muted">
                                      Email Verification Code
                                    </label>
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        value={otp}
                                        onChange={(e) => {
                                          setOtp(e.target.value);
                                        }}
                                      />
                                      <span class="input-group-text">
                                        <a
                                          className="text-lgray"
                                          onClick={() => {
                                            N_sendUpdateEmail(
                                              user?.params
                                                ? user.params.user_id
                                                : user.user_id,
                                              email,
                                              0
                                            ).then((res) => {
                                              if (res.status == 200) {
                                                setIsOTPSent(true);
                                                NotificationManager.success(
                                                  res.message
                                                );
                                              }
                                            });
                                          }}
                                        >
                                          {isOTPSent
                                            ? "Resend Code"
                                            : "Send Code"}
                                        </a>
                                        {isOTPSent ? (
                                          <a
                                            className="ms-1 custom_badge bg-soft-primary"
                                            onClick={() => {
                                              N_verifyUpdateEmail(
                                                user?.params
                                                  ? user.params.user_id
                                                  : user.user_id,
                                                email,
                                                otp,
                                                0
                                              ).then((res) => {
                                                if (res.status == 200) {
                                                  setIsOTPVerified(true);
                                                  NotificationManager.success(
                                                    res.message
                                                  );
                                                  N_setGoogleAuth(
                                                    user?.params
                                                      ? user.params.user_id
                                                      : user.user_id
                                                  ).then((d) => {
                                                    if (d.status == 200) {
                                                      QRCode.toDataURL(
                                                        d.secret.otpauth_url,
                                                        function (
                                                          err,
                                                          data_url
                                                        ) {
                                                          setUrl(data_url);
                                                          setUserSecret(
                                                            d.secret.base32
                                                          );
                                                        }
                                                      );
                                                    }
                                                  });
                                                }
                                              });
                                            }}
                                          >
                                            Verify code
                                          </a>
                                        ) : null}
                                      </span>
                                    </div>
                                  </div>
                                ) : null}
                                {isOTPVerified ? (
                                  <div class="mb-4">
                                    {profile?.authenticator !== 2 ? (
                                      <>
                                        <div className="form-group text-center">
                                          <img src={url} />
                                        </div>
                                        <div className="form-group text-center">
                                          {userSecret}
                                        </div>
                                        <label for="Fiat" class="text-muted">
                                          OTP
                                        </label>{" "}
                                        <input
                                          type="number"
                                          class="form-control"
                                          value={gotp}
                                          onChange={(e) => {
                                            setGotp(e.target.value);
                                          }}
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                ) : null}
                                {isOTPVerified ? (
                                  <div className="d-grid">
                                    {profile?.authenticator !== 2 ? (
                                      <button
                                        className="btn btn-pink"
                                        onClick={() => {
                                          if (gotp) {
                                            N_setGoogleAuthOTP(
                                              user?.params
                                                ? user.params.user_id
                                                : user.user_id,
                                              2,
                                              gotp,
                                              "g"
                                            ).then((data) => {
                                              if (data.status === 200) {
                                                NotificationManager.success(
                                                  data.message
                                                );
                                                window.location.reload();
                                              } else {
                                                NotificationManager.error(
                                                  data.message
                                                );
                                              }
                                            });
                                          } else {
                                            NotificationManager.error(
                                              "fill all data"
                                            );
                                          }
                                        }}
                                      >
                                        Update
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-pink"
                                        onClick={() => {
                                          N_setGoogleAuthOTP(
                                            user?.params
                                              ? user.params.user_id
                                              : user.user_id,
                                            0
                                          ).then((data) => {
                                            if (data.status === 200) {
                                              NotificationManager.success(
                                                data.message
                                              );
                                              window.location.reload();
                                            } else {
                                              NotificationManager.error(
                                                data.message
                                              );
                                            }
                                          });
                                        }}
                                      >
                                        Disable
                                      </button>
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>

              {/* ---------Advanced Settings Column ----------*/}

              <div className="col-lg-12">
                <div className="account_page_subheading mb-2">
                  Advanced Settings
                </div>
                <div>
                  <div className="row mt-4">
                    <div>
                      <div className="tablelike_box_simple mb-3">
                        <div className="row">
                          <div className="col-lg-8">
                            <div className="d-flex gap-3">
                              <div className="circle_backdrop">
                                <LuKey className="fs-4 text-pink" />
                              </div>
                              <div>
                                <h5 className="mb-0 fs-16">Login Password</h5>
                                <span className="text-lgray">
                                  For account login.
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 text-end">
                            <a
                              className="btn btn-outline-pink"
                              data-bs-toggle="collapse"
                              href="#password_change"
                            >
                              Change
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="collapse" id="password_change">
                        <div className="row my-4">
                          <div className="col-lg-5 m-auto">
                            <h4> Change Password</h4>
                            <div className="jambotron-outline">
                              <div class="cstm_form">
                                {!isOTPVerified ? (
                                  <div class="mb-4">
                                    <label class="small text-muted">
                                      Email Verification Code
                                    </label>
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        value={otp}
                                        onChange={(e) => {
                                          setOtp(e.target.value);
                                        }}
                                      />
                                      <span class="input-group-text">
                                        <a
                                          className="custom_badge bg-soft-pink"
                                          onClick={() => {
                                            N_sendUpdateEmail(
                                              user?.params
                                                ? user.params.user_id
                                                : user.user_id,
                                              email,
                                              0
                                            ).then((res) => {
                                              if (res.status == 200) {
                                                setIsOTPSent(true);
                                                NotificationManager.success(
                                                  res.message
                                                );
                                              }
                                            });
                                          }}
                                        >
                                          {isOTPSent
                                            ? "Resent Code"
                                            : "Sent Code"}
                                        </a>
                                        {isOTPSent ? (
                                          <a
                                            className="ms-1 custom_badge bg-soft-primary"
                                            onClick={() => {
                                              N_verifyUpdateEmail(
                                                user?.params
                                                  ? user.params.user_id
                                                  : user.user_id,
                                                email,
                                                otp,
                                                0
                                              ).then((res) => {
                                                if (res.status == 200) {
                                                  setIsOTPVerified(true);
                                                  NotificationManager.success(
                                                    res.message
                                                  );
                                                  N_setGoogleAuth(
                                                    user?.params
                                                      ? user.params.user_id
                                                      : user.user_id
                                                  ).then((d) => {
                                                    if (d.status == 200) {
                                                      QRCode.toDataURL(
                                                        d.secret.otpauth_url,
                                                        function (
                                                          err,
                                                          data_url
                                                        ) {
                                                          setUrl(data_url);
                                                          setUserSecret(
                                                            d.secret.base32
                                                          );
                                                        }
                                                      );
                                                    }
                                                  });
                                                }
                                              });
                                            }}
                                          >
                                            Verify code
                                          </a>
                                        ) : null}
                                      </span>
                                    </div>
                                  </div>
                                ) : null}
                                {isOTPVerified ? (
                                  <div class="mb-4">
                                    <label for="Fiat" class="text-muted">
                                      Old password
                                    </label>{" "}
                                    <input
                                      type="password"
                                      class="form-control"
                                      value={oldPassword}
                                      onChange={(e) => {
                                        setOldPassword(e.target.value);
                                      }}
                                    />
                                    <label for="Fiat" class="text-muted">
                                      New Password
                                    </label>{" "}
                                    <input
                                      type="password"
                                      class="form-control"
                                      value={password}
                                      onChange={(e) => {
                                        setPassword(e.target.value);
                                      }}
                                    />
                                    <label for="Fiat" class="text-muted">
                                      Confirm Password
                                    </label>{" "}
                                    <input
                                      type="password"
                                      class="form-control"
                                      value={confirm_password}
                                      onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                      }}
                                    />
                                  </div>
                                ) : null}
                                {isOTPVerified ? (
                                  <div className="d-grid">
                                    <button
                                      className="btn btn-pink"
                                      onClick={() => {
                                        if (
                                          oldPassword &&
                                          password &&
                                          confirm_password
                                        ) {
                                          N_resetPassword(
                                            user?.params
                                              ? user.params.user_id
                                              : user.user_id,
                                            oldPassword,
                                            password,
                                            confirm_password
                                          ).then((data) => {
                                            if (data.status === 200) {
                                              NotificationManager.success(
                                                data.message
                                              );
                                              setTimeout(() => {
                                                window.location.reload();
                                              }, 2000);
                                            } else {
                                              NotificationManager.error(
                                                data.message
                                              );
                                            }
                                          });
                                        } else {
                                          NotificationManager.error(
                                            "fill all data"
                                          );
                                        }
                                      }}
                                    >
                                      Update
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="tablelike_box mb-3">
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="d-flex gap-3">
                        <div className="circle_backdrop">
                          <img
                            alt=""
                            src="https://assets.staticimg.com/ucenter-web/1.0.12/svg/sec-email.59d1741d.svg"
                          />
                        </div>
                        <div>
                          <h5 className="mb-0 fs-16">Restrict Login IP</h5>
                          <span className="text-lgray">
                            When enabled, you will be logged out automatically
                            whenever your login IP changes.
                          </span>
                        </div>
                      </div>

                    <div className="col-lg-4 text-end">
                      <a href="#" className="btn btn-outline-pink">
                        Change
                      </a>
                    </div>
                  </div>
                </div> */}
                    {/* <div className="tablelike_box mb-3">
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="d-flex gap-3">
                        <div className="circle_backdrop">
                          <img
                            alt=""
                            src="https://assets.staticimg.com/ucenter-web/1.0.12/svg/sec-email.59d1741d.svg"
                          />
                        </div>
                        <div>
                          <h5 className="mb-0 fs-16">Trading Password</h5>
                          <span className="text-lgray">
                            Used to verify transactions, withdrawals, API
                            creation, etc. Be sure to use a strong password and
                            keep it secure.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 text-end">
                      <a href="#" className="btn btn-outline-pink">
                        Change
                      </a>
                    </div>
                  </div>
                </div> */}
                    {/* <div className="tablelike_box mb-3">
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="d-flex gap-3">
                        <div className="circle_backdrop">
                          <img
                            alt=""
                            src="https://assets.staticimg.com/ucenter-web/1.0.12/svg/sec-email.59d1741d.svg"
                          />
                        </div>
                        <div>
                          <h5 className="mb-0 fs-16">
                            Email Anti-Phishing Code
                          </h5>
                          <span className="text-lgray">
                            Displayed in emails from KuCoin to safeguard against
                            phishing attempts.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 text-end">
                      <a href="#" className="btn btn-outline-pink">
                        Change
                      </a>
                    </div>
                  </div>
                </div>
                <div className="tablelike_box mb-3">
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="d-flex gap-3">
                        <div className="circle_backdrop">
                          <img
                            alt=""
                            src="https://assets.staticimg.com/ucenter-web/1.0.12/svg/sec-email.59d1741d.svg"
                          />
                        </div>
                        <div>
                          <h5 className="mb-0 fs-16">
                            Login Anti-Phishing Code
                          </h5>
                          <span className="text-lgray">
                            Displayed on the login page.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 text-end">
                      <a href="#" className="btn btn-outline-pink">
                        Change
                      </a>
                    </div>
                  </div>
                </div>
                <div className="tablelike_box mb-3">
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="d-flex gap-3">
                        <div className="circle_backdrop">
                          <img
                            alt=""
                            src="https://assets.staticimg.com/ucenter-web/1.0.12/svg/sec-email.59d1741d.svg"
                          />
                        </div>
                        <div>
                          <h5 className="mb-0 fs-16">
                            Withdrawal Anti-Phishing Code
                          </h5>
                          <span className="text-lgray">
                            Confirmation windows will include your configured
                            anti-phishing code.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 text-end">
                      <a href="#" className="btn btn-outline-pink">
                        Settings
                      </a>
                    </div>
                  </div>
                </div>
                <div className="tablelike_box mb-3">
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="d-flex gap-3">
                        <div className="circle_backdrop">
                          <img
                            alt=""
                            src="https://assets.staticimg.com/ucenter-web/1.0.12/svg/sec-email.59d1741d.svg"
                          />
                        </div>
                        <div>
                          <h5 className="mb-0 fs-16">Login Password</h5>
                          <span className="text-lgray">For account login.</span>
                        </div>
                      </div>useDispatch
                </div>
                <div className="tablelike_box mb-3">
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="d-flex gap-3">
                        <div className="circle_backdrop">
                          <img
                            alt=""
                            src="https://assets.staticimg.com/ucenter-web/1.0.12/svg/sec-email.59d1741d.svg"
                          />
                        </div>
                        <div>
                          <h5 className="mb-0 fs-16">Login Password</h5>
                          <span className="text-lgray">For account login.</span>
                        </div>
                      </div>
                    </div>useDispatch
                        Change
                      </a>
                    </div>
                  </div>
                </div> */}
                  </div>

                  {/* ---------Advanced Settings Column ----------*/}
                  <div className="col-lg-12 mt-4">
                    <div className="account_page_subheading mb-2">Account</div>
                    <section>
                      <div className="tablelike_box_simple mb-3">
                        <div className="row">
                          <div className="col-lg-8">
                            <div className="d-flex gap-3">
                              <div className="circle_backdrop">
                                <MdDisabledVisible className="fs-4 text-pink" />
                              </div>
                              <div>
                                <h5 className="mb-0 fs-16">Freeze Account</h5>
                                <span className="text-lgray">
                                  If you freeze your account, trading and
                                  withdrawals will be disabled. You can request
                                  to unfreeze your account the next time you log
                                  in.
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 text-end">
                            <a
                              className="btn btn-outline-pink"
                              data-bs-toggle="modal"
                              data-bs-target="#freez_confirmation"
                            >
                              Freeze
                            </a>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section>
                      <div className="tablelike_box_simple mb-3">
                        <div className="row">
                          <div className="col-lg-8">
                            <div className="d-flex gap-3">
                              <div className="circle_backdrop">
                                <AiOutlineUserDelete className="fs-4 text-pink" />
                              </div>
                              <div>
                                <h5 className="mb-0 fs-16">Delete Account</h5>
                                <span className="text-lgray">
                                  Warning: Once you delete your account, it
                                  cannot be recovered and you will be unable to
                                  use any of its associated services.
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 text-end">
                            <a
                              className="btn btn-outline-pink"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_confirmation"
                            >
                              Delete
                            </a>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div id="freez_confirmation" class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-sm">
          <div class="modal-content">
            <div class="modal-header bg-pink">
              <h4 class="modal-title text-white">Freeze Account</h4>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="text-center">
                <div className="fs-16 mb-3">
                  Are you sure you want to freeze?{" "}
                </div>

                <div className="">
                  <a className="btn btn-light" data-bs-dismiss="modal">
                    Cancel
                  </a>
                  <a
                    className="btn btn-outline-pink ms-2"
                    onClick={() => {
                      N_freezeAccount(
                        user?.params ? user.params.user_id : user.user_id,
                        "freeze"
                      ).then((data) => {
                        if (data.status == 200) {
                          NotificationManager.success(data.message);
                          setTimeout(() => {
                            dispatch(
                              user_logout(() => {
                                props.history?.replace("/login");
                              })
                            );
                            document.location.reload();
                          }, 3000);
                        }
                      });
                    }}
                  >
                    Freeze
                  </a>
                </div>
              </div>
            </div>
            <div class="modal-footer border-top-0 pb-3 text-start"></div>
          </div>
        </div>
      </div>

      <div id="delete_confirmation" class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-sm">
          <div class="modal-content text-center">
            <div class="modal-header border-bottom-0">
              <h4 class="modal-title">Delete Account</h4>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body text-center">
              <div className="fs-16 mb-3"> Are you sure to Delete your account ?</div>
              <div class="modal-footer border-top-0 text-center">
              <a
                className="btn btn-outline-pink"
                onClick={() => {
                  N_freezeAccount(
                    user?.params ? user.params.user_id : user.user_id,
                    "freeze"
                  ).then((data) => {
                    if (data.status == 200) {
                      NotificationManager.success(data.message);
                      setTimeout(() => {
                        dispatch(
                          user_logout(() => {
                            props.history?.replace("/");
                          })
                        );
                        document.location.reload();
                      }, 3000);
                    }
                  });
                }}
              >
                Yes
              </a>
              <a className="btn btn-light"  data-bs-dismiss="modal">No</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
