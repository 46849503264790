import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FaCoins, FaSortDown, FaSortUp } from "react-icons/fa";
import {
  N_GetAllSymbol,
  N_crypto_withdraw_New,
  N_crypto_withdraw_Otp,
  N_crypto_withdraw_Otp_Email,
  N_getBlockchain,
  N_getBlockchainData,
  N_withdrawHistory
} from "./redux/helpers/api_functions_new";
export default function AssetsWithdraw() {
  const [blockchain, setBlockchain] = useState();
  const [currency, setCurrency] = useState();
  const [contract, setContract] = useState();
  const [coindata, setCoinData] = useState();
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState();
  const [transection_id, setTransectionId] = useState();
  const [wallettype, setwallettype] = useState();
  const [wallets, setWallets] = useState();
  const [wallet_data, setWalletData] = useState();
  const [withdrawhistory, setWithdrawHistory] = useState();
  const [symbolData, setSymbolData] = useState();
  const [isSelected, setIsSelected] = useState(0);
  const [withdraw_address, setWithdrawAddress] = useState();
  const [withdrawAmount, setWithdrawAmount] = useState();
  const [motp, setMOtp] = useState();
  const [eotp, setEOtp] = useState();
  const [search, setSearch] = useState('');
  const { user } = useSelector((state) => state.AuthReducer);
  const { wallet } = useSelector((state)=>state.coinDBReducer)
  useEffect(() => {
    N_getBlockchain().then((res) => {
      if (res.status == 200) {
        setBlockchain(res.data);
      }
    });
    N_GetAllSymbol().then((res) => {
      setWallets(res);
      const coinsData = res.find((item) => item.symbol == "USDT");
      setCoinData(coinsData);
    });
  }, []);
  useEffect(()=>{
    const walletd = wallet.find((item)=>item.symbol == "USDT");
    setWalletData(walletd)
  }, [wallet])
  useEffect(()=>{
    if(coindata) {
      let data = {
        user_id:user?.params ? user.params.user_id : user.user_id,
        symbol:coindata.symbol
      }
      N_withdrawHistory(data)
      .then((data) => {
        if (data.status === 200) {
          setWithdrawHistory(data?.result);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    }
   
  }, [coindata]);
  const walletsdata = wallets && wallets.filter((products)=>{
      return products.symbol.includes(search);
  })
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
        <div className="container">
            <div className="marginTop">
              <div className="account_page">
            <div className="col-lg-12">
              <div className="flex_row mb-4">
                <div className="">
                  <div class="">
                    <h3 className=""> Withdraw Crypto </h3>
                  </div>
                </div>
                <div className="">
                  <a href="/AssetsFiat" className="btn btn-light">
                    Withdraw Fiat{" "}
                    <i class="fa-solid fa-arrow-right-long ms-1"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-7">
                <div className="cstm_form">
                <div class="row_flex_div ">
                        <div class="col_left"></div>
                        <div class="col_right">
                          <label for="inputPassword6" class="">
                            Coin
                          </label>
                        </div>
                      </div>

                      <div class="row_flex_div mb-4">
                        <div class="col_left">
                          <label for="inputPassword6" class="">
                            Select Coin
                          </label>
                        </div>
                        <div class="col_right">
                          <div className="select_box">
                            {" "}
                            <div
                              className="select_box_inner"
                              data-bs-toggle="modal"
                              data-bs-target="#coin_modal"
                            >
                              <div class="d-flex align-items-center">
                                <div>
                                  <img
                                    src={coindata?.icon}
                                    alt="coin symbole"
                                    class="coin_img me-2"
                                  />
                                </div>
                                <div class="product_name">
                                  {coindata?.symbol}{" "}
                                  <span className="unit text-muted">
                                    {coindata?.name}
                                  </span>
                                </div>{" "}
                              </div>
                            </div>
                            <div>
                              {" "}
                              <img
                                src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
                                class="arrow_img"
                                alt="arrow-icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                  <div class="row_flex_div align-items-start ">
                    <div class="col_left d-lg-block d-none">Withdraw to</div>
                    <div class="col_right">
                      <div class="custom_pills mb-4">
                        <div class="nav nav-pills" id="nav-tab" role="tablist">
                          <div
                            class="nav-item nav-link active"
                            href="#wallet_address"
                            data-toggle="tab"
                            style={{ height: '28px' }}
                          >
                            Wallet Address
                          </div>
                          {/* <div
                            class="nav-item nav-link"
                            href="#aco_user"
                            data-toggle="tab"
                            style={{ height: '28px' }}
                          >
                            GlobelX User
                          </div> */}
                        </div>
                      </div>

                      <div className="tab-content">
                        <div
                          className="tab-pane fade active show"
                          id="wallet_address"
                        >
                          <div className="cstm_form">
                            <div className="mb-4">
                              <label class="small text-muted">
                                Wallet Address
                              </label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Wallet Address"
                                  value={withdraw_address}
                                  onChange={(e)=>{
                                    setWithdrawAddress(e.target.value);
                                  }}
                                />
                                {/* <span class="input-group-text">
                                  <i class="fa-regular fa-file text-primary"></i>
                                </span> */}
                              </div>
                            </div>

                            <div className="mb-4">
                              <label class="small text-muted">
                               Withdrawal Amount
                              </label>
                              <div class="input-group">
                                <input
                                  type="number"
                                  class="form-control"
                                  placeholder="Enter Withdrawal Amount"
                                  value={withdrawAmount}
                                  onChange={(e)=>{
                                    setWithdrawAmount(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div class="mb-4">
                              <label for="Fiat" class="text-muted">
                                Network
                              </label>{" "}
                              <div className="select_box">
                                {" "}
                                <div className="select_box_inner">
                                  <div
                                    className="select_box_inner"
                                    data-bs-toggle="modal"
                                    data-bs-target="#network_modal"
                                  >
                                     <div class="product_name">
                                  {symbolData
                                    ? symbolData.symbol +
                                      " ("+symbolData.type+")" +
                                      symbolData.blockchain
                                    : "Select Network"}
                                </div>{" "}
                                  </div>
                                </div>
                                <div>
                                  {" "}
                                  <img
                                    src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
                                    class="arrow_img"
                                    alt="arrow-icon"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane fade " id="aco_user">
                          <div class="mb-4 new_pills">
                            <div
                              class="nav nav-pills"
                              id="nav-tab"
                              role="tablist"
                            >
                              <div
                                class="nav-item nav-link active "
                                href="#email_div"
                                data-toggle="tab"
                              >
                                Email
                              </div>
                              <div
                                class="nav-item nav-link"
                                href="#phone_div"
                                data-toggle="tab"
                              >
                                Phone
                              </div>
                            </div>
                          </div>

                          <div className="tab-content">
                            <div
                              className="tab-pane active fade show"
                              id="email_div"
                            >
                              <div className="cstm_form">
                                <div className="mb-4">
                                  {/* <label class="small text-muted">
                               Email
                              </label> */}
                                  <div class="input-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Enter Email"
                                    />
                                    <span class="input-group-text">
                                      <i class="fa-regular fa-file text-primary"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="tab-pane fade" id="phone_div">
                              <div className="cstm_form">
                                <div className="mb-4">
                                  {/* <label class="small text-muted">
                               Email
                              </label> */}
                                  <div class="input-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Enter Phone"
                                    />
                                    <span class="input-group-text">
                                      <i class="fa-regular fa-file text-primary"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <section>
                        <div className="all_value_grid">
                          <div class="all_value_col">
                            <h6 class="mb-0  fs-12 text-muted">
                              Available Balance
                            </h6>
                            <span class="">{wallet_data?.balance-wallet_data?.locked}</span>
                            <span class="text-muted ms-1">{wallet_data?.symbol}</span>
                          </div>
                          {/* <div class="all_value_col">
                            <h6 class="mb-0  fs-12 text-muted">
                              Minimum Withdrawal
                            </h6>
                            <span class="">0.00</span>
                            <span class="text-muted ms-1">BNB</span>
                          </div> */}
                          <div class="all_value_col">
                            <h6 class="mb-0  fs-12 text-muted">Fee</h6>
                            <span class="">{wallet_data?.withdrawal_fee}</span>
                            <span class="ms-1 text-muted">{wallet_data?.symbol}</span>
                          </div>
                          {/* <div class="all_value_col">
                            <h6 class="mb-0  fs-12 text-muted">
                              24h Withdrawal Limit
                            </h6>
                            <span class="">1,000,000.00</span>
                            <span class="text-muted ms-1">USDT</span>
                          </div> */}
                        </div>
                      </section>
                      <div className="d-grid">
                        <a
                        className="btn btn-warning btn-lg"
                        data-bs-toggle="modal"
                        data-bs-target="#withdrawal_modal"
                        >
                          Withdrawal
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div class="col-lg-4">
                      <div className="jambotron-outline">
                        <div className="account_page_subheading mb-2">
                          Without KYC
                        </div>
                        <span class="badge bg-light border rounded-pill me-2 fs-14 mb-2">
                          <span class="fa fa-circle-check text-green me-2"></span>{" "}
                          Deposit
                        </span>{" "}
                        <span class="badge bg-light border rounded-pill me-2 fs-14 mb-2">
                          <span class="fa fa-circle-check text-green me-2"></span>{" "}
                          Trade
                        </span>{" "}
                        <span class="badge bg-light border rounded-pill me-2 fs-14 mb-2">
                          <span class="fa-solid fa-circle-xmark text-secondary  me-2"></span>{" "}
                          Withdrawal
                        </span>{" "}
                        <span class="badge bg-light border rounded-pill me-2 fs-14 mb-2">
                          <span class="fa-solid fa-circle-xmark text-secondary  me-2"></span>{" "}
                          P2P
                        </span>
                      </div>
                      <div className="jambotron-outline mt-3">
                        <div className="account_page_subheading mb-2">
                          With KYC
                        </div>

                        <span class="badge bg-light border rounded-pill me-2 fs-14 mb-2">
                          <span class="fa fa-circle-check text-green me-2"></span>{" "}
                          Deposit
                        </span>

                        <span class="badge bg-light border rounded-pill me-2 fs-14 mb-2">
                          <span class="fa fa-circle-check text-green me-2"></span>{" "}
                          Trade
                        </span>

                        <span class="badge bg-light border rounded-pill me-2 fs-14 mb-2">
                          <span class="fa fa-circle-check text-green me-2"></span>{" "}
                          Withdrawal
                        </span>

                        <span class="badge bg-light border rounded-pill me-2 fs-14 mb-2">
                          <span class="fa fa-circle-check text-green me-2"></span>{" "}
                          P2P
                        </span>
                      </div>
                    </div>
            </div>

            <div className="col-lg-12 mt-5">
              <div className="flex_row mb-4">
                <div className="">
                  <div class="">
                    <h4 className=""> Recent Withdrawals </h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="overflow-auto mt-3">
                  <div className="table_scroll_div">
              <table className="table global_table">
                <thead>
                  <tr>
                    <th>Symbol</th>
                    <th>Amount</th>
                    <th>Network</th>
                    <th> Withdrawal Address/Account </th>
                    <th>Status</th>
                    <th class="text-end">Time</th>
                  </tr>
                </thead>
                <tbody>
                {withdrawhistory && withdrawhistory.length > 0 ? (
                          withdrawhistory.map((item) => {
                            return (
                            <tr>
                              <td>{item.symbol}</td>
                              <td>{item.amount}</td>
                              <td>{item.network.toUpperCase()}</td>
                              {/* <td>
                                <a class="anchor_link">View Transaction</a>
                              </td> */}
          
                              <td>
                                <span className="address_item">
                                  {item.to_address}
                                  <span class="">
                                    <img src="/img/copy-icon.svg" alt="copy-address" />
                                  </span>
                                </span>
                              </td>
                              <td>{item.status==1?"Completed":item.status==-2?"Rejected":"Pending"}</td>
          
                              <td class="text-end">
                                  {new Date(item.updatedAt).toLocaleString()}
                                </td>
                            </tr>
                            );
                          })
                        ):(
                            <tr>
                              <td colSpan={6} className="text-center">
                                <img
                                  src="/img/no-data.png"
                                  className="no-data-found"
                                />
                              </td>
                            </tr>
                          )}
              

                
                </tbody>
              </table>
              </div>
            </div>

            {/* =============Use this div When data is not configure================ */}

            {/* <div className="col-lg-12">
              <div className="aco_box">
                <div className="page-content-title text-center">
                  <h3 className="">Withdraw Crypto</h3>
                </div>
                <div className="aco_hero mt-4">
                  <div class="pb-3">
                    <h4 className="fw-bold">
                      You must configure the security settings of your account
                      before withdrawal.
                    </h4>
                  </div>

                  <div className="text-lgray">
                    <p>
                      <i className="fa fa-exclamation-circle me-2 text-warning"></i>
                      Set trading password. <a href="#">Configure</a>
                    </p>
                    <p>
                      {" "}
                      <i className="fa fa-exclamation-circle me-2 text-warning"></i>
                      Bind mobile phone or add Google Verification.{" "}
                      <a href="#">Enable</a>
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            {/* =============When data is not configure================ */}
          </div>
          </div></div>
        </main>
      </div>

      <div class="modal coin_modal fade" tabindex="-1" id="coin_modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
              <h4 class="modal-title">Coin</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="m-1">
                <div class="input-group ">
                  <span class="input-group-text bg-transparent">
                    <div class="">
                      <i class="fa fa-search text-lgray"></i>
                    </div>
                  </span>
                  <input
                    type="text"
                    class="form-control text-start mp-exide-form bg-white"
                    aria-label="Text input with checkbox"
                    placeholder="Search "
                    onChange={(e)=>{
                      setSearch(e.target.value.toUpperCase())
                    }}
                  />
                </div>
              </div>

              <div className="mt-4">
                <h6 className="text-lgray">Popular</h6>
                <p class="fs-14">
                  {wallets &&
                    wallets.map((item) => {
                      if (
                        item.symbol == "USDT" ||
                        item.symbol == "ETH" ||
                        item.symbol == "BNB" ||
                        item.symbol == "TRX"
                      ) {
                        return (
                          <span
                            class="label_light me-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                              setCoinData(item);
                              setIsSelected(false);
                              setSymbolData();
                              const wallt_data = wallet.find((dt)=>dt.symbol==item.symbol);
                              setWalletData(wallt_data);
                            }}
                          >
                            {item.symbol}
                          </span>
                        );
                      }
                    })}
                </p>
              </div>

              <div className="custom_scrollbar height250">
                {walletsdata &&
                  walletsdata.map((item) => {
                    if(item.symbol!=='INR') {
                      return (
                        <div
                          class="select_options"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            setCoinData(item);
                            setIsSelected(false);
                            setSymbolData("");
                            const wallt_data = wallet.find((dt)=>dt.symbol==item.symbol);
                            setWalletData(wallt_data);
                          }}
                        >
                          <div>
                            <img
                              src={item.icon}
                              alt="coin symbole"
                              class="market_coin_img"
                            />
                          </div>
                          <div class="product_name">
                            <div class="mb-0 fw-bold">{item.symbol}</div>
                            <div class="text-muted fs-12">{item.name}</div>
                          </div>{" "}
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal coin_modal fade" tabindex="-1" id="network_modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
              <h4 class="modal-title">Select Network</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="alert alert-warning">
                <div class="d-flex">
                  <div>
                    <i className="fa fa-exclamation-circle me-2 text-warning"></i>
                  </div>
                  <div>
                    Ensure that the selected deposit network is the same as the
                    withdrawal network. Otherwise, your assets could be lost.
                  </div>
                </div>
              </div>

              <div className="custom_scrollbar height250">
                {blockchain &&
                  blockchain.map((item) => {
                    return (
                      <div
                        class="select_options"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setSymbolData(item);
                          N_getBlockchainData(
                            item?.blockchain,
                            user?.params ? user.params.user_id : user.user_id,
                            coindata?.symbol
                          ).then((res) => {
                            if (res.status == 200) {
                              if (res.contractdata) {
                                setCurrency(res.currencydata);
                                setContract(res.contractdata);
                                setIsSelected(1);
                              } else if (
                                item.blockchain == coindata?.blockchain
                              ) {
                                setCurrency(res.currencydata);
                                setIsSelected(2);
                              } else {
                                setIsSelected(3);
                              }
                            }
                          });
                        }}
                      >
                        <div class="product_name">
                          <div class="mb-0 fw-bold">{item.symbol}{item.type?`(${item.type})`:''}</div>
                          <div class="text-muted fs-12"> {item.blockchain}</div>
                        </div>{" "}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal coin_modal fade" tabindex="-1" id="withdrawal_modal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-bottom-0">
              <h4 class="modal-title">Withdrawal</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-5">
            {isOTPVerified?
              <div class="mb-4">
                <label class="text-muted">
                  Email Verification Code
                </label>
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    value={eotp}
                    onChange={(e) => {
                      setEOtp(e.target.value);
                    }}
                  />
                  <span class="input-group-text">
                      <a
                        className="ms-1"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={(e)=>{
                          N_crypto_withdraw_Otp_Email(
                            user?.params
                          ? user.params.user_id
                          : user.user_id,
                          eotp, 
                          transection_id, 
                          wallettype
                          ).then((dt)=>{
                            setTimeout(()=>{
                              document.location.reload()
                            }, 100)
                          })
                        }}
                      >
                        Verify code
                      </a>
                  </span>
                  
                </div>
              </div>:
              <div class="mb-4">
              <label class="text-muted mb-1">
                Mobile Verification Code
              </label>
              <div class="input-group">
                <input
                  type="number"
                  class="form-control"
                  value={motp}
                  onChange={(e) => {
                    setMOtp(e.target.value);
                  }}
                />
                <span class="input-group-text bg-primary">
                  <a
                    className="text-white"
                    onClick={() => {
                      const symbol = coindata.symbol;
                      const network = symbolData.type;
                      N_crypto_withdraw_New(user?.params
                        ? user.params.user_id
                        : user.user_id,
                        symbol,
                        withdraw_address,
                        withdrawAmount,
                        network
                        )
                      .then((data)=>{
                          if(data.status==200) {
                            NotificationManager.success(data.message);
                            setIsOTPSent(true)
                            setTransectionId(data?.params.transection_id)
                            setwallettype(data?.params.symbol)
                          } else {
                            NotificationManager.error(data.message);
                          }
                          
                      })
                    }}
                  >
                      {isOTPSent
                        ? "Resend Code"
                        : "Send Code"}
                                    
                  </a>
                  {isOTPSent ? (
                    <a
                      className="ms-1"
                      onClick={(e)=>{
                        N_crypto_withdraw_Otp(
                          user?.params
                        ? user.params.user_id
                        : user.user_id,
                        motp, 
                        transection_id, 
                        wallettype
                        ).then((res)=>{
                          if(res.status == 200) {
                            setEOtp('');
                            setIsOTPVerified(true)
                            NotificationManager.success(res.message)
                          } else {
                            NotificationManager.error(res.message)
                          }
                        })
                      }}
                    >
                      Verify code
                    </a>
                  ) : null}
                </span>
                
              </div>
            </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
