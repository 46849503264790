import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import OrderSidebar from "./components/OrderSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdOutlineInfo } from "react-icons/md";

export default function CryptoOrders() {
  return (
    <>
      <Header />
      <div className="page-content">
        <main className="container">
          <div className="account_page">
            <div class="page-content-title">
              <h3 class="fw-normal">Order History</h3>
            </div>
            <div className="overflow-auto mt-3">
              <div className="table_scroll_div">
                <table className="table global_table">
                  <thead>
                    <tr>
                      <th>Buy/Sell</th>
                      <th>Status</th>
                      <th>Order Quantity</th>
                      <th>Amount</th>
                      <th>Price</th>
                      <th>Fee</th>
                      <th>Payment Method</th>
                      <th>Time</th>
                    </tr>
                  </thead>

                  <tbody>
                    {/*============= Use this div when no data is found =============== */}

                    <tr>
                      <td
                        colSpan={8}
                        className="text-center border-bottom-0 text-muted"
                      >
                        <img
                          src="/img/no-data.png"
                          className="no-data-found mt-5"
                        />
                      </td>
                    </tr>

                    {/*============= Use this div when no data is found =============== */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
