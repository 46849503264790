import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import OrderSidebar from "./components/OrderSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdOutlineInfo } from "react-icons/md";
import { N_cancleOrderById, N_spotOrder, N_tradeOrder } from "./redux/helpers/api_functions_new";
import { getUserBalance, getUserOrder } from "./redux/actions/coinDBAction";

export default function SpotOrders(props) {
  const dispatch = useDispatch();
  const {coins, paired_curency} = useSelector((state)=>state.coinDBReducer);
  const {user} = useSelector((state)=>state.AuthReducer);
  const [pair_data, setPairData] = useState();
  const [pair_currency, setPairCurrency] = useState("all");
  const [side, setSide] = useState('all');
  const [type, setType] = useState('all');
  const [action, setAction] = useState('open');
  const [openorder, setOpenOrder] = useState([]);
  const [tradehistory, setTradeHistory] = useState([]);
  useEffect(()=>{
    if(coins && paired_curency.length>0) {
      updatePair();
    }
  }, [coins, paired_curency]);
  function updatePair() {
    let paircurrency = [];
    paired_curency && paired_curency.map((item)=>{
      Object.values(coins).map((data)=>{
        paircurrency.push(data.symbol+"/"+item.currency_coin);
      });
    });
    setPairData(paircurrency);

  }
  const pair = pair_data && pair_data.map((item)=>{
    return (
      <option value={item}>{item}</option>
    )
  }) 
  useEffect(()=>{
    if(action == 'trade') {
      N_tradeOrder(user?.params?.user_id ? user.params.user_id : user.user_id, action)
      .then((res)=>{
          if(res.status == 200) {
            setTradeHistory(res.params.trade_history.compleated);
          }
      })
    } else {
      N_spotOrder(user?.params?.user_id ? user.params.user_id : user.user_id, pair_currency, side, type, action)
      .then((res)=>{
          if(res.status == 200) {
            setOpenOrder(res.openorders);
          }
      })
    }
  }, [pair_currency, side, type, action])
  return (
    <>
      <Header />
      <div className="s-layout">
        <div class="s-layout__sidebar">
          <OrderSidebar />
        </div>

        <main className="s-layout__content">
          <div className="dashboard_header">
            <h3 className="header_title"> Spot </h3>
          </div>
          <div className="marginTop">
            <div className="account_page">
              <div className="container">
                <div className="row">
                  <div className="activity_panel mb-4">
                    <div className="">
                      <div class="custom_pills">
                        <div class="nav nav-pills" id="nav-tab" role="tablist">
                          <div
                            class="nav-item nav-link active fs-14"
                            style={{ height: "35px" }}
                            href="#open_order"
                            data-toggle="tab"
                            onClick={()=>{
                              setAction('open')
                            }}
                          >
                            Open Orders
                          </div>
                          {/* <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#adv_order"
                            data-toggle="tab"
                          >
                            Advanced Orders
                          </div> */}

                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#order_history"
                            data-toggle="tab"
                            onClick={()=>{
                              setAction('order')
                            }}
                          >
                            Order History
                          </div>

                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#trade_history"
                            data-toggle="tab"
                            onClick={(e)=>{
                              setAction('trade');
                            }}
                          >
                            Trade History
                          </div>

                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#subscription"
                            data-toggle="tab"
                          >
                            Subscriptions
                          </div>

                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#convert"
                            data-toggle="tab"
                          >
                            Convert
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-content">
                  <div className="tab-pane fade active show" id="open_order">
                    <div className="row cstm_form mb-4">
                      <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e)=>{
                            setPairCurrency(e.target.value);
                          }}
                        >
                          <option value="all" selected>All</option>
                          {pair}
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e)=>{
                            setSide(e.target.value);
                          }}
                        >
                          <option value="all" selected>All</option>
                          <option value="sell">Sell</option>
                          <option value="buy">Buy</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e)=>{
                            setType(e.target.value)
                          }}
                        >
                          <option value="all" selected>All </option>
                          <option value="limit">Limit Order</option>
                          <option value="market">Market Order</option>
                        </select>
                      </div>
                    </div>

                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Time</th>
                              <th>Pair</th>
                              <th>Side</th>
                              <th>Type</th>
                              <th>Order Price</th>
                              <th>Amount</th>
                              <th>Filled</th>
                              <th>Unexecuted</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}
                            {openorder && openorder.length>0 ?
                            openorder.map((item)=>{
                              return (
                                <tr>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{new Date(item.createdAt).toLocaleString()}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{item.currency_type.toUpperCase()+"/"+item.compare_currency.toUpperCase()}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{side}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{type}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{item.raw_price}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{item.volume}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{item.total_executed}</p>
                                </td>

                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{Number(item.volume)-Number(item.total_executed)}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p><button className="btn btn-pink"
                                  onClick={(e)=>{
                                    e.preventDefault()
                                    N_cancleOrderById(user?.params?.user_id ? user.params.user_id : user.user_id, item.order_id)
                                    .then((res) => {
                                      if (res.status === 200) {
                                        dispatch(getUserOrder(user?.params?.user_id ? user.params.user_id : user.user_id));
                                        dispatch(getUserBalance(user?.params?.user_id ? user.params.user_id : user.user_id));
                                        N_spotOrder(user?.params?.user_id ? user.params.user_id : user.user_id, pair_currency, side, type, action)
                                        .then((res)=>{
                                            if(res.status == 200) {
                                              setOpenOrder(res.openorders);
                                            }
                                        })
                                        NotificationManager.success(res.message);
                                      } else {
                                        NotificationManager.error(res.message);
                                      }
                                    })
                                    .catch((e) => {
                                      console.log("error: ", e);
                                    });
                                  }}>Cancel</button></p>
                                </td>
                              </tr>
                              )
                            })
                            :<tr>
                              <td
                                colSpan={9}
                                className="text-center border-bottom-0 text-muted"
                              >
                                <img
                                  src="/img/no-data.png"
                                  className="no-data-found"
                                />
                                <p>No records</p>
                              </td>
                            </tr>}

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="adv_order">
                    <div className="row cstm_form mb-4">
                      <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Pair</option>
                          <option value="1">All</option>
                          <option value="2">OXEN/BTC</option>
                          <option value="3">NRG/BTC</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Side</option>
                          <option value="1">All</option>
                          <option value="2">Sell</option>
                          <option value="3">Buy</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Type</option>
                          <option value="1">All </option>
                          <option value="2">Limit Order</option>
                          <option value="3">Market Order</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        <a href="#" className="btn btn-pink rounded-pill">
                          CSV Download
                        </a>
                      </div>
                    </div>
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Time</th>
                              <th>Pair</th>
                              <th>Side</th>
                              <th>Type</th>
                              <th>Trigger Condition</th>
                              <th>Order Price</th>
                              <th>Amount</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}

                            <tr>
                              <td
                                colSpan={8}
                                className="text-center border-bottom-0 text-muted"
                              >
                                <img
                                  src="/img/no-data.png"
                                  className="no-data-found"
                                />
                                <p>No records</p>
                              </td>
                            </tr>

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="order_history">
                    <div className="row cstm_form mb-4">
                      {/* <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Month</option>
                          <option value="1">All</option>
                          <option value="2">1 Month</option>
                          <option value="3">3 Months</option>
                          <option value="3">1 Year</option>
                        </select>
                      </div> */}
                         <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e)=>{
                            setPairCurrency(e.target.value);
                          }}
                        >
                          <option value="all" selected>All</option>
                          {pair}
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e)=>{
                            setSide(e.target.value);
                          }}
                        >
                          <option value="all" selected>All</option>
                          <option value="sell">Sell</option>
                          <option value="buy">Buy</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e)=>{
                            setType(e.target.value)
                          }}
                        >
                          <option value="all" selected>All </option>
                          <option value="limit">Limit Order</option>
                          <option value="market">Market Order</option>
                        </select>
                      </div>

                      <div className="col-lg-2 mb-2">
                        <a href="#" className="btn btn-pink">
                          Save As CSV
                        </a>
                      </div>
                    </div>
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                             <th>Time</th>
                              <th>Pair</th>
                              <th>Side</th>
                              <th>Type</th>
                              <th>Order Price</th>
                              <th>Amount</th>
                              <th>Filled</th>
                              <th>Unexecuted</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}

                            {openorder && openorder.length>0 ?
                            openorder.map((item)=>{
                              if(item.order_status==1 || (item.order_status==2 && Number(item.total_executed)>0))
                              return (
                                <tr>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{new Date(item.createdAt).toLocaleString()}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{item.currency_type.toUpperCase()+"/"+item.compare_currency.toUpperCase()}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{side}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{type}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{item.raw_price}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{item.volume}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{item.total_executed}</p>
                                </td>

                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{Number(item.volume)-Number(item.total_executed)}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-danger"
                                >
                                  <p>{Number(item.volume)==Number(item.total_executed)?"Completed":"Cancel"}</p>
                                </td>
                              </tr>
                              )
                            })
                            :<tr>
                              <td
                                colSpan={9}
                                className="text-center border-bottom-0 text-muted"
                              >
                                <img
                                  src="/img/no-data.png"
                                  className="no-data-found"
                                />
                                <p>No records</p>
                              </td>
                            </tr>}

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="trade_history">
                    {/* <div className="row cstm_form mb-4">
                      <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Month</option>
                          <option value="1">All</option>
                          <option value="2">1 Month</option>
                          <option value="3">3 Months</option>
                          <option value="3">1 Year</option>
                        </select>
                      </div>

                      <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Pair</option>
                          <option value="1">All</option>
                          <option value="2">OXEN/BTC</option>
                          <option value="3">NRG/BTC</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Side</option>
                          <option value="1">All</option>
                          <option value="2">Sell</option>
                          <option value="3">Buy</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Type</option>
                          <option value="1">All </option>
                          <option value="2">Limit Order</option>
                          <option value="3">Market Order</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        <a href="#" className="btn btn-pink">
                          Save As CSV
                        </a>
                      </div>
                    </div> */}

                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Time</th>
                              <th>Pair</th>
                              <th>Amount</th>
                              <th>Volume</th>
                              <th>Price</th>
                              <th>Unexecuted</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}

                            {tradehistory && tradehistory.length>0 ?
                            tradehistory.map((item)=>{
                              return (
                                <tr>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{new Date(Number(item.timestamp)).toLocaleString()}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{item.currency_type.toUpperCase()+"/"+item.compare_currency.toUpperCase()}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{item.raw_price}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{item.volume}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{item.total_executed}</p>
                                </td>

                                <td
                                  className="border-bottom-0 text-muted"
                                >
                                  <p>{Number(item.volume)-Number(item.total_executed)}</p>
                                </td>
                                <td
                                  className="border-bottom-0 text-danger"
                                >
                                  <p>{Number(item.volume)==Number(item.total_executed)?"Completed":"Cancel"}</p>
                                </td>
                              </tr>
                              )
                            })
                            :<tr>
                              <td
                                colSpan={9}
                                className="text-center border-bottom-0 text-muted"
                              >
                                <img
                                  src="/img/no-data.png"
                                  className="no-data-found"
                                />
                                <p>No records</p>
                              </td>
                            </tr>}

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="subscription">
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Coin</th>
                              <th>Buy Price</th>
                              <th>Amount</th>
                              <th>Subscription Time</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}

                            <tr>
                              <td
                                colSpan={6}
                                className="text-center border-bottom-0 text-muted"
                              >
                                <img
                                  src="/img/no-data.png"
                                  className="no-data-found"
                                />
                                <p>No records</p>
                              </td>
                            </tr>

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="convert">
                    <div className="row cstm_form mb-4">
                      <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Month</option>
                          <option value="1">All</option>
                          <option value="2">1 Month</option>
                          <option value="3">3 Months</option>
                          <option value="3">1 Year</option>
                        </select>
                      </div>

                      <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Paid By</option>
                          <option value="1">All Account</option>
                          <option value="2">Funding Account</option>
                          <option value="3">Trading Account</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>From</option>
                          <option value="1">All </option>
                          <option value="2">BTC</option>
                          <option value="3">USDT</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>To</option>
                          <option value="1">All </option>
                          <option value="2">BTC</option>
                          <option value="3">USDT</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Status</option>
                          <option value="1">All </option>
                          <option value="2">Successful</option>
                          <option value="3">Pending</option>
                          <option value="3">Canceled</option>
                        </select>
                      </div>
                    </div>

                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Time Filled</th>
                              <th>Paid By</th>
                              <th>From</th>
                              <th>To</th>
                              <th>Price</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}

                            <tr>
                              <td
                                colSpan={6}
                                className="text-center border-bottom-0 text-muted"
                              >
                                <img
                                  src="/img/no-data.png"
                                  className="no-data-found"
                                />
                                <p>No records</p>
                              </td>
                            </tr>

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
