import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import OrderSidebar from "./components/OrderSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdOutlineInfo } from "react-icons/md";

export default function Isolated() {
  return (
    <>
      <Header />
      <div className="s-layout">
        <div class="s-layout__sidebar">
          <OrderSidebar />
        </div>

        <main className="s-layout__content">
          <div className="dashboard_header">
            <h3 className="header_title">Isolated</h3>
          </div>
          <div className="marginTop">
            <div className="account_page">
              <div className="container">
                <div className="row">
                  <div className="activity_panel mb-4">
                    <div className="">
                      <div class="custom_pills">
                        <div class="nav nav-pills" id="nav-tab" role="tablist">
                          <div
                            class="nav-item nav-link active fs-14"
                            style={{ height: "35px" }}
                            href="#open_order"
                            data-toggle="tab"
                          >
                            Open Orders
                          </div>
                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#adv_order"
                            data-toggle="tab"
                          >
                            Advanced Orders
                          </div>

                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#order_history"
                            data-toggle="tab"
                          >
                            Order History
                          </div>

                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#trade_history"
                            data-toggle="tab"
                          >
                            Trade History
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
             
              <div className="tab-content">
                <div className="tab-pane fade active show" id="open_order">
                  <div className="row cstm_form mb-4">
                    <div className="col-lg-2 mb-2 mb-2">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Pair</option>
                        <option value="1">All</option>
                        <option value="2">OXEN/BTC</option>
                        <option value="3">NRG/BTC</option>
                      </select>
                    </div>
                    <div className="col-lg-2 mb-2 mb-2">
                      {" "}
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Side</option>
                        <option value="1">All</option>
                        <option value="2">Sell</option>
                        <option value="3">Buy</option>
                      </select>
                    </div>
                    <div className="col-lg-2 mb-2">
                      {" "}
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Type</option>
                        <option value="1">All </option>
                        <option value="2">Limit Order</option>
                        <option value="3">Market Order</option>
                      </select>
                    </div>
                  </div>

                  <div className="overflow-auto mt-3">
                    <div className="table_scroll_div">
                      <table className="table global_table">
                        <thead>
                          <tr>
                            <th>Time</th>
                            <th>Pair</th>
                            <th>Side</th>
                            <th>Type</th>
                            <th>Order Price</th>
                            <th>Amount</th>
                            <th>Filled</th>
                            <th>Unexecuted</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {/*============= Use this div when no data is found =============== */}

                          <tr>
                            <td
                              colSpan={9}
                              className="text-center border-bottom-0 text-muted"
                            >
                              <img
                                src="/img/no-data.png"
                                className="no-data-found"
                              />
                              <p>No records</p>
                            </td>
                          </tr>

                          {/*============= Use this div when no data is found =============== */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="adv_order">
                  <div className="row cstm_form mb-4">
                    <div className="col-lg-2 mb-2">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Pair</option>
                        <option value="1">All</option>
                        <option value="2">OXEN/BTC</option>
                        <option value="3">NRG/BTC</option>
                      </select>
                    </div>
                    <div className="col-lg-2 mb-2">
                      {" "}
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Side</option>
                        <option value="1">All</option>
                        <option value="2">Sell</option>
                        <option value="3">Buy</option>
                      </select>
                    </div>
                    <div className="col-lg-2 mb-2">
                      {" "}
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Type</option>
                        <option value="1">All </option>
                        <option value="2">Limit Order</option>
                        <option value="3">Market Order</option>
                      </select>
                    </div>
                    <div className="col-lg-2 mb-2">
                      <a href="#" className="btn btn-pink rounded-pill ">
                        CSV Download
                      </a>
                    </div>
                  </div>
                  <div className="overflow-auto mt-3">
                    <div className="table_scroll_div">
                    <table className="table global_table">
                      <thead>
                        <tr>
                          <th>Time</th>
                          <th>Pair</th>
                          <th>Side</th>
                          <th>Type</th>
                          <th>Trigger Condition</th>
                          <th>Order Price</th>
                          <th>Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {/*============= Use this div when no data is found =============== */}

                        <tr>
                          <td
                            colSpan={8}
                            className="text-center border-bottom-0 text-muted"
                          >
                            <img
                              src="/img/no-data.png"
                              className="no-data-found"
                            />
                            <p>No records</p>
                          </td>
                        </tr>

                        {/*============= Use this div when no data is found =============== */}
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="order_history">
                  <div className="row cstm_form mb-4">
                    <div className="col-lg-2 mb-2">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Month</option>
                        <option value="1">All</option>
                        <option value="2">1 Month</option>
                        <option value="3">3 Months</option>
                        <option value="3">1 Year</option>
                      </select>
                    </div>

                    <div className="col-lg-2 mb-2">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Pair</option>
                        <option value="1">All</option>
                        <option value="2">OXEN/BTC</option>
                        <option value="3">NRG/BTC</option>
                      </select>
                    </div>
                    <div className="col-lg-2 mb-2">
                      {" "}
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Side</option>
                        <option value="1">All</option>
                        <option value="2">Sell</option>
                        <option value="3">Buy</option>
                      </select>
                    </div>
                    <div className="col-lg-2 mb-2">
                      {" "}
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Type</option>
                        <option value="1">All </option>
                        <option value="2">Limit Order</option>
                        <option value="3">Market Order</option>
                      </select>
                    </div>
                    <div className="col-lg-2 mb-2">
                      <a href="#" className="btn btn-pink">
                        Save As CSV
                      </a>
                    </div>
                  </div>
                  <div className="overflow-auto mt-3">
                    <div className="table_scroll_div">
                    <table className="table global_table">
                      <thead>
                        <tr>
                          <th>Time</th>
                          <th>Pair</th>
                          <th>Side</th>
                          <th>Type</th>
                          <th>Trigger Condition</th>
                          <th>Order Price</th>
                          <th>Amount</th>
                          <th>Filled</th>
                          <th>Avg. Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {/*============= Use this div when no data is found =============== */}

                        <tr>
                          <td
                            colSpan={10}
                            className="text-center border-bottom-0 text-muted"
                          >
                            <img
                              src="/img/no-data.png"
                              className="no-data-found"
                            />
                            <p>No records</p>
                          </td>
                        </tr>

                        {/*============= Use this div when no data is found =============== */}
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="trade_history">
                  <div className="row cstm_form mb-4">
                    <div className="col-lg-2 mb-2">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Month</option>
                        <option value="1">All</option>
                        <option value="2">1 Month</option>
                        <option value="3">3 Months</option>
                        <option value="3">1 Year</option>
                      </select>
                    </div>

                    <div className="col-lg-2 mb-2">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Pair</option>
                        <option value="1">All</option>
                        <option value="2">OXEN/BTC</option>
                        <option value="3">NRG/BTC</option>
                      </select>
                    </div>
                    <div className="col-lg-2 mb-2">
                      {" "}
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Side</option>
                        <option value="1">All</option>
                        <option value="2">Sell</option>
                        <option value="3">Buy</option>
                      </select>
                    </div>
                    <div className="col-lg-2 mb-2">
                      {" "}
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Type</option>
                        <option value="1">All </option>
                        <option value="2">Limit Order</option>
                        <option value="3">Market Order</option>
                      </select>
                    </div>
                    <div className="col-lg-2 mb-2">
                      <a href="#" className="btn btn-pink">
                        Save As CSV
                      </a>
                    </div>
                  </div>

                  <div className="overflow-auto mt-3">
                    <div className="table_scroll_div">
                    <table className="table global_table">
                      <thead>
                        <tr>
                          <th>Time</th>
                          <th>Pair</th>
                          <th>Side</th>
                          <th>Type</th>
                          <th>Amount</th>
                          <th>Volume</th>
                          <th>Price</th>
                        </tr>
                      </thead>

                      <tbody>
                        {/*============= Use this div when no data is found =============== */}

                        <tr>
                          <td
                            colSpan={7}
                            className="text-center border-bottom-0 text-muted"
                          >
                            <img
                              src="/img/no-data.png"
                              className="no-data-found"
                            />
                            <p>No records</p>
                          </td>
                        </tr>

                        {/*============= Use this div when no data is found =============== */}
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
            </div> </div>

          </div>
        </main>
      </div>
    </>
  );
}
