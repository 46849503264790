import React, { useState, useEffect } from "react";
import Header from "./HomeComp/Header";
import ProfileSidebar from "./components/ProfileSidebar";
import { submitKyc } from "./redux/actions/authActions";

import FullLoader from "./components/FullLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  N_checkKYCStatus,
  N_getCity,
  N_userEditSubmit,
  N_getState,
  N_getUserProfile
} from "./redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
import { SEND_OTP } from "./redux/constant";
import { Link } from "react-router-dom";

export default function EditProfile(props) {
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = useState();
  const [getUser, setUser] = useState();
  const [fullloading, setFullLoader] = useState(true);

  let user_id = user?.params ? user.params.user_id : user.user_id;
  useEffect(() => {
    N_getUserProfile(user_id).then((res) => {
      if (res?.params?.profile_info) {
        setFullLoader(false);
        setUser(res.params.profile_info);
      }
    });
  }, []);

  const submit = (e) => {
    e.preventDefault();
    const body = {
      user_id: user?.params ? user.params.user_id : user.user_id,
      first_name: document.getElementById("user_fname").value,
      middle_name: document.getElementById("user_mname").value,
      last_name: document.getElementById("user_lname").value,
      email: document.getElementById("user_email").value
    };
    console.log(body);
    setLoading(true);
    N_userEditSubmit(body).then((res) => {
      if (res.status === 200) {
        NotificationManager.success(res.message);
        NotificationManager.success("OTP Sent to your Email");
        dispatch({
          type: SEND_OTP,
          data: {
            email: document.getElementById("user_email").value,
            user_id: user_id
          }
        });
        props.history?.replace(
          "/otp?action=change_email&new_email=" +
            document.getElementById("user_email").value
        );
      } else {
        NotificationManager.error(res.message);
      }
    });
  };
  if (fullloading)
    return (
      <>
        <Header {...props} />
        <FullLoader />
      </>
    );
  else
    return (
      <>
        <Header {...props} />
        <div className="col-lg-12">
          {/* <div className="modified_collapse bg-secondary"> */}
            {/* <button
              class="btn text-white border-0 text-left d-block d-lg-none mt-5 sidebar_toggle collapsed"
              data-bs-toggle="collapse"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Profile
            </button> */}
            <ProfileSidebar />
          {/* </div> */}
          <div className="main-content">
            {/* <div className="mt-3">
              <h5 className="mb-3">Edit Personal Info</h5>
            </div> */}
            <div className="row mt-3">
              <div className="col-lg-12 m-auto">
                <div class="profile-card">
                  <div class="image">
                    <img
                      src="/img/account_profile.png"
                      style={{ width: "130px", height: "130px" }}
                      class="profile-img"
                    />
                  </div>

                  <div className="col-lg-4 col-12 m-auto">
                    <form
                      className=""
                      onSubmit={submit}
                      method="post"
                      id="welcome_form"
                    >
                      <div id="personal_info" className="">
                        <div className="form-group h">
                          <label htmlFor="user_fname">
                            FIRST NAME (Name:{" "}
                            {getUser && getUser.name ? getUser.name : ""})
                          </label>
                          <input
                            type="text"
                            name="user_fname"
                            className="form-control"
                            id="user_fname"
                          />
                          <i className="fas fa-check-circle"></i>
                          <i className="fas fa-exclamation-circle"></i>
                          <small>Error message</small>
                        </div>
                        <div className="form-group h">
                          <label htmlFor="user_mname">MIDDLE NAME</label>
                          <input
                            type="text"
                            name="user_mname"
                            className="form-control"
                            id="user_mname"
                          />
                          <i className="fas fa-check-circle"></i>
                          <i className="fas fa-exclamation-circle"></i>
                          <small>Error message</small>
                        </div>
                        <div className="form-group h">
                          <label htmlFor="user_lname">LAST NAME</label>
                          <input
                            type="text"
                            name="user_lname"
                            className="form-control"
                            id="user_lname"
                          />
                          <i className="fas fa-check-circle"></i>
                          <i className="fas fa-exclamation-circle"></i>
                          <small>Error message</small>
                        </div>
                        <div className="form-group">
                          <label htmlFor="user_email">Email ID</label>
                          <input
                            type="text"
                            name="user_email"
                            defaultValue={
                              getUser && getUser.email ? getUser.email : ""
                            }
                            className="form-control"
                            id="user_email"
                          />
                        </div>
                        <input
                          type="hidden"
                          name="session_id"
                          id="session_id"
                        />
                        <div className="d-grid">
                          <button
                            type="submit"
                            id="btn_submit"
                            className="btn btn-primary"
                          >
                            {loading ? (
                              <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                            ) : null}
                            <span id="reg">SUBMIT</span>
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="text-center py-2">
                      <Link to="/profile" className="text-muted">
                        {" "}
                        Back to Profile
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
