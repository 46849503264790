import React, { useEffect, useState } from "react";
import Router from "./pages/router";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./pages/Globalstyle";
import { darkTheme, lightTheme } from "./pages/Themes";
import { useSelector } from "react-redux";
import './i18n'
import { getDirection } from './i18n';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom/cjs/react-router-dom";


function App(props) {
  const { i18n } = useTranslation();
  const { switch_theme } = useSelector((state) => state.AuthReducer);
  const { lng } = useParams(); // Get language from URL params
  useEffect(() => {
    // Update the HTML `dir` attribute based on the current language
    const currentLang = i18n.language;
    const direction = getDirection(currentLang);
    document.documentElement.dir = direction;
    document.documentElement.lang = currentLang;

    // Optionally update the body or any other element
    document.body.style.direction = direction;
  }, [i18n.language]);
  const [redirected, setRedirected] = useState(false);


  useEffect(() => {
    // Get language from localStorage
    const storedLanguage = localStorage.getItem("language") || "en"; // Default to 'en'

    // Check the current language in the URL
    const urlParams = new URLSearchParams(window.location.search);
    const lng = urlParams.get("lng") || storedLanguage;

    if (["en", "ar"].includes(lng)) {
      if (!urlParams.get("lng")) {
        // Append language to the URL if not already present
        const newPath = `${window.location.pathname}?lng=${lng}`;
        window.history.replaceState(null, "", newPath);
      }

      // Change language dynamically
      i18n.changeLanguage(lng);
      localStorage.setItem("language", lng); // Ensure localStorage is updated
    } else {
      if (!redirected) {
        // Default to 'en' if invalid language is provided
        setRedirected(true);
        const newPath = `${window.location.pathname}?lng=en`;
        localStorage.setItem("language", "en");
        window.history.replaceState(null, "", newPath);
        i18n.changeLanguage("en");
      }
    }
  }, [redirected, i18n]);


  return (
      <ThemeProvider theme={switch_theme === "light" ? lightTheme : darkTheme}>
        <>
          {/* <GlobalStyles /> */}
          <Router {...props} />
          <NotificationContainer />
        </>
      </ThemeProvider>
  );
}

export default App;
