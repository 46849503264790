import React, { useState } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";

export default function StartTrade() {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  AOS.init({ duration: 1000 });
  const [showindex, setShowindex] = useState(null);

  // Dummy data for liveData
  const liveData = [
    {
      _id: "1",
      question: "What is a cryptocurrency exchange ?",
      answer:
        "A cryptocurrency exchange is an online platform that facilitates the buying, selling, and trading of digital assets like Bitcoin, Ethereum, and other cryptocurrencies. Much like a traditional stock exchange, a cryptocurrency exchange provides a marketplace where users can trade assets with each other or with the exchange itself.",
    },
    {
      _id: "2",
      question: "How to buy Bitcoin and other cryptocurrencies on Amexa ?",
      answer:
        "A cryptocurrency exchange is an online platform that facilitates the buying, selling, and trading of digital assets like Bitcoin, Ethereum, and other cryptocurrencies. Much like a traditional stock exchange, a cryptocurrency exchange provides a marketplace where users can trade assets with each other or with the exchange itself.",
    },
    {
      _id: "3",
      question: "How to Track cryptocurrency currency ?",
      answer:
        "A cryptocurrency exchange is an online platform that facilitates the buying, selling, and trading of digital assets like Bitcoin, Ethereum, and other cryptocurrencies. Much like a traditional stock exchange, a cryptocurrency exchange provides a marketplace where users can trade assets with each other or with the exchange itself.",
    },
    {
      _id: "4",
      question: "How to Trade cryptocurrencies on Amexa ? ",
      answer:
        "A cryptocurrency exchange is an online platform that facilitates the buying, selling, and trading of digital assets like Bitcoin, Ethereum, and other cryptocurrencies. Much like a traditional stock exchange, a cryptocurrency exchange provides a marketplace where users can trade assets with each other or with the exchange itself.",
    },
    {
      _id: "5",
      question: "How to earn crypto on Amexa ?",
      answer:
        "A cryptocurrency exchange is an online platform that facilitates the buying, selling, and trading of digital assets like Bitcoin, Ethereum, and other cryptocurrencies. Much like a traditional stock exchange, a cryptocurrency exchange provides a marketplace where users can trade assets with each other or with the exchange itself.",
    },
  ];
  return (
    <>
    <div className="faq_section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="para_heading text-gradient d-flex justify-content-between align-items-center">
            <div>
            Frequently Asked Questions
              </div>
              <div className="text-end d-none d-md-block">
                <img style={{width:'40%'}} src="/img/object.png" />
              </div>
            </div>
              <div className="mt-5">
              {liveData?.map((item, i) => (
                <>
                  <div
                    className="accordion"
                    id="accordionExample"
                    key={item._id}
                  >
                    <div className={`accordionitem accordion-item`}>
                      <h2 className="accordion-header" id="headingOne2">
                        <button
                          className={`accordionbutton accordion-button
                              ${i === showindex ? "" : "collapsed"}
                              `}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseOne2${i}`}
                          aria-expanded="true"
                          aria-controls="collapseOne2"
                          onClick={() => setShowindex(i)}
                        >
                          {item.question}
                        </button>
                      </h2>
                      <div
                        id={`collapseOne2${i}`}
                        className={`accordion-collapse collapse ${
                          i === showindex ? "show" : null
                        }`}
                        aria-labelledby="headingOne2"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className={`accordionbody accordion-body`}
                        >
                          <p className='p'>{item.answer}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
              </div>
          </div>

        </div>

      </div>

    </div>
  
    </>
  );
}
