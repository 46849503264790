import React from "react";
import ProfileSidebar from "./components/ProfileSidebar";
import Header from "./HomeComp/Header";

export default function PrivacyControl(props) {
  return (
    <div>
      <Header {...props} />
      <div className="col-lg-12">
       
          <ProfileSidebar {...props} />
        
        <div className="main-content">
          <div className="mt-3">
            <h4 className="mb-3">Privacy Policy Control</h4>

            <div className="card">
              <div className="card-header"> Privacy Policy</div>
              <div className="card-body">
                
                <h5 className="mb-0 text ">For requests regarding </h5>
                <ul className="mb-3 mt-2">
                  <span className="badge bg-light me-2 text-dark border fs-12">
                    {" "}
                    Copy of your data
                  </span>
                  <span className="badge bg-light me-2 text-dark border fs-12">
                    Data export
                  </span>
                  <span className="badge bg-light me-2 text-dark border fs-12">
                    Data correction
                  </span>
                </ul>
           
                 <div className="">
                  {" "}
                 <p className="mb-2 mt-0">  You control your data, and we respect that
                 <br/>Please reach out to us. Our team will be happy to help you
                  out.</p>
                
               <div>
               <a href="#support" className="btn btn-primary">
                  {" "}
                  Contact Us
                </a>
               </div>

                </div>
              </div>
            </div>

           
          </div>
        </div>
      </div>
    </div>
  );
}
