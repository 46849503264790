import React, { useEffect, useState } from "react";
import ProfileSidebar from "./components/ProfileSidebar";
import Header from "./HomeComp/Header";
import { useSelector } from "react-redux";
import { N_getNotification } from "./redux/helpers/api_functions_new";

export default function GetNotifi(props) {
  const [getallnoti, getAllNoti] = useState(0);
  const { user } = useSelector((state) => state.AuthReducer);
  useEffect(() => {
    N_getNotification(user?.params ? user.params.user_id : user.user_id).then(
      (data) => {
        if (data.status === 200) getAllNoti(data.params.notification);
      }
    );
  }, []);
  return (
    <div>
      <Header {...props} />

      <ProfileSidebar {...props} />

      <div className="main-content">
        <div className="mt-3">
          <h4 className="mb-3">Notifications</h4>

          <article>
            {getallnoti == 0 ? (
              <>
                <div
                  class="alert alert-info d-flex align-items-center"
                  role="alert"
                >
                  <h5>
                    {" "}
                    <i className="fa fa-exclamation-triangle fs-1"></i>
                  </h5>
                  <h5 className="ms-3">You don't have any notifications yet</h5>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-12">
                  <table className="table table-hover table-bordered mdfthemetxt">
                    <thead className="light-theme-color">
                      <tr>
                        <th className="tdCenter">No.</th>
                        <th className="tdCenter">Area</th>
                        <th className="tdCenter">MSG</th>
                        <th className="tdCenter">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getallnoti
                        ? getallnoti.map((item, i) => (
                            <>
                              <tr>
                                <td className="tdCenter">{++i}</td>
                                <td className="tdCenter">{item.name}</td>
                                <td className="tdCenter"> {item.msg} </td>
                                <td className="tdCenter">
                                  {" "}
                                  {new Date(
                                    item.createdAt
                                  ).toLocaleString()}{" "}
                                </td>
                              </tr>
                            </>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </article>
        </div>
      </div>
    </div>
  );
}
