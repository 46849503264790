import React from "react";
import {
  BsFacebook,
  BsTelegram,
  BsInstagram,
  BsLinkedin,
} from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { adminurl } from "../redux/helpers/api_functions_new";
import { useSelector } from "react-redux";

export default function Footer() {
  const { webData } = useSelector((state) => state.websiteDBReducer);
  AOS.init({ duration: 1000 });
  return (
    <div className="footer_div">
      <div className="container py-5 footer_bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="footer-logo">
                <img
                  style={{ width: "100px" }}
                  className="logo_height"
                  src={`img/logo.png`}
                  alt="logo"
                />
              </div>
              <p className="pt-2 pe-5">
                AMEXA has everything you need to buy, sell, and trade crypto. An
                intuitive experience from the start. From day one, we designed
                and built a streamlined crypto exchange for newcomers and
                experts alike. Make easy deposits and withdrawals, measure your
                portfolio's performance and keep track of all of your crypto in
                one convenient place.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h5>About Us</h5>
              <ul className="footer_list">
                <li>
                  <a href="/about">About</a>
                </li>

                <li>
                  <a href="/downloads">Downloads</a>
                </li>

                <li>
                  <a>Business Contacts</a>
                </li>
                <li>
                  <a href="/community">Community</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 my-2">
              <h5>Service</h5>
              <p>INR Trade</p>
              <p>Request Token</p>
              <p>Unique trade</p>
              <p>Safe trade</p>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12 my-2 mmysocial">
              <h5>Social Links </h5>
              <div>
                <ul className="footer_list">
                  <li>
                    <a
                      className="d-flex align-items-center"
                      href="https://x.com/Amexa418481"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <AiFillTwitterCircle className="fs-4" />
                      <span className="align-bottom ms-2 fs-14">Twitter</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="d-flex align-items-center"
                      href="https://t.me/amexa_en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsTelegram className="fs-4" />
                      <span className="align-bottom ms-2 fs-14">Telegram</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="d-flex align-items-center"
                      href="https://www.linkedin.com/company/amexa-en/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsLinkedin className="fs-5" />
                      <span className="align-bottom ms-2 fs-14">Linkedin</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="d-flex justify-content-center footer-bottom">
          <span className="" style={{ padding: "10px" }}>
            © 2024 AMEXA.
          </span>
          <a href="/privacy">Privacy policy</a>
          <a href="/terms_&_conditions">Terms & Conditions</a>
        </div>
      </div>
    </div>
  );
}
