import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./pages/redux/store";
import { GoogleOAuthProvider } from '@react-oauth/google';
// import '@rainbow-me/rainbowkit/styles.css';
// import {
//   getDefaultWallets,
//   RainbowKitProvider,
// } from '@rainbow-me/rainbowkit';
// import { configureChains, createConfig, WagmiConfig } from 'wagmi';
// import {
//   mainnet,
//   polygon,
//   optimism,
//   arbitrum,
//   zora,
// } from 'wagmi/chains';
// import { alchemyProvider } from 'wagmi/providers/alchemy';
// import { publicProvider } from 'wagmi/providers/public';
// const { chains, publicClient } = configureChains(
//   [mainnet, polygon, optimism, arbitrum, zora],
//   [
//     alchemyProvider({ apiKey: process.env.ALCHEMY_ID }),
//     publicProvider()
//   ]
// );
// const { connectors } = getDefaultWallets({
//   appName: 'My RainbowKit App',
//   projectId: 'YOUR_PROJECT_ID',
//   chains
// });
// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors,
//   publicClient
// })

ReactDOM.render(
  <GoogleOAuthProvider clientId="804328762528-806oa3kpc9mahkcvi3cafirqnl45tcb4.apps.googleusercontent.com">
  <React.StrictMode>
  {/* <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}> */}
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
    {/* </RainbowKitProvider>
    </WagmiConfig> */}
  </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
reportWebVitals();
