import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FaCoins, FaSortDown, FaSortUp } from "react-icons/fa";

export default function AssetsMargin() {
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="container marginTop account_page">
            <div className="page-content-title"></div>
            <div className="col-lg-12">
              <div className="aco_hero mt-5 text-center">
                <img src="/img/solutions.svg" alt="solutions" />
                <p className="mt-3">You haven't enabled margin trading yet.</p>
                <a href="#" className="btn btn-pink">Enable Margin Trading</a>
              </div>
            </div>
          </div>
        </main>
      </div>     
    </>
  );
}
