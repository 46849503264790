import React, { useEffect, useState } from "react";
import Header from "./HomeComp/Header";
import P2PBuyNSellNew from "./components/p2pBuyNSellNew";
import Preloader from "./components/PreLoader";

import "./exchange.css";
import P2pContent from "./P2pContent";
import { useSelector } from "react-redux";
import {
  N_getUserBankDetails,
  N_p2pSellOrder,
  N_p2pOrderAction,
  N_p2pCancelOrder,
  getAPICall
} from "./redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
import { MdOutlineError } from "react-icons/md";
import { Link } from "react-router-dom";
import "./p2pnew.css";
import "./customp2p.css";
import Footer from "../pages/HomeComp/Footer";
export default function P2P(props) {
  const { coins } = useSelector((state) => state.coinDBReducer);
  const { isLoggedIn, user } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActivetab] = useState(0);
  const [activeCoin, setActiveCoin] = useState("RBC");
  const [popup, showpopup] = useState(false);
  const [p2plist, setp2pList] = useState();
  const [buyList, setBuyList] = useState();
  const [his, setHistory] = useState();
  const [p2pstatus, setp2pStatus] = useState(true);
  const [p2pmsg, setp2pmsg] = useState();
  const [openOrder, setOpenOrder] = useState();
  const [pendingData, setPendingData] = useState();
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const userId = user?.params ? user.params.user_id : user.user_id;
  // useEffect(()=>{

  // }, [])
  useEffect(() => {
    document.title = "AMEXA P2P";
    if (Object.values(coins).length) {
      if (isLoggedIn) {
        getAPICall("/p2p-check-status").then((res) => {
          const data = res.data;
          if (data.status == 200) {
            setp2pStatus(data.p2pstatus);
            if (!data.p2pstatus) {
              setp2pmsg(data.p2pmsg);
            }
            setLoading(false);
          }
        });
      } else {
        setLoading(false);
      }
    }
  }, [coins.length]);

  let coinslist = Object.values(coins).map((item, index) => {
    if (item.symbol !== "INR") {
      return (
        <>
          <option
            key={index}
            value={item.symbol}
            selected={item.symbol == "RBC"}
          >
            {item.symbol}
          </option>
        </>
      );
    }
  });
  useEffect(() => {
    N_p2pSellOrder("", "", activeCoin)
      .then((d) => {
        if (d.status == 200) {
          setBuyList(d.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [activeCoin]);
  return (
    <>
      {popup ? (
        <>
          <div
            style={{
              position: "fixed",
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              zIndex: 200,
              backgroundColor: "rgba(0,0,0,0.3)"
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "20%",
                zIndex: 1000,
                boxShadow: "2px 2px 20px rgba(0,0,0,0.4)",
                display: "flex",
                flexDirection: "column",
                alignSelf: "center"
              }}
            >
              {isLoggedIn ? (
                <>
                  <div className="security_header shead-bg"></div>
                  <div className="container shead-bg">
                    <div className="row">
                      <div className="col-12 col-md-12 col-sm-12">
                        <form className="signupform mdfthemetxt" id="otp_form">
                          <div className="mb-2">
                            <h3>Are you sure! You want to Buy {activeCoin}</h3>
                            <b>
                              If you Click yes and not buy this coin then Charge
                              will be deducted
                            </b>
                          </div>
                          <div className="form-group" id="btns">
                            <button
                              type="button"
                              id="verify_id"
                              className="btn bg-primary"
                              onClick={() => {
                                document.getElementById(
                                  "verify_id"
                                ).disabled = true;
                                N_getUserBankDetails(p2plist.sell_user_id)
                                  .then((d) => {
                                    if (d.status == 200) {
                                      props.history.push({
                                        pathname: "/p2ppage",
                                        p2plist,
                                        bank_details: d.params.bank_details
                                      });
                                    } else {
                                      showpopup(false);
                                    }
                                  })
                                  .catch((e) => {
                                    console.log(e);
                                    showpopup(false);
                                  });
                              }}
                            >
                              Verify
                            </button>
                            <button
                              type="button"
                              className="btn bg-danger ml-4"
                              onClick={(e) => {
                                showpopup(false);
                              }}
                            >
                              close
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  class="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <strong>Hey!</strong> Login First For Buy {activeCoin}.
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => {
                      showpopup(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}

      {p2pstatus ? (
        <>
          <Header {...props} />
          {loading ? (
            <Preloader />
          ) : (
            <>
              <div className="box-header with-border top-header pt-5"> 
              <div className="container">
             <div className="row align-items-center py-3">
             <div className="col-lg-8 ">
              
              
              <h2 className="text-white">
                Buy and Sell <span className="text-warning"> {activeCoin}</span> <br/>with Your Preferred Payment
                Methods
              </h2>

              <h5 className="p2p-hader-para mb-0">
                Buy and sell <span className="text-warning"> {activeCoin}</span> safely and easily on AMEXA P2P. 
                Find the best offer below and buy and sell with Your
                Preferred Payment Methods today.
              </h5>
            </div>
          
            <div className="col-lg-4 text-end">
              <img src="/img/buy-sell.png" style={{height:'230px'}} className="img-fluid"/>
            </div>
            </div>
             </div>
              </div>

              <div className="container my-4">
                <div className="">
                  <div className="row mb-4">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-4 mb-2">
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setActiveCoin(e.target.value);
                            }}
                          >
                            {coinslist}
                          </select>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <button
                            type="button"
                            className={` btn btn-outline-primary ms-1 ${
                              activeTab == 0 ? "active" : ""
                            }`}
                            onClick={() => setActivetab(0)}
                          >
                            BUY
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-primary ms-1 ${
                              activeTab == 1 ? "active" : ""
                            }`}
                            onClick={() => setActivetab(1)}
                          >
                            SELL
                          </button>
                          {isLoggedIn ? (
                        <>
                          <button
                            type="button"
                            className={`btn btn-outline-primary ms-1 ${
                              activeTab == 4 ? "active" : ""
                            }`}
                            onClick={() => {
                              setActivetab(4);
                              N_p2pSellOrder(
                                user?.params
                                  ? user.params.user_id
                                  : user.user_id,
                                "open",
                                activeCoin
                              )
                                .then((d) => {
                                  if (d.status == 200) {
                                    setOpenOrder(d.data);
                                  }
                                })
                                .catch((e) => {
                                  console.log(e);
                                });
                            }}
                          >
                            Open Order
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-primary ms-1 ${
                              activeTab == 2 ? "active" : ""
                            }`}
                            onClick={() => {
                              setActivetab(2);
                              N_p2pSellOrder(
                                user?.params
                                  ? user.params.user_id
                                  : user.user_id,
                                "history",
                                activeCoin
                              )
                                .then((d) => {
                                  if (d.status == 200) {
                                    setHistory(d.data);
                                  }
                                })
                                .catch((e) => {
                                  console.log(e);
                                });
                            }}
                          >
                            HISTORY
                          </button>
                          <button
                            type="button"
                            className={`btn btn-success ms-1 ${
                              activeTab == 3 ? "active" : ""
                            }`}
                            onClick={() => {
                              setActivetab(3);
                              N_p2pSellOrder(
                                user?.params
                                  ? user.params.user_id
                                  : user.user_id,
                                "action",
                                activeCoin
                              )
                                .then((d) => {
                                  if (d.status == 200) {
                                    setPendingData(d.data);
                                  }
                                })
                                .catch((e) => {
                                  console.log(e);
                                });
                            }}
                          >
                            ACTION
                          </button>
                          </>
                      ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="p2p-works-container mt-3">
                      {activeTab == 0 ? (
                        <div className="table-responsive">
                          <table className="table custom_tbl">
                            <thead>
                              <tr className="bg-pale-dark">
                                <th>Price</th>
                                <th>Volume</th>
                                <th>Total</th>
                                <th>Payment Mode</th>
                                <th>ACTION</th>
                              </tr>
                            </thead>
                            <tbody>
                              {buyList && buyList.length > 0 ? (
                                buyList.map((item) => {
                                  if (userId != item.sell_user_id)
                                    return (
                                      <tr className="">
                                        <td>{item.price}</td>
                                        <td>{item.volume - item.p2p_fee}</td>
                                        <td>
                                          {(
                                            item.price *
                                            (item.volume - item.p2p_fee)
                                          ).toFixed(4)}
                                        </td>
                                        <td>
                                          <span class="badge bg-info text-white border">
                                            Bank Transfer
                                          </span>
                                        </td>
                                        <td>
                                          {item.order_status == 0 ? (
                                            <button
                                              className="btn me-2 btn-primary"
                                              onClick={() => {
                                                setp2pList(item);
                                                showpopup(true);
                                              }}
                                            >
                                              BUY {activeCoin}
                                            </button>
                                          ) : (
                                            <div>Pending</div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                })
                              ) : (
                                <tr>
                                  <td colspan="5">
                                    <div className="alert alert-info my-2">
                                      <div className="fs-14 text-center">
                                        No Records Founds !
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : null}
                      {activeTab == 1 ? (
                        <P2PBuyNSellNew {...props} coin={activeCoin} />
                      ) : null}
                      {activeTab == 4 ? (
                        <div className="table-responsive">
                          <table
                            className="table custom_tbl"
                            style={{
                              overflow: "hidden"
                            }}
                          >
                            <thead>
                              <tr className="bg-pale-dark">
                                <th>Price</th>
                                <th>Volume</th>
                                <th>Total</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {openOrder && openOrder.length > 0 ? (
                                openOrder.map((item) => {
                                  if (
                                    userId != item.sell_user_id ||
                                    item.order_status != -2
                                  ) {
                                    return (
                                      <tr>
                                        <td>{item.price}</td>
                                        <td>{item.volume}</td>
                                        <td>{item.price * item.volume}</td>
                                        <td>
                                          {item.order_status == -1 ? (
                                            "pending"
                                          ) : item.order_status == -3 ? (
                                            "pending by Admin"
                                          ) : item.order_status == 0 ? (
                                            <button
                                              id="cnaclebtn"
                                              className="btn me-2 btn-sm btn-outline-danger"
                                              onClick={() => {
                                                document.getElementById(
                                                  "cnaclebtn"
                                                ).style.display = "none";
                                                N_p2pCancelOrder(
                                                  user?.params
                                                    ? user.params.user_id
                                                    : user.user_id,
                                                  item.order_token
                                                )
                                                  .then((data) => {
                                                    if (data.status == 200) {
                                                      NotificationManager.success(
                                                        data.message
                                                      );
                                                      document.location.reload();
                                                    } else {
                                                      NotificationManager.error(
                                                        data.message
                                                      );
                                                    }
                                                  })
                                                  .catch((e) => {
                                                    NotificationManager.error(
                                                      "Something went Wrong!!"
                                                    );
                                                  });
                                              }}
                                            >
                                              Cancel Order
                                            </button>
                                          ) : (
                                            "Pending By Seller"
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }
                                })
                              ) : (
                                <div className="mdfthemetxt">
                                  No Records Founds
                                </div>
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : null}
                      {activeTab == 2 ? (
                        <div className="table-responsive">
                          <table
                            className="table custom_tbl"
                            style={{
                              overflow: "hidden"
                            }}
                          >
                            <thead>
                              <tr className="bg-pale-dark">
                                <th>ScreenShot</th>
                                <th>Bank Transaction Id</th>
                                <th>Type</th>
                                <th>Price</th>
                                <th>Volume</th>
                                <th>Total</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {his && his.length > 0 ? (
                                his.map((item) => {
                                  return (
                                    <tr>
                                      <td>
                                        <a
                                          href={`https://ctapi.live${item.url}`}
                                          target="_blank"
                                        >
                                          Show Screenshot
                                        </a>
                                      </td>
                                      <td>{item.transection_id}</td>
                                      <td>
                                        {item.buy_user_id == userId ? (
                                          <span className="text-success">
                                            Buy
                                          </span>
                                        ) : item.sell_user_id == userId ? (
                                          <span className="text-danger">
                                            Sell
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                      <td>{item.price}</td>
                                      <td>{item.volume}</td>
                                      <td>{item.price * item.volume}</td>
                                      <td>
                                        {item.order_status == 1
                                          ? "Success"
                                          : "Rejected"}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <div className="mdfthemetxt">
                                  No Records Founds
                                </div>
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : null}
                      {activeTab == 3 ? (
                        <div className="table-responsive">
                          <table
                            className="table custom_tbl"
                            style={{
                              overflow: "hidden"
                            }}
                          >
                            <thead>
                              <tr className="bg-pale-dark">
                                <th>Screenshot</th>
                                <th>Bank Transaction Id</th>
                                <th>Price</th>
                                <th>Volume</th>
                                <th>Total</th>
                                <th>ACTION</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pendingData && pendingData.length > 0 ? (
                                pendingData.map((item) => {
                                  return (
                                    <tr>
                                      <td>
                                        <a
                                          href={`https://ctapi.live${item.url}`}
                                          target="_blank"
                                        >
                                          Show Screenshot
                                        </a>
                                      </td>
                                      <td>{item.transection_id}</td>
                                      <td>{item.price}</td>
                                      <td>{item.volume}</td>
                                      <td>{item.price * item.volume}</td>
                                      <td>
                                        <div id="btnapprvd">
                                          <button
                                            className="btn me-2 btn-outline-primary btn-sm"
                                            onClick={() => {
                                              document.getElementById(
                                                "btnapprvd"
                                              ).style.display = "none";
                                              N_p2pOrderAction(
                                                user?.params
                                                  ? user.params.user_id
                                                  : user.user_id,
                                                item.order_token,
                                                "approved"
                                              )
                                                .then((data) => {
                                                  if (data.status == 200) {
                                                    NotificationManager.success(
                                                      data.message
                                                    );
                                                    document.location.reload();
                                                  } else {
                                                    NotificationManager.error(
                                                      data.message
                                                    );
                                                  }
                                                })
                                                .catch((e) => {
                                                  NotificationManager.error(
                                                    "Something went Wrong!!"
                                                  );
                                                });
                                            }}
                                          >
                                            Approved
                                          </button>
                                          <button
                                            className="btn me-2 btn-outline-danger btn-sm"
                                            onClick={() => {
                                              document.getElementById(
                                                "btnapprvd"
                                              ).style.display = "none";
                                              N_p2pOrderAction(
                                                user?.params
                                                  ? user.params.user_id
                                                  : user.user_id,
                                                item.order_token,
                                                "rejected"
                                              )
                                                .then((data) => {
                                                  if (data.status == 200) {
                                                    NotificationManager.success(
                                                      data.message
                                                    );
                                                    document.location.reload();
                                                  } else {
                                                    NotificationManager.error(
                                                      data.message
                                                    );
                                                  }
                                                })
                                                .catch((e) => {
                                                  NotificationManager.error(
                                                    "Something went Wrong!!"
                                                  );
                                                });
                                            }}
                                          >
                                            Rejected
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <div className="mdfthemetxt">
                                  No Records Founds
                                </div>
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <P2pContent />
          <Footer />
        </>
      ) : (
        <div className="container" style={{ height: "100%" }}>
          <div className="row mt-5">
            <div className="col-6 offset-3">
              <div
                className="fs-5 card rounded-start text-center shadow-lg"
                style={{ height: "300px", background: "gainsboro" }}
              >
                <div className="text-danger fs-5 h-100">
                  <MdOutlineError
                    style={{ height: "90px", width: "90px", margin: "auto" }}
                  />
                  <div>{p2pmsg}</div>
                  <Link to="/exchange/btc-inrx" className="btn btn-danger mt-5">
                    Continue
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
