import React, { useState } from "react";
import ProfileSidebar from "./components/ProfileSidebar";
import Header from "./HomeComp/Header";
import { useSelector } from "react-redux";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NotificationManager } from "react-notifications";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export default function Reports(props) {
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const { close_order_loading, user_order_close } = useSelector(
    (state) => state.coinDBReducer
  );
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const [all_report, allReport] = useState();
  const [downloadb, downloadB] = useState(0);

  const downloadReport = () => {
    console.log("user_order_close", user_order_close);
    console.log(startDate, endDate);
    if (close_order_loading == false) {
      // let raw = {
      //   user_id : user_id,
      //   start_date : startDate,
      //   end_date : endDate,
      // }
      allReport(user_order_close);
      downloadB(1);
      // getUserTradeHistory("get","user_trade_history",raw).then((data) => {
      //   console.log("data ", data);
      //     allReport(data);
      //     downloadB(1);
      //     NotificationManager.info("Report fetch successfull. Now you can Download the report.");

      // })
    } else {
      NotificationManager.info("Report Not Ready");
    }
  };

  return (
    <div>
      <Header {...props} />
      <div className="col-lg-12">
        
          <ProfileSidebar {...props} />
        
        <div className="main-content">
          <div className="mt-3">
            <h4 className="mb-3">User Reports</h4>

            <div className="card">
              <div className="card-header fs-5">
                Get your trading report on your email
              </div>
              <div className="card-body">
                <div className="row col-md-10 sanfont">
                  <h5 className="mb-3 text-muted"> </h5>
                  <div className="col-md-4 mb-2">
                    <div className="text-muted mb-1">Start Date </div>
                    <ReactDatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <div className="text-muted mb-1">End Date </div>
                    <ReactDatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <div className="text-muted mb-1">&nbsp; </div>
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        downloadReport();
                      }}
                    >
                      {" "}
                      Get Report
                    </button>
                  </div>
                </div>
                <div className="text-secondary mt-3 mb-2">The report will include: </div>

                <span className="badge bg-light rounded-pill border me-2 fs-12">
                  {" "}
                  Exchange Trades
                </span>
                <span className="badge bg-light rounded-pill border me-2 fs-12">
                  P2P Trades
                </span>
                <span className="badge bg-light rounded-pill border me-2 fs-12">
                  STF Trades
                </span>
                <span className="badge bg-light rounded-pill border me-2 fs-12">
                  {" "}
                  Current Coin Balance
                </span>
                <span className="badge bg-light rounded-pill border me-2 fs-12">
                  {" "}
                  Deposit and Withdrawals
                </span>
                <span className="badge bg-light rounded-pill border me-2 fs-12">
                  {" "}
                  Ledger History
                </span>
                <span className="badge bg-light rounded-pill border me-2 fs-12">
                  {" "}
                  Airdrops and other distributions
                </span>

                {downloadb == 1 ? (
                  <>
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button btn btn-secondary mt-2 mb-2 bold"
                      table="table-to-xls"
                      filename="tablexls"
                      sheet="tablexls"
                      buttonText="Download Trading Report"
                    />
                  </>
                ) : (
                  ""
                )}

                <div className="">
                  <table id="table-to-xls" className="h">
                    <tr>
                      <th>#</th>
                      <th>Price</th>
                      <th>Volume</th>
                      <th>Currency</th>
                      <th>Date</th>
                    </tr>
                    {all_report
                      ? all_report.map((item, i) => (
                          <>
                            <tr>
                              <td>{++i}</td>
                              <td>{item.raw_price}</td>
                              <td>{item.volume}</td>
                              <td>
                                {item.currency_type}/{item.compare_currency}
                              </td>
                              <td>
                                {new Date(
                                  Number(item.timestamp)
                                ).toLocaleString()}
                              </td>
                            </tr>
                          </>
                        ))
                      : null}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
