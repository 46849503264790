import React, { useEffect } from "react";
import Header from "./HomeComp/Header";
import ProfileSidebar from "./components/ProfileSidebar";
import { N_getUserProfile } from "./redux/helpers/api_functions_new";
import { useSelector } from "react-redux";
import Loader from "./components/Loader";
import { Link } from "react-router-dom";
import { AiOutlineEdit, AiOutlineFileText } from "react-icons/ai";
import { BsShieldCheck } from "react-icons/bs";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FBTCins, FaSortDown, FaSortUp } from "react-icons/fa";
import { Sparklines, SparklinesLine } from "react-sparklines";
import Footer from "./HomeComp/Footer";
export default function Api(props) {
  // const { user } = useSelector((state) => state.AuthReducer);
  // const [loading, setLoading] = React.useState(true);
  // const [profile, setProfile] = React.useState({});
  // useEffect(() => {
  //   N_getUserProfile(user?.params ? user.params.user_id : user.user_id)
  //     .then((d) => {
  //       // console.log("userProfile",d?.params.profile_info);
  //       if (d.status === 200) {
  //         setProfile(d?.params.profile_info);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }, []);
  return (
    <>
      <Header {...props} />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <ProfileSidebar />
        </div>

        <main className="s-layout__content">
          <div className="dashboard_header">
            <h3 className="header_title">Api Management</h3>
            <div className="flex_row">
             <div> <a href="" className="btn btn-pink rounded-pill me-2">
                Creat API
              </a></div>
             <div> <a href="" className="btn btn-outline-pink rounded-pill me-2">
                Withdraw to API Address Book
              </a> </div>

             <div>  <a href="" className="btn btn-outline-pink rounded-pill">
                Abnormal API Call Notifications
              </a></div>
            </div>
          </div>
          <div className="account_page">
            <div className="col-lg-12 col-md-12 col-sm-12 mt-3 table-responsive">
              <div className="text-center border-bottom-0">
                <img src="/img/no-data.png" className="no-data-found" />
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
