import React from "react";
import Loader from "./components/Loader";
import { useSelector, useDispatch } from "react-redux";
export default function TradeTab(props) {
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const { trade_history, trade_loading } = useSelector(
    (state) => state.coinDBReducer
  );
  const coin = props?.match?.params?.id?.split("-");
  const coinname = coin[0] + coin[1];
  // let l = trade_history ? [coinname]?.length - 1 : 0;
  let harr = [];
  function reverseArr(input) {
    let ret = new Array();
    for (var i = input.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    const ret1 = ret.sort((a, b) => b.timestamp - a.timestamp);
    return ret1;
  }
  if (
    trade_history &&
    trade_history[coinname] &&
    trade_history[coinname].length > 0
  )
    harr = reverseArr(trade_history[coinname]);
  // console.log(harr);
  return (
    <div className="card">
      <div className="card-header">Trade History</div>
      <div className="card-body p-0">
        <div className="trade_height custom_scrollbar">
          <table className="exchange_tbl order-book-table ">
            <thead className="sticky_thead">
              <tr>
                <th>
                  <h6>Price</h6>
                </th>
                <th className="">
                  <h6>Volume</h6>
                </th>

                <th className="text-end">
                  <h6>Time</h6>
                </th>
              </tr>
            </thead>
            <tbody className="sell">
              {trade_loading ? (
                <Loader />
              ) : harr?.length != 0 ? (
                harr ? (
                  harr?.map((d, index, arr) => (
                    <TradeRow
                      isSell={
                        index === 9
                          ? false
                          : parseFloat(d.raw_price) >=
                            parseFloat(harr ? arr[index + 1]?.raw_price : 0)
                          ? false
                          : true
                      }
                      price={Number(d.raw_price)}
                      volume={Number(d.volume)}
                      timestamp={d.timestamp}
                      key={index}
                    />
                  ))
                ) : (
                  <td className="text-center py-5 text-muted" colspan="3">
                    <div>
                      <i class="fa fa-folder-open-o fs-2"></i>
                    </div>
                    <div className="fs-12">No Trade History Found !</div>
                  </td>
                )
              ) : (
                <td className="text-center py-5 text-muted" colspan="3">
                  <div>
                    <i class="fa fa-folder-open-o fs-2"></i>
                  </div>
                  <div className="fs-12">No Trade History Found !</div>
                </td>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function TradeRow(props) {
  return (
    <tr
      className=""
      // style={
      //   props.isSell
      //     ? {
      //         background: "rgba(241, 67, 47, 0.1)",
      //         color: "#f00000",
      //         padding: "7.79px 0px",
      //         fontSize: "12px"
      //       }
      //     : {
      //         background: "rgba(35, 172, 80, 0.1)",
      //         color: "#2bcd76",
      //         padding: "7.79px 0px",
      //         fontSize: "12px"
      //       }
      // }
    >
      <td>
        {/* <i
          className={`fas ${props.isSell ? "fa-arrow-down text-green me-1" : "fa-arrow-up text-red me-1"}`}
        ></i> */}
        <span className={` ${props.isSell ? "text-green" : "text-red"}`}>
          {props.price}
        </span>
      </td>
      <td>
        {props.volume.toString().length > 6
          ? props.volume.toString().substr(0, 8)
          : props.volume}
      </td>
      <td className="text-end">
        {new Date(props.timestamp).getHours() +
          ":" +
          new Date(props.timestamp).getMinutes() +
          ":" +
          new Date(props.timestamp).getSeconds()}
        {/* {moment(props.time).format("LTS")} */}
        {/* {props.time.raw_price} */}
      </td>
    </tr>
  );
}
