import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { getCoinRate, getRound } from "./redux/helpers/helper_functions";
import { Link } from "react-router-dom";
import { N_transferAmonut } from "./redux/helpers/api_functions_new";

export default function Overview() {
  const { isLoggedIn, user, profile  } = useSelector((state) => state.AuthReducer);
  const { wallet, paired_curency_price, coins, wallet_loading } = useSelector(
    (state) => state.coinDBReducer
  );
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [symbol, setSymbol] = useState('');
    const [amount, setAmount] = useState(0);

  const [total_inr, setTotalInr] = useState(0);
  const [total_usdt, setTotalUsdt] = useState(0);
  const [total_btc, setTotalBtc] = useState(0);

  const [total_p2p_inr, setp2pinr] = useState(0);
  const [total_p2p_usdt, setp2pusdt] = useState(0);
  const [total_p2p_btc, setp2pbtc] = useState(0);


  const [total_futur_inr, setfuturinr] = useState(0);
  const [total_futur_usdt, setfuturusdt] = useState(0);
  const [total_futur_btc, setfuturbtc] = useState(0);

  const [total_margin_inr, setmargininr] = useState(0);
  const [total_margin_usdt, setmarginusdt] = useState(0);
  const [total_margin_btc, setmarginbtc] = useState(0);
  
  useEffect(()=>{
    if (!wallet_loading) {
      getWallets();
    }
  }, [wallet, coins, paired_curency_price])

  function getWallets() {
      let total = 0;
      let total_p2pbalance = 0;
      let total_futurbalance = 0;
      let total_marginbalance = 0;

      wallet && Object.keys(wallet).map((res, i) => {
        let wallet_type = wallet[res]?.symbol.toUpperCase();
        let rate = getCoinRate(coins, wallet_type);
        let inr_val =rate*(getRound(wallet[res]?.balance));
        total_p2pbalance = total_p2pbalance + rate*(getRound(wallet[res]?.p2p_balance));
        total_futurbalance = total_futurbalance + rate*(getRound(wallet[res]?.future_balance));
        total_marginbalance = total_marginbalance + rate*(getRound(wallet[res]?.margin_balance));
        total = total + parseFloat(inr_val);
       
      });
      setTotalInr(getRound(total));
      setTotalUsdt(getRound(total/paired_curency_price.USDT))
      setTotalBtc(getRound(total/paired_curency_price.BTC));

      setp2pinr(getRound(total_p2pbalance));
      setp2pusdt(getRound(total_p2pbalance/paired_curency_price.USDT));
      setp2pbtc(getRound(total_p2pbalance/paired_curency_price.BTC));

      setfuturinr(getRound(total_futurbalance));
      setfuturusdt(getRound(total_futurbalance/paired_curency_price.USDT));
      setfuturbtc(getRound(total_futurbalance/paired_curency_price.BTC));

      setmargininr(getRound(total_marginbalance));
      setmarginusdt(getRound(total_marginbalance/paired_curency_price.USDT));
      setmarginbtc(getRound(total_marginbalance/paired_curency_price.BTC));
  }


  const coin_data = coins && Object.values(coins).map((item)=>{
    return (
      <option value={item.symbol}>{item.symbol}</option>
    )
  })
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="col-lg-12 mt-1">
            <div className="alert alert-warning fs-12">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>{" "}
              Complete identity verification for stronger security and higher
              withdrawal limit. Verified accounts have: (1) access to all of
              AMEXA's features and services, higher withdrawal limit, and (2)
              enhanced account security, ensuring the safet
            </div>
          </div>
          <div className="marginTop ">
            <div className="account_page">
              <div className="container">
            <div className="page-content-title">
              <h3 className="">Assets Overview</h3>
            </div>

            <div className="col-lg-12">
              <div className="flex_row mb-4">
                <div>
                  {" "}
                  <span className="desc_card">{total_usdt}USDT</span>
                  <span className="text-lgray"> ≈ {total_inr} INR </span>
                </div>

                <div className="">
                  <Link to="/AssetsDeposit" className="btn btn-pink me-2">
                    Deposit
                  </Link>
                  <Link to="/exchange/btc-inrx" className="btn btn-outline-pink me-2">
                    Buy Crypto
                  </Link>
                  <Link to="/AssetsWithdraw" className="btn btn-outline-pink">
                    Withdraw
                  </Link>
                </div>
              </div>
              <div className="row mycard">
                <div className="col-lg-4 mb-4">
                  <div className="custom_card borderTop teal">
                    <div className="head">
                      <h4>Funding Account(Main)</h4>
                    </div>
                    <div>
                      {" "}
                      <span className="desc_card">{total_btc} </span>
                      <span>BTC</span>
                    </div>
                    <div className="subdesc_card"> ≈ {total_usdt}USD</div>
                    <div className="footer">
                      <a
                      data-bs-toggle="modal"
                      data-bs-target="#transfer_modal"
                      >Transfer</a> 
                      {/* <div class="verticle_line"></div>
                      <a href="#">Lend</a> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-4">
                  <div className="custom_card borderTop lgray">
                    <div className="head">
                      <h4>Margin Account</h4>
                    </div>
                    <div>
                      {" "}
                      <span className="desc_card">{total_margin_btc} </span>
                      <span>BTC</span>
                    </div>
                    <div className="subdesc_card"> ≈ {total_margin_usdt}USD</div>
                    <div className="footer">
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#transfer_margin"
                      >Transfer</a> 
                       {/* <div class="verticle_line"></div>
                      <a href="#">Lend</a> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-4">
                  <div className="custom_card borderTop lblue">
                    <div className="head">
                      <h4>Future Account</h4>
                    </div>
                    <div>
                      {" "}
                      <span className="desc_card">{total_futur_btc} </span>
                      <span>BTC</span>
                    </div>
                    <div className="subdesc_card"> ≈ {total_futur_usdt}USD</div>
                    <div className="footer">
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#transfer_future"
                      >Transfer</a> 
                      {/* <div class="verticle_line"></div>
                      <a href="#">Lend</a> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 mb-4">
                  <div className="custom_card borderTop dgray">
                    <div className="head">
                      <h4>P2P Account</h4>
                    </div>
                    <div>
                      {" "}
                      <span className="desc_card">{total_p2p_btc} </span>
                      <span>BTC</span>
                    </div>
                    <div className="subdesc_card"> ≈ {total_p2p_usdt}USD</div>
                    <div className="footer">
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#transfer_p2p"
                      >Transfer</a> 
                      {/* <div class="verticle_line"></div>
                      <a href="#">Lend</a> */}
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-4 mb-4">
                  <div className="custom_card borderTop dblue">
                    <div className="head">
                      <h4>Trading Bot Account</h4>
                    </div>
                    <div>
                      {" "}
                      <span className="desc_card">0.00 </span>
                      <span>BTC</span>
                    </div>
                    <div className="subdesc_card"> ≈ 0.00USD</div>
                    <div className="footer">
                      <a href="#">Transfer</a> <div class="verticle_line"></div>
                      <a href="#">Lend</a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <h4>FAQs</h4>
              <div class="faq_row">
                <div class="faq-head">
                  What are the differences among the AMEXA Funding Account,
                  Trading Account, Margin Account, Futures Account, and
                  Financial Account?
                </div>
                <div class="faq-desc">
                  <span>
                    The Funding Account is used for crypto deposits,
                    withdrawals, and purchases via credit/debit cards, as well
                    as margin lending. The Trading Account is used for crypto
                    trading. The Margin Account is used for margin borrowing and
                    trading. The Futures Account is used for futures trading.
                    The Financial Account is used for staking and financial
                    management, with an annual yield of up to 20%.
                  </span>
                </div>
              </div>

              <div class="faq_row">
                <div class="faq-head">
                  What are the differences among Loans, Liabilities, and the
                  Estimated Value of your account?
                </div>
                <div class="faq-desc">
                  <span>
                    Loans refer to the amount of crypto lent out from the
                    Funding Account in the margin market. Liabilities refer to
                    the amount of crypto borrowed into the Margin Account in the
                    margin market. Both Liabilities and Loans are included in
                    the Estimated Value of your account. Note: Loans will not
                    generate KCS Bonuses but Liabilities will.
                  </span>
                </div>
              </div>
            </div>
          </div></div></div>
        </main>
        <div class="modal fade" tabindex="-1" id="transfer_modal">
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Transfer</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
            <label className="">from</label>
             <select className="form-control"
             onChange={(e)=>{
              setFrom(e.target.value);
             }}>
             <option selected> Please Select</option>
              <option value="funding">Funding Account</option>
             </select>
             <label className="">to</label>
             <select className="form-control"
             onChange={(e)=>{
              setTo(e.target.value);
             }}>
              <option selected> Please Select</option>
              <option value="p2p">p2p Account</option>
              <option value="margin">margin Account</option>
              <option value="future">future Account</option>
             </select>
             <label className="">Coin</label>
             <select className="form-control"
             onChange={(e)=>{
              setSymbol(e.target.value);
             }}>
             <option selected> Please Select</option>
              {coin_data}
             </select>
             <label className="">Amount</label>
             <input className="form-control" type="number" 
             onChange={(e)=>{
              setAmount(e.target.value)
             }}/>
             <div className="d-flex">
             <button className="btn btn-pink"
              data-bs-dismiss="modal"
              aria-label="Close"
             onClick={(e)=>{
              e.preventDefault()
              if(from =='funding' && to && symbol && amount>0) {
                let type = 'add';
                let wallet_type = symbol;
                let volume = amount;
                let action = to;
                N_transferAmonut(type, wallet_type, user?.params ? user.params.user_id : user.user_id, volume, action)
                  .then((data)=>{
                    if(data.status == 200) {
                      NotificationManager.success(data.message)
                      setTimeout(()=>{
                        document.location.reload();
                      }, 2000)
                      
                    } else {
                      NotificationManager.error(data.message);
                    }
                  });
              } else {
                NotificationManager.error("Fill All Data");
              }
              
             }}
             >Confirm</button>
             </div>
            
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" tabindex="-1" id="transfer_p2p">
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Transfer</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
            <label className="">from</label>
             <select className="form-control"
             onChange={(e)=>{
              setFrom(e.target.value);
             }}>
              <option selected> Please Select</option>
              <option value="p2p">p2p Account</option>
             </select>
             <label className="">to</label>
             <select className="form-control"
             onChange={(e)=>{
              setTo(e.target.value);
             }}>
             <option selected> Please Select</option>
              <option value="funding">funding Account</option>
             </select>
             <label className="">Coin</label>
             <select className="form-control"
             onChange={(e)=>{
              setSymbol(e.target.value)
             }}>
             <option selected> Please Select</option>
              {coin_data}
             </select>
             <label className="">Amount</label>
             <input className="form-control" type="number" 
             onChange={(e)=>{
              setAmount(e.target.value)
             }} />
             <div className="d-flex">
             <button className="btn btn-pink"
               onClick={(e)=>{
                e.preventDefault()
                if(from =='p2p' && to && symbol && amount>0) {
                  let type = 'minus';
                  let wallet_type = symbol;
                  let volume = amount;
                  let action = from;
                  N_transferAmonut(type, wallet_type, user?.params ? user.params.user_id : user.user_id, volume, action)
                    .then((data)=>{
                      if(data.status == 200) {
                        NotificationManager.success(data.message)
                        setTimeout(()=>{
                          document.location.reload();
                        }, 2000)
                      } else {
                        NotificationManager.error(data.message);
                      }
                    });
                } else {
                  NotificationManager.error("Fill All Data");
                }
                
               }}
             >Confirm</button>
             </div>
            
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" tabindex="-1" id="transfer_margin">
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Transfer</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
            <label className="">from</label>
             <select className="form-control"
              onChange={(e)=>{
                setFrom(e.target.value);
               }}>
             <option selected> Please Select</option>
              <option value="margin">Margin Account</option>
             </select>
             <label className="">to</label>
             <select className="form-control"
              onChange={(e)=>{
                setTo(e.target.value);
               }}>
             <option selected> Please Select</option>
              <option value="funding">Funding Account</option>
             </select>
             <label className="">Coin</label>
             <select className="form-control"
              onChange={(e)=>{
                setSymbol(e.target.value);
               }}>
             <option selected> Please Select</option>
              {coin_data}
             </select>
             <label className="">Amount</label>
             <input className="form-control" type="number" 
              onChange={(e)=>{
                setAmount(e.target.value);
               }}/>
             <div className="d-flex">
             <button className="btn btn-pink"
               onClick={(e)=>{
                e.preventDefault()
                if(from =='margin' && to && symbol && amount>0) {
                  let type = 'minus';
                  let wallet_type = symbol;
                  let volume = amount;
                  let action = from;
                  N_transferAmonut(type, wallet_type, user?.params ? user.params.user_id : user.user_id, volume, action)
                    .then((data)=>{
                      if(data.status == 200) {
                        NotificationManager.success(data.message)
                        setTimeout(()=>{
                          document.location.reload();
                        }, 2000)
                      } else {
                        NotificationManager.error(data.message);
                      }
                    });
                } else {
                  NotificationManager.error("Fill All Data");
                }
                
               }}
             >Confirm</button>
             </div>
            
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" tabindex="-1" id="transfer_future">
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Transfer</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
            <label className="">from</label>
             <select className="form-control"
              onChange={(e)=>{
                setFrom(e.target.value);
               }}>
             <option selected> Please Select</option>
              <option value="future">Future Account</option>
             </select>
             <label className="">to</label>
             <select className="form-control"
              onChange={(e)=>{
                setTo(e.target.value);
               }}>
             <option selected> Please Select</option>
              <option value="funding">Funding Account</option>
             </select>
             <label className="">Coin</label>
             <select className="form-control"
              onChange={(e)=>{
                setSymbol(e.target.value);
               }}>
             <option selected> Please Select</option>
              {coin_data}
             </select>
             <label className="">Amount</label>
             <input className="form-control" type="number" 
             onChange={(e)=>{
              setAmount(e.target.value);
             }}/>
             <div className="d-flex">
             <button className="btn btn-pink"
               onClick={(e)=>{
                e.preventDefault()
                if(from =='future' && to && symbol && amount>0) {
                  let type = 'minus';
                  let wallet_type = symbol;
                  let volume = amount;
                  let action = from;
                  N_transferAmonut(type, wallet_type, user?.params ? user.params.user_id : user.user_id, volume, action)
                    .then((data)=>{
                      if(data.status == 200) {
                        NotificationManager.success(data.message)
                        setTimeout(()=>{
                          document.location.reload();
                        }, 2000)
                      } else {
                        NotificationManager.error(data.message);
                      }
                    });
                } else {
                  NotificationManager.error("Fill All Data");
                }
                
               }}
             >Confirm</button>
             </div>
            
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}
