import React from "react";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import Carousel from "react-grid-carousel";
import { FaCoins } from "react-icons/fa";
import { BiGitCompare } from "react-icons/bi";
import { HiCubeTransparent } from "react-icons/hi";
import { FiUser, FiGlobe, FiUsers } from "react-icons/fi";

import Header from "./Header";
import Footer from "./Footer";

export default function RewardHub() {
  return (
    <>
      <Header />
      <div className="page-content">
        <div className="row bg-light">
           
          <div class="tab_area bg-transparent"> 
          <div className="container">
            <div className="d-flex justify-content-between">
            <div class="custom_pills">
              <div class="nav nav-pills" id="nav-tab" role="tablist">
                <div
                  class="nav-item nav-link active"
                  href="#new_user"
                  data-toggle="tab"
                >
                  New Users Only
                </div>
                <div
                  class="nav-item nav-link"
                  href="#pro_trading"
                  data-toggle="tab"
                >
                  Pro Trading
                </div>
              </div>
            </div>
            
            <div className="">
              <span className="gray_badge fw-bold">
                <img
                  src="https://assets.staticimg.com/landing-web/1.18.31/static/coupons-pc.632f4415.svg"
                  alt="couponsLight-icon"
                />{" "}
                My Coupons
              </span>
              <span className="gray_badge fw-bold">
                <img
                  src="https://assets.staticimg.com/landing-web/1.18.31/static/history-pc.959967e8.svg"
                  alt="couponsLight-icon"
                />{" "}
                Task History
              </span>
            </div>
            </div>
            </div>
          </div>
        </div>
        <div className="tab-content">
          <div className="tab-pane fade active show" id="new_user">
            <div className="bg-gredient-pink spacer">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-7 mb-3">
                    <div className="banner_left_inner">
                      <h2 className="bannertxt">
                        Up to 3,200 USDT for a limited time.
                      </h2>
                      <div className="mb-4">
                        {" "}
                        <span className="custom_badge bg-soft-pink">
                          {" "}
                          Ends in 52d 02h 45m
                        </span>
                      </div>

                      <div className="mt-5">
                        <img src="./img/users-trading.png" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-5 text-end">
                    <div className="recieved_amt">
                      You have recieved{" "}
                      <i class="fa-regular fa-circle-question ms-1"></i>{" "}
                    </div>
                    <div class="recieved_amt_value">
                      <span class="num">4</span>/5 USDT
                    </div>

                    <div className="reward_accordion">
                      <div
                        class="accordion accordion-flush"
                        id="accordionExample"
                      >
                        <div class="accordion-item steps_collapse">
                          <h2 class="accordion-header">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              <div className="circle_bullets">
                                <span>1</span>
                              </div>
                              <div>
                                <h5>Sign Up</h5>{" "}
                                <p>
                                  Complete to receive up to 400 USDT in rewards.
                                </p>
                              </div>
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            class="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <div className="pink-hero">
                                <img
                                  src="./img/reward-cash.png"
                                  width="100px"
                                />

                                <h5>0.1～100 USDT</h5>
                                <h6>Rewards for Withdrawal</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item steps_collapse">
                          <h2 class="accordion-header">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              <div className="circle_bullets active">
                                <span>2</span>
                              </div>
                              <div>
                                <h5>First Deposit/Buy Crypto </h5>
                                <p>
                                  Complete to receive up to 400 USDT in rewards.
                                </p>
                              </div>
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="pink-hero">
                                    <img
                                      src="./img/reward-cash.png"
                                      width="100px"
                                    />

                                    <h5>0.1～100 USDT</h5>
                                    <h6>Rewards for Withdrawal</h6>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="pink-hero">
                                    <img
                                      src="./img/reward-cash.png"
                                      width="100px"
                                    />

                                    <h5>0.1～100 USDT</h5>
                                    <h6>Rewards for Withdrawal</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item steps_collapse">
                          <h2 class="accordion-header">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              <div className="circle_bullets">
                                <span>3</span>
                              </div>
                              <div>
                                <h5>First Trade</h5>{" "}
                                <p>
                                  Complete to receive up to 400 USDT in rewards.
                                </p>
                              </div>
                            </button>
                          </h2>
                          <div
                            id="collapseThree"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <div className="pink-hero">
                                <img
                                  src="./img/reward-cash.png"
                                  width="100px"
                                />

                                <h5>0.1～100 USDT</h5>
                                <h6>Rewards for Withdrawal</h6>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="accordion-item steps_collapse">
                          <h2 class="accordion-header">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#headingFour"
                              aria-expanded="false"
                              aria-controls="headingFour"
                            >
                              <div className="circle_bullets">
                                <span>4</span>
                              </div>
                              <div>
                                <h5>Pro Trading Tasks</h5>
                                <p>
                                  Complete to receive up to 400 USDT in rewards.
                                </p>
                              </div>
                            </button>
                          </h2>
                          <div
                            id="headingFour"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <div className="pink-hero">
                                <img
                                  src="./img/reward-cash.png"
                                  width="100px"
                                />

                                <h5>0.1～100 USDT</h5>
                                <h6>Rewards for Withdrawal</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="page_spacer">
              <div className="container  faq">
                <div className="col-lg-12 text-center">
                  <h2 class="para_heading"> FAQs </h2>
                </div>
                <div className="col-lg-12">
                  <div
                    class="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingOne">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          What are new user rewards?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          New user rewards are rewards provided to new AMEXA
                          users. Rewards are given to new users when they
                          complete their sign-up, first deposit/crypto purchase,
                          first trade, and pro trading. Rewards include USDT and
                          coupons, worth up to 3200 USDT. Users must complete
                          the corresponding tasks within 60 days after signing
                          up for their AMEXA account in order to receive the
                          rewards. Each reward can only be received once by each
                          user.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingTwo">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          Who is eligible to participate and receive new user
                          rewards?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          The following types of users are eligible to
                          participate and receive new user rewards: (1) Users
                          who signed up for their AMEXA accounts after 08:00:00
                          (UTC) May 23, 2023. (2) Users who signed up for their
                          AMEXA accounts after 08:00:00 (UTC) March 1, 2023 and
                          have not yet made their first deposit or crypto
                          purchase.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingThree">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          What are rewards for withdrawal?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          To qualify for withdrawal, accumulate the required
                          amount of crypto rewards within 60 days of signing up
                          for your AMEXA account. Ensure you withdraw within
                          the specified time frame, as failure to do so will
                          result in the inability to withdraw those crypto
                          rewards. The rewards will be credited to your Funding
                          Account within 14 working days after initiating a
                          withdrawal. Contact customer service if you do not
                          receive your rewards within this time frame.
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          New User Rewards Details
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFour"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <p>
                            {" "}
                            Sign-Up Reward: Sign up for a AMEXA account to
                            receive a USDT reward. The reward amount will be
                            randomly determined within a set range.
                          </p>
                          <p>
                            {" "}
                            First Deposit/Buy Crypto Reward: Deposit or buy
                            crypto for the first time (any amount) to receive
                            USDT and coupon rewards. Eligible deposits or
                            purchases can be made through Fiat Deposit, P2P,
                            Third-Party, Fast Trade, or on-chain transfers.
                            Please note that deposits or purchases using assets
                            from Red Envelopes or Trial Funds are not eligible.
                            Reward amounts will be randomly determined within a
                            set range.
                          </p>
                          <p>
                            {" "}
                            First Trade Reward: Complete your first trade (any
                            amount) to receive a USDT reward. Trades can be
                            spot, futures, margin, or bot trades. The reward
                            amount will be randomly determined within a set
                            range. Zero-fee trades do not count.
                          </p>
                          <p>
                            {" "}
                            Limited-Time Gift Pack: Complete your first trade
                            within 7 days of signing up for your AMEXA account
                            to receive an additional gift pack, which includes
                            VIP Trial Coupons, Futures Deduction Coupons,
                            Trading Bot Fee Rebate Coupons, etc.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tab-pane fade active show" id="p2p_data"></div>
          </div>
          <div className="tab-pane fade active show" id="pro_trading"></div>
        </div>
      </div>
      <Footer />
    </>
  );
}
