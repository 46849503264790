import React from "react";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import Carousel from "react-grid-carousel";
import { FaCoins } from "react-icons/fa";
import { BiGitCompare } from "react-icons/bi";
import { HiCubeTransparent } from "react-icons/hi";
import { FiUser, FiGlobe, FiUsers } from "react-icons/fi";

import Header from "./Header";
import Footer from "./Footer";

export default function AffiliateHome() {
  return (
    <>
      <Header />
      <div className="page-content">
        <div className="tab_area">
          <div className="container custom_pills">
            <div className="nav nav-pills" id="nav-tab" role="tablist">
              <div
                className="nav-item nav-link fs-16 me-4 active"
                href="#reward_hub"
                data-toggle="tab"
              >
                Earn Together With Your Friends
              </div>
              <div
                className="nav-item nav-link fs-16"
                href="#affiliate_prog"
                data-toggle="tab"
              >
                Affiliate Program
              </div>
            </div>
          </div>
        </div>

        <div className="tab-content">
          <div
            className="tab-pane fade active show"
            data-toggle="tab"
            id="reward_hub"
          >
            <div className="reward_banner page_spacer">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-12 col-lg-6 mb-3">
                    <div className="banner_left_inner">
                      <h2 className="maintxt">
                        <span className="text-pink"> Earn </span> Together With
                        Your Friends
                      </h2>
                      <p className="mb-2">
                        {" "}
                        Share Up to 20% Commission From Your Referees' Trades
                      </p>
                      <a className="mt-4 text-pink" href="/">
                        Know More
                      </a>

                    

                     <div className="col-lg-6">
                     <img src="/img/tokenomics.png" alt=""></img>
                     </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div class="buysell-card mt-2 mb-2">

                      
                   
                    <div class="p-4">

                    <div className="row mb-4">
                      <div className="col-lg-6">
                        <div> <span className="small-info">
                        Your Commission Rate : 
                        </span>
                      </div>
                      </div>
                      <div className="col-lg-6">
                        <div> <span className="small-info">
                             Invitee's Cashback Rate:

                            </span></div>
                      </div>
                      </div>
                            <div>
                            
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  value=""
                                  placeholder="Limit: 10 -5,000"
                                />
                                <span class="input-group-text">
                                 
                                 <i className="fa fa-copy fs-5 text-muted"></i>
                                </span>
                              </div>
                            </div>

                            <div>
                              
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control "
                                  value=""
                                  placeholder="Limit: 9.52 - 4,761.9"
                                />
                                <span class="input-group-text">
                                  {" "}
                                  <span class="input-group-text">
                                  <i className="fa fa-copy fs-5 text-muted"></i>
                                  </span>
                                </span>
                              </div>
                            </div>
                         

                            <div class="d-grid mt-2">
                              <button class="btn btn-success btn-lg">
                               Sign UP / Log In
                              </button>
                            </div>
                           
                          </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade " data-toggle="tab" id="affiliate_prog">
            <div className="inner_page_banner">
              {/* <div className="banner_left"></div>
      <div className="banner_right"></div> */}
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-12 col-lg-7 mb-3">
                    <div className="banner_left_inner">
                      <h2 className="bannertxt">
                        <span className="text-pink">AMEXA</span> Affiliate
                        Program
                      </h2>
                      <p className="mb-4">
                        {" "}
                        Welcome to the AMEXA Affiliate Program. Refer friends
                        to trade on AMEXA and receive up to 60% commission
                        from their trading fees. When your invitees also become
                        AMEXA Affiliates (i.e., your sub-affiliates), you
                        will further receive second-level commission.
                      </p>

                      <div className="mt-5">
                        <a className="btn btn-pink btn-lg px-5" href="/">
                          Apply Now
                        </a>
                        <a className="btn border-0 bg-transaprent btn-lg text-lgray" href="/">
                          Already an Affiliate ? Log In
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-5">
                    <img
                      src="/img/steps.png"
                      className="img-fluid"
                      width="80% "
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="container my-5">
              <div className="currency_section">
                <Carousel scrollSnap autoplay>
                  <Carousel.Item>
                    <img
                      width="100%"
                      src="https://5gexpo.net/northamerica/wp-content/uploads/2022/04/Ad-Banners-728x90-5G-NA.png"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      width="100%"
                      src="https://5gexpo.net/northamerica/wp-content/uploads/2022/04/Ad-Banners-728x90-5G-NA.png"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      width="100%"
                      src="https://5gexpo.net/northamerica/wp-content/uploads/2022/04/Ad-Banners-728x90-5G-NA.png"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
              {/* <div className="row">{banner_child}</div> */}
            </div>

            <div className="spacer ">
              <div className="container">
                <div class="row align-items-center">
                  <div class="col-lg-4 col-md-6 col-12 d-flex justify-content-center ">
                    <img src="/img/pngwing.com-1.png" class="img-fluid" />
                  </div>
                  <div class="col-lg-8 col-md-6 mb-5">
                    <h2 class="para_heading">
                      {" "}
                      The Highest Commissions in the Industry{" "}
                    </h2>

                    <div className="row mt-5">
                      <div className="col-lg-4">
                        <FiUsers className="fs-3 mb-4 text-lgray" />
                        <h3>16,000+</h3>
                        <p className="lead text-lgray">Number of Affiliate</p>
                      </div>
                      <div className="col-lg-4">
                        <HiCubeTransparent className="fs-3 mb-4 text-lgray" />
                        <h3>100,000,000+ </h3>
                        <p className="lead text-lgray">Commission Paid Out </p>
                      </div>
                      <div className="col-lg-4">
                        <FiGlobe className="fs-3 mb-4 text-lgray" />
                        <h3>200+</h3>
                        <p className="lead text-lgray">Countries</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="page_spacer">
              <div className="container">
                <div className="para_heading text-center">
                  How to Earn Commissions
                </div>
                <div className="row d-flex align-center justify-content-center mt-5">
                  <div className="col-lg-4 col-md-6 col-12 padding_0">
                    <div className="card bg-transparent border-0 text-center">
                      <div className="card-body padding_5">
                        <div class="text-center mb-3">
                          <div class="vtimeline_no active"> 1</div>
                          <div class="vtimeline_right"></div>
                        </div>

                        <h4 className="">Apply to become an affiliate</h4>
                        <p className="text-lgray">
                          Anyone with community, media, or other resources can
                          apply to become a AMEXA affiliate.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 padding_0">
                    <div className="card bg-transparent border-0 text-center">
                      <div className="card-body padding_5">
                        <div class="text-center mb-3">
                          <div class="vtimeline_left"></div>
                          <div class="vtimeline_no "> 2</div>
                          <div class="vtimeline_right"></div>
                        </div>

                        <h4>Promote AMEXA</h4>
                        <p className="text-lgray">
                          Share your referral link to your community, followers,
                          or other channels.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 padding_0">
                    <div className="card bg-transparent border-0 text-center">
                      <div className="card-body padding_5">
                        <div class="text-center mb-3">
                          <div class="vtimeline_left"></div>
                          <div class="vtimeline_no "> 3</div>
                        </div>

                        <h4>Claim commission</h4>
                        <p className="text-lgray">
                          You will earn commission when users who signed up via
                          your referral link make trades.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="features_section ">
              <div className="container">
                <div class="row align-items-center">
                  <div class="col-lg-4 col-md-6 col-12 d-flex justify-content-center ">
                    <img src="/img/phone-1.png" class="img-fluid" />
                  </div>
                  <div class="col-lg-8 col-md-6 mb-5">
                    <h2 class="para_heading">
                      {" "}
                      How much commission can I earn?{" "}
                    </h2>
                    <p className="lead text-lgray my-5">
                      When invitees conduct spot trading or futures trading on
                      AMEXA, you will receive up to 60% commission from their
                      trading fees.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="spacer">
              <div className="container">
                <div className="para_heading text-center">
                  Why become a AMEXA affiliate?
                </div>
                <div className="row d-flex align-center justify-content-center mt-5">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="card bg-transparent border-0 mb-4">
                      <div className="card-body padding_5">
                        <h4>High Commissions</h4>
                        <p className="text-lgray">
                          Daily commissions of up to 60% of trading fees, and
                          permanent affiliate relationships.
                        </p>
                      </div>
                    </div>
                    <div className="card bg-transparent border-0 mb-4">
                      <div className="card-body padding_5">
                        <h4 className="">Premium Brand</h4>
                        <p className="text-lgray">
                          With the goal of facilitating the free flow of digital
                          assets across the globe, AMEXA is a premium brand
                          that constantly attracts new users into the
                          cryptocurrency space.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="card bg-transparent border-0 mb-4">
                      <div className="card-body padding_5">
                        <h4>Transparent Referral System</h4>
                        <p className="text-lgray">
                          Our visualized referral dashboard provides affiliates
                          with comprehensive and multi-channel commission
                          management.
                        </p>
                      </div>
                    </div>
                    <div className="card bg-transparent border-0 mb-4">
                      <div className="card-body padding_5">
                        <h4>Second-Tier Commissions</h4>
                        <p className="text-lgray">
                          Benefit from our unique multi-tier commission system
                          (earn even more with second-tier commissions)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container faq">
              <div className="col-lg-12 text-center">
                <h2 class="para_heading"> FAQs </h2>
              </div>
              <div className="col-lg-12">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        What is the AMEXA Affiliate Program?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        The AMEXA Affiliate Program is looking for partners
                        who share the same mission and values. Affiliates are
                        granted the right to create a special referral link
                        which will bind any user who registers through it to
                        their accounts. Earn trading fee commissions from all
                        users you invite, so long as they use your referral
                        link.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        Who can join the AMEXA Affiliate Program?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            1. YouTube vloggers, crypto community leaders,
                            writers, and other content creators who are willing
                            to promote AMEXA on their respective platforms
                            (social media accounts with over 5,000 followers or
                            communities with over 500 members).
                          </li>
                          <li>
                            2. Crypto market websites, crypto tool websites,
                            industry media websites, and other crypto websites.
                          </li>
                          <li>
                            3. Marketers, institutions, and organizations.{" "}
                            <a
                              href="https://www.AMEXA.com/news/AMEXA-affiliate-program-guide-test-run"
                              target="_blank"
                              rel="noopener noreferrer"
                              class="lrtcss-p9h5ah"
                            >
                              Learn more&gt;&gt;
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        How do I earn commissions through the AMEXA Affiliate
                        Program?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        Once you become a AMEXA Affiliate, you can use your
                        own referral links to invite friends to sign up. When
                        invitees conduct spot trading or futures trading, you
                        will receive up to 60% commission from their trading
                        fees. You also have the ability to create special
                        referral links in which you can decide how you want to
                        incentivize the users you invite to AMEXA, thereby
                        increasing your referral success rate.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
