import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AuthKyc from "./AuthKyc";
import CurrencyPreference from "./currencyPreference";
import Forget from "./Forget";
import Index from "./Index";
import Login from "./Login";
import OTP from "./OTP";
import UpdatePassword from "./UpdatePassword";
import FOTP from "./FOTP";
import Register from "./Register";
import Security from "./Security";
import UserKYC from "./UserKyc";
import UserPayment from "./UserPayment";
import UserReferral from "./UserReferral";
import Welcome from "./Welcome";
import Exchange from "./Exchange";
import UserProfile from "./UserProfile";
import Wallet from "./Wallet";
import GetNotifi from "./Notification";
import Fees from "./Fees";
import TwoFA from "./TwoFA";
import ActivityLog from "./ActivityLog";
import Reports from "./Reports";
import PrivacyControl from "./PrivacyControl";
import Cupon from "./Cupon";
import UpcomingProgram from "./UpcomingProgram";
import { useDispatch, useSelector } from "react-redux";



import {
  getUserBalance,
  getUserOrder,
  viewFav,
} from "./redux/actions/coinDBAction";
import { getWebsite } from "./redux/actions/websiteDBAction";
import InrDeposite from "./InrDeposite";
import MobileVerification from "./MobileVerification";
import ConfimationPopup from "./components/ConfimationPopup";
// import AffiliateScreen from "./AffiliateScreen";

import Authenticator from "./Authenticator";
import P2P from "./P2P";
import P2PNew from "./P2PNew";
import p2ppage from "./components/p2ppage";
import About from "./HomeComp/About";
import Career from "./HomeComp/Career";
import BusinessContact from "./HomeComp/BusinessContact";
import Community from "./HomeComp/Community";
import Notice from "./Notice";
import Success from "./Success";
import createSocketClient from "./redux/helpers/socket";
import AffiliateHome from "./HomeComp/AffiliateHome";
import FastTrade from "./HomeComp/FastTrade";
import Markets from "./HomeComp/markets";
import RewardHub from "./HomeComp/RewardHub";
import AccountSecurity from "./AccountSecurity";
import {
  GET_COIN_DATA,
  GET_CURRENCY_DATA,
  SET_BUY_ORDER_BOOK,
  SET_PAIRED_CURRENCY,
  SET_PAIRED_CURRENCY_PRICE,
  SET_SELL_ORDER_BOOK,
  SET_TRADE_HISTORY,
} from "./redux/constant";
import Account_Authenticator from "./Account_Authenticator";
import KYCVerification from "./KYCVerification";
import EditProfile from "./EditProfile";

//privacy
import Privacy from "./HomeComp/Privacy";
import TermsConditions from "./HomeComp/Terms&Conditions";
import Downloads from "./HomeComp/Downloads";
import Wrapper from "./HomeComp/Wrapper";
import Overview from "./overview";
import FundingMain from "./fundingMain";
import AssetsWithdraw from "./AssetsWithdraw";
import AssetsDeposit from "./AssetsDeposit";
import AssetsFiat from "./AssetsFiat";
import AssetsTradingAccount from "./AssetsTradingAccount";
import AssetsMargin from "./AssetsMargin";
import AssetsFuture from "./AssetsFuture";
import TradingBot from "./TradingBot";
import AssetsFinance from "./AssetsFinance";
import AssetsHistory from "./AssetsHistory";
import MyBonus from "./MyBounus";
import subAccount from "./subAccount";
import ApiManagement from "./ApiManagement";
import DownloadCsv from "./DownloadCsv";
import AccountVerification from "./AccountVerification";
import { N_getProfile, N_getSupportedCurrency } from "./redux/helpers/api_functions_new";
import SpotOrders from "./SpotOrders";
import OrderSidebar from "./components/OrderSidebar";
import Isolated from "./Isolated";
import MarginOrder from "./MarginOrder";
import Earn from "./Earn";
import MarginLending from "./MarginLending";
import FastTradeOrder from "./FastTradeOrder";
import p2pOrder from "./p2pOrder";
import CryptoOrders from "./CryptoOrders";
import PageNotFound from "./PageNotFound";
import { getRound } from "./redux/helpers/helper_functions";

export default function Router(props) {
  const dispatch = useDispatch();
  const { isLoggedIn, switch_theme, user } = useSelector((state) => state.AuthReducer);

  useEffect(() => {
    const socket = new createSocketClient("kujgwvfq-a-ghosttown-z-1fhhup0p6");
    socket.on("cmc_updated", (res) => {
      if (res.length>0) {
        dispatch({ type: GET_COIN_DATA, data: res, coin_loading: false });
        N_getSupportedCurrency()
        .then((data) => {
          if (!data.error && data) {
            let ddt = {};
            res?.map((d, i) => {
              for (let i = 0; i < data.length; i++) {
                if (d.symbol === data[i].currency_coin) {
                  ddt[d.symbol] = getRound(d.current_price);
                }
              }
            });
            dispatch({ type: SET_PAIRED_CURRENCY_PRICE, data: ddt });
            dispatch({ type: SET_PAIRED_CURRENCY, data: data });
          } else {
            console.log(data);
          }
        })
        
      }
      dispatch({
        type: GET_CURRENCY_DATA,
        data: { currency_coin: Date.now(), currency_price: 2 },
      });
    });
    socket.on("buy_order_updated", (res) => {
      // console.log("buy_order_updated: ", res);
      dispatch({
        type: SET_BUY_ORDER_BOOK,
        data: res,
        order_book_loading: false,
      });
    });

    socket.on("sell_order_updated", (res) => {
      // console.log("sell_order_updated: ", res);
      dispatch({
        type: SET_SELL_ORDER_BOOK,
        data: res,
        order_book_loading: false,
      });
    });
    socket.on("order_history_updated", (res) => {
      // console.log("order_history_updated", res);
      dispatch({ type: SET_TRADE_HISTORY, data: res, trade_loading: false });
    });

    dispatch(getWebsite());
    // if (isLoggedIn && user?.params ? user.params.user_id : user.user_id) getProfile();
  }, []);

  function getProfile(){
    dispatch(getUserBalance(user?.params ? user.params.user_id : user.user_id));
    N_getProfile(user?.params ? user.params.user_id : user.user_id)
        .then((d) => {
          if (d.status === 200) {
            dispatch({type:'PROFILE', data:d?.params.profile_info})
          }
        })
        .catch((e) => {
          console.log(e);
        });
  }

  useEffect(()=>{
    if (isLoggedIn && user?.params ? user.params.user_id : user.user_id) getProfile();
  },[isLoggedIn && user?.params ? user.params.user_id : user.user_id])
  
  useEffect(()=>{
    if (switch_theme == "dark") {
      localStorage.removeItem("tradingview.current_theme.name");
      const body = document.getElementsByTagName("body")[0];
      body?.classList.add("is_dark");
      body?.classList.remove("is_light");

      var icon = document.querySelector("#theme_icon");
      icon?.classList.add("fa-moon");
      icon?.classList.remove("fa-sun");
    } else {
      localStorage.removeItem("tradingview.current_theme.name");
      const body = document.getElementsByTagName("body")[0];
      body?.classList.add("is_light");
      body?.classList.remove("is_dark");

      var icon = document.querySelector("#theme_icon");
      icon?.classList.add("fa-sun");
      icon?.classList.remove("fa-moon");
    }
  }, [])

  return (
    <Switch>
      <Route exact path="/" component={Index} />
      <Route exact path="/ref/:id?" component={Index} />
      <Route exact path="/login" component={Login} />
      <Route path="/create/:id?" component={Register} />
      <Route path="/otp" component={OTP} />
      <Route path="/security" component={Security} />
      <Route path="/welcome" component={Welcome} />
      <Route path="/edit_profile" component={EditProfile} />
      <Route path="/kyc" component={AuthKyc} />
      <Route path="/update-password" component={UpdatePassword} />
      <Route
        exact
        path="/forget"
        render={(props) =>
          !isLoggedIn ? <Forget {...props} /> : <Redirect to="/" />
        }
      />
      <Route
        exact
        path="/forget-password"
        render={(props) =>
          !isLoggedIn ? <FOTP {...props} /> : <Redirect to="/" />
        }
      />
      <Route path="/exchange/:id?" component={Exchange} />
      <Route path="/p2p/:id?" component={P2P} />
      <Route path="/p2pnew/:id?" component={P2PNew} />
      <Route path="/p2ppage" component={p2ppage} />
      <Route path="/authenticator" component={Authenticator} />
      <Route
        path="/account_authenticator"
        render={(props) =>
          isLoggedIn ? (
            <Account_Authenticator {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      <Route
        path="/profile"
        render={(props) =>
          isLoggedIn ? <UserProfile {...props} /> : <Redirect to="/login" />
        }
      />
      <Route
        path="/currency_preference"
        render={(props) =>
          isLoggedIn ? (
            <CurrencyPreference {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      <Route
        path="/activity_log"
        render={(props) =>
          isLoggedIn ? <ActivityLog {...props} /> : <Redirect to="/login" />
        }
      />
      <Route path="/referral" component={UserReferral} />
      <Route path="/notice/:id?" component={Notice} />
      <Route path="/success" component={Success} />
      <Route path="/payment_option" component={UserPayment} />
      <Route path="/user_kyc" component={UserKYC} />
      <Route path="/notification" component={GetNotifi} />
      <Route path="/fees" component={Fees} />
      <Route
        path="/2fa"
        render={(props) =>
          isLoggedIn ? <TwoFA {...props} /> : <Redirect to="/login" />
        }
      />
      <Route
        path="/download_report"
        render={(props) =>
          isLoggedIn ? <Reports {...props} /> : <Redirect to="/login" />
        }
      />
      <Route path="/privacy_control" component={PrivacyControl} />
      <Route path="/coupan" component={Cupon} />
      <Route path="/upcoming_program" component={UpcomingProgram} />
      <Route path="/mobile-verify/:action?" component={MobileVerification} />
      <Route path="/kyc-verify" component={KYCVerification} />
      <Route
        path="/wallet"
        render={(props) =>
          isLoggedIn ? <Wallet {...props} /> : <Redirect to="/login" />
        }
      />
      <Route
        path="/inr-deposit"
        render={(props) =>
          isLoggedIn ? <InrDeposite {...props} /> : <Redirect to="/login" />
        }
      />
      <Route path="/transaction/:status/:msg" component={ConfimationPopup} />
      <Route path="/about" component={About} />
      <Route path="/career" component={Career} />
      <Route path="/businesscontact" component={BusinessContact} />
      <Route path="/community" component={Community} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/terms_&_conditions" component={TermsConditions} />
      <Route path="/downloads" component={Downloads} />
      <Route path="/api-docs" component={Wrapper} />
      <Route path="/AffiliateHome" component={AffiliateHome} />
      <Route path="/FastTrade" component={FastTrade} />
      <Route path="/markets" component={Markets} />
      <Route path={"/RewardHub"} component={RewardHub}/>
      <Route
        path="/Overview"
        render={(props) =>
          isLoggedIn ? <Overview {...props} /> : <Redirect to="/login" />
        }
      />
      <Route
        path="/FundingMain"
        render={(props) =>
          isLoggedIn ? <FundingMain {...props} /> : <Redirect to="/login" />
        }
      />
      <Route
        path="/AssetsWithdraw"
        render={(props) =>
          isLoggedIn ? <AssetsWithdraw {...props} /> : <Redirect to="/login" />
        }
      />
      <Route
        path="/AssetsDeposit"
        render={(props) =>
          isLoggedIn ? <AssetsDeposit {...props} /> : <Redirect to="/login" />
        }
      />
      <Route
        path="/AssetsFiat"
        render={(props) =>
          isLoggedIn ? <AssetsFiat {...props} /> : <Redirect to="/login" />
        }
      />
      <Route
        path="/AssetsTradingAccount"
        render={(props) =>
          isLoggedIn ? <AssetsTradingAccount {...props} /> : <Redirect to="/login" />
        }
      />
      <Route
      path="/AssetsMargin"
      render={(props) =>
        isLoggedIn ? <AssetsMargin {...props} /> : <Redirect to="/login" />
      }
      />
       <Route
      path="/AssetsFuture"
      render={(props) =>
        isLoggedIn ? <AssetsFuture {...props} /> : <Redirect to="/login" />
      }
      />
       <Route
      path="/TradingBot"
      render={(props) =>
        isLoggedIn ? <TradingBot {...props} /> : <Redirect to="/login" />
      }
      />
       <Route
      path="/AssetsFinance"
      render={(props) =>
        isLoggedIn ? <AssetsFinance {...props} /> : <Redirect to="/login" />
      }
      />
       <Route
      path="/AssetsHistory"
      render={(props) =>
        isLoggedIn ? <AssetsHistory {...props} /> : <Redirect to="/login" />
      }
      />
      <Route path={"/MyBonus"} component={MyBonus}/>
      <Route
      path="/AccountSecurity"
      render={(props) =>
        isLoggedIn ? <AccountSecurity {...props} /> : <Redirect to="/login" />
      }
      />
      <Route
      path="/subAccount"
      render={(props) =>
        isLoggedIn ? <subAccount {...props} /> : <Redirect to="/login" />
      }
      />
      <Route
      path="/ApiManagement"
      render={(props) =>
        isLoggedIn ? <ApiManagement {...props} /> : <Redirect to="/login" />
      }
      />
      <Route
      path="/DownloadCsv"
      render={(props) =>
        isLoggedIn ? <DownloadCsv {...props} /> : <Redirect to="/login" />
      }
      />
      <Route
      path="/AccountVerification"
      render={(props) =>
        isLoggedIn ? <AccountVerification {...props} /> : <Redirect to="/login" />
      }
      />
      <Route
      path="/SpotOrders"
      render={(props) =>
        isLoggedIn ? <SpotOrders {...props} /> : <Redirect to="/login" />
      }
      />
       <Route
      path="/OrderSidebar"
      render={(props) =>
        isLoggedIn ? <OrderSidebar {...props} /> : <Redirect to="/login" />
      }
      />
       <Route
      path="/MarginOrder"
      render={(props) =>
        isLoggedIn ? <MarginOrder {...props} /> : <Redirect to="/login" />
      }
      />
      <Route path={"/Isolated"} component={Isolated}/>
      <Route path={"/Earn"} component={Earn}/>
      <Route path={"/MarginLending"} component={MarginLending}/>
      <Route path={"/FastTradeOrder"} component={FastTradeOrder}/>
      <Route path={"/p2pOrder"} component={p2pOrder}/>
      <Route path={"/CryptoOrders"} component={CryptoOrders}/>
      <Route path={"/PageNotFound"} component={PageNotFound}/>
      
    </Switch>
  );
}
