import React from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";

export default function StartTrade() {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  AOS.init({ duration: 1000 });
  return (
    <>
      <div className="get_started_section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center text-md-start">
                <div className="para_heading">
                Get Started in<br/>
                <span className="text-gradient">3 Minutes</span>
                </div>
                <div className="sub_heading my-4">
                Discover the most intuitive and unparalleled user<br/> experience with Amexa
                </div>
                <div className="mt-4 mb-3">
                  <button className="login_btn">Learn More</button>

                </div>

              </div>
              <div className="col-md-6 ps-5 mt-2">
                <div className="link_card">
                  <div>
                  How to Buy Crypto With AMEXA
                  </div>
                <div>
                  <img src="/img/arrow-up.png" />
                </div>
                </div>
                <div className="link_card">
                  <div>
                  How to Withdraw and Deposit on AMEXA
                  </div>
                <div>
                  <img src="/img/arrow-up.png" />
                </div>
                </div>
                <div className="link_card">
                  <div>
                  How to Start Wallet on AMEXA
                  </div>
                <div>
                  <img src="/img/arrow-up.png" />
                </div>
                </div>


              </div>
              

            </div>
          
           
        </div>
      </div>

      <div className="crypto_trading_info_section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="para_heading text-center">
              Your one-stop platform for <br/><span className="text-gradient">crypto trading</span>
              </div>
              <div className="subheading mt-4 text-center">
              Experience effortless crypto trading with a platform built for all levels. Access real-time insights,<br/> robust security, and intuitive tools to manage and grow your portfolio with confidence.
              </div>
              <div className="crypto_trading_info_div">
                <div className="para_heading text-center">
                Start your Cryto Trading  in a<br/> minutes with AMEXA
                </div>
                <div>
                  <div className="d-flex justify-content-center">
                    <button className="crypto_trading_info_btn1">Login</button>
                    <button className="crypto_trading_info_btn2">Get Started</button>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>
    </>
  );
}
