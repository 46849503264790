import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdOutlineInfo } from "react-icons/md";

export default function TradingBot() {
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="marginTop ">
            <div className="account_page">
              <div className="container">
                {" "}
                <div className="page-content-title">
                 

                  <h3 className="">Trading Bot Account</h3>
                </div>
                <div className="row my-4">
                  <div className="col-lg-2 mb-3">
                    <h6 className="mb-0 text-muted">
                      {" "}
                      <MdOutlineInfo className="fs-5" /> Total Operating Assets
                    </h6>
                    <div className="mt-sm">
                      <span class="desc_card">0.00</span>
                      <span class="fs-16 text-muted ms-1">BTC</span>
                      <span class="fs-16 text-lgray">
                        <span>≈ 0.00</span>
                        <span>USD</span>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-2 mb-3">
                    <h6 className="mb-0 text-muted"> Total Profit</h6>
                    <div className="mt-sm">
                      {" "}
                      <span class="desc_card">0.00</span>
                      <span class="fs-16 text-muted ms-1">BTC</span>
                      <span class="fs-16 text-lgray">
                        <span>≈ 0.00</span>
                        <span>USD</span>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-2 mb-3">
                    <h6 className="mb-0  text-muted"> Today's Profit</h6>
                    <div className="mt-sm">
                      {" "}
                      <span class="desc_card">0.00</span>
                      <span class="fs-16 text-muted ms-1">BTC</span>
                      <span class="fs-16 text-lgray">
                        <span>≈ 0.00</span>
                        <span>USD</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="overflow-auto mt-3">
                  <div className="table_scroll_div">
                    <table className="table global_table">
                      <thead>
                        <tr>
                          <th>Strategy</th>
                          <th>Pair</th>
                          <th>Operating Assets</th>
                          <th>Profit</th>
                          <th>Today's Profit</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="d-flex align-items-center">
                              <div>
                                <img
                                  src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/classicgrid.97861746.svg"
                                  alt="coin symbole"
                                  class="thumb_img"
                                />
                              </div>
                              <div class="product_name">
                                <div class="text-muted "> Spot Grid</div>
                              </div>{" "}
                            </div>
                          </td>
                          <td>
                            <div class="mb-0">--</div>
                          </td>
                          <td>
                            <div class="mb-0">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>
                          <td>
                            <div class="mb-0">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>
                          <td>
                            <div class="mb-0">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>

                          <td>
                            <a href="#" class="anchor_link">
                              <i className="fa fa-plus me-1"></i> Create
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex align-items-center">
                              <div>
                                <img
                                  src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/futuregrid.abf5f532.svg"
                                  alt="coin symbole"
                                  class="thumb_img"
                                />
                              </div>
                              <div class="product_name">
                                <div class="text-muted"> Future Grid</div>
                              </div>{" "}
                            </div>
                          </td>
                          <td>
                            <div class="mb-0">--</div>
                          </td>
                          <td>
                            <div class="mb-0">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>
                          <td>
                            <div class="mb-0">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>
                          <td>
                            <div class="mb-0">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>

                          <td>
                            <a href="#" class="anchor_link">
                              <i className="fa fa-plus me-1"></i> Create
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex align-items-center">
                              <div>
                                <img
                                  src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/martingale.d7b66cbd.svg"
                                  alt="coin symbole"
                                  class="thumb_img"
                                />
                              </div>
                              <div class="product_name">
                                <div class="text-muted"> Martingale</div>
                              </div>{" "}
                            </div>
                          </td>
                          <td>
                            <div class="mb-0">--</div>
                          </td>
                          <td>
                            <div class="mb-0">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>
                          <td>
                            <div class="mb-0">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>
                          <td>
                            <div class="mb-0">0.00</div>
                            <div class="text-muted fs-12"> ≈ 0.00 USD</div>
                          </td>

                          <td>
                            <a href="#" class="anchor_link">
                              <i className="fa fa-plus me-1"></i> Create
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
