import React from "react";
import { FaPercent, FaCoins } from "react-icons/fa";
import { SlBookOpen } from "react-icons/sl";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { BsFillBarChartFill } from "react-icons/bs";
import { GiPayMoney, GiCoins } from "react-icons/gi";
import { useSelector } from "react-redux";

export default function P2pContent() {
  const { webData } = useSelector((state) => state.websiteDBReducer);
  return (
    <>
      <div className="container p2p-works-container mt-5">
        <div className="">
          {" "}
          <div className="d-flex justify-content-between pb-3"></div>
          <div class="section-padding">
            <div class="container">
              <div class="col-md-8 col-lg-6 m-auto text-center mb-5">
                <h2 class="title">How P2P Works</h2>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error voluptatem
                  accusantium doloremque rem aperiam.
                </p>
              </div>

              <div class="row">
                <div class="col-lg-4">
                  <div class="work-box px-lg-5 text-center mb-5 mb-lg-0">
                    <div class="work-icon bg-soft-primary mb-4">
                      <SlBookOpen className="p2p-main-header"/>
                    </div>
                    <h5 class="fw-semibold">1. Place an Order</h5>
                    <p class="text-muted">
                      {" "}
                      Once you place a P2P order, the crypto asset will be
                      escrowed by AMEXA P2P.
                    </p>
                    <img src="/img/arrow-top.png" alt="" class="work-arrow" />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="work-box px-lg-5 text-center mb-5 mb-lg-0">
                    <div class="work-icon bg-soft-success mb-4">
                      <GiPayMoney className="p2p-main-header"/>
                    </div>
                    <h5 class="fw-semibold">2. Pay the Seller</h5>
                    <p class="text-muted">
                      {" "}
                      Send money to the seller via the suggested payment
                      methods.
                    </p>
                    <img
                      src="/img/arrow-bottom.png"
                      alt=""
                      class="work-arrow"
                    />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="work-box px-lg-5 text-center mb-5 mb-lg-0">
                    <div class="work-icon bg-soft-primary mb-4">
                      <GiCoins className="p2p-main-header"/>
                    </div>
                    <h5 class="fw-semibold">3.Get your Crypto</h5>
                    <p class="text-muted">
                      Once the seller confirms receipt of money, the escrowed
                      crypto will be released to you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row pb-4">
              <div className="col-md-4">
                <div className="text-center card-main-container">
                  <div className="p2-card-icons">
                    {" "}
                    <span className="p2p-main-header">
                      <SlBookOpen style={{ fontSize: "35px" }} />
                    </span>
                  </div>

                  <div className="p2-card-heading pb-1 pt-4 mdfthemetxt">
                    <h4> Place an Order</h4>
                  </div>
                  <div className="p2-card-para pt-1 pb-4 text-secondary">
                    Once you place a P2P order, the crypto asset will be escrowed
                    by AMEXA P2P.
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="text-center card-main-container">
                  <div className="p2-card-icons">
                    {" "}
                    <span className="p2p-main-header">
                      {" "}
                      <GiPayMoney style={{ fontSize: "35px" }} />
                    </span>
                  </div>

                  <div className="p2-card-heading pb-1 pt-4 mdfthemetxt">
                    <h4> Pay the Seller</h4>
                  </div>
                  <div className="p2-card-para pt-1 pb-4 text-secondary">
                    Send money to the seller via the suggested payment methods.
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="text-center card-main-container">
                  <div className="p2-card-icons">
                    {" "}
                    <span className="p2p-main-header">
                      <GiCoins style={{ fontSize: "35px" }} />
                    </span>
                  </div>

                  <div className="p2-card-heading pb-1 pt-4 mdfthemetxt">
                    <h4>Get your Crypto</h4>
                  </div>
                  <div className="p2-card-para pt-1 pb-4 text-secondary">
                    Once the seller confirms receipt of money, the escrowed crypto
                    will be released to you.
                  </div>
                </div>
              </div>
            </div> */}
        </div>
        </div>
        <div className="container spacer"
       
        >
          <div className="row p2p-works-container-- mt-4 mb-4">
            <div className="container">
            <div class="col-md-8 col-lg-6 m-auto text-center mb-5">
              <h2 class="title mt-3">Advantages of P2P Exchange</h2>
              <p>
                Sed ut perspiciatis unde omnis iste natus error voluptatem
                accusantium doloremque rem aperiam.
              </p>
            </div>

            <div className="row align-items-center">
            <div className="col-md-7">
              <div>
                <div className="d-flex align-items-start gap-4 mb-4">
                  <div className="">
                  <span className="text-warning">
                      <FaPercent className="fs-2"/>
                    </span>
                  </div>
                  <div>
                    <h5 className="mdfthemetxt"> Low Transaction Fees</h5>
                    <p className="my-0 text-muted fs-14">
                      On AMEXA P2P, takers are charged zero trading fees. We
                      pledge to apply the lowest P2P transaction fees for all
                      markets.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start gap-4 mb-4">
                  <div className="">
                    <span className="text-warning">
                      <FaCoins className="fs-2"/>
                    </span>
                  </div>
                  <div>
                    <h5 className="mdfthemetxt"> Flexible Payment Methods</h5>
                    <p className="my-0 text-muted fs-14">
                      Peer-to-peer (P2P) exchanges allow sellers the freedom to
                      define how they want to be paid, including bank transfer.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start gap-4 mb-4">
                  <div className="">
                  <span className="text-warning">
                      <BsFillBarChartFill className="fs-2"/>
                    </span>
                  </div>
                  <div className="">
                    <h5 className="mdfthemetxt">
                      Trade at Your Preferred Prices
                    </h5>
                    <p className="my-0 text-muted fs-14">
                      Trade crypto with the freedom to buy and sell at your
                      preferred prices. Buy or sell from the existing offers, or
                      create trade advertisements to set your own prices.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start gap-4 mb-4">
                  <div className="">
                  <span className="text-warning">
                      <MdOutlinePrivacyTip className="fs-2" />
                    </span>
                  </div>
                  <div>
                    <h5 className="mdfthemetxt">Protecting Your Privacy</h5>
                    <p className="my-0 text-muted fs-14">
                      Unlike credit card or bank transfers, peer-to-peer
                      exchanges do not collect information about buyers and
                      sellers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <img src="/img/phone-1.png" alt="" />
            </div>
            </div>
            </div>
          </div>
        </div>
      
    </>
  );
}
