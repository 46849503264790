import React from "react";
import { useSelector } from "react-redux";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FaCoins } from "react-icons/fa";
import { BiGitCompare } from "react-icons/bi";
import { HiCubeTransparent } from "react-icons/hi";
import Card from "./Card";
import AOS from "aos";
import "aos/dist/aos.css";
import { getRound } from "../redux/helpers/helper_functions";

export default function MarketTrend() {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  AOS.init();
  const { coins, campare_currency } = useSelector(
    (state) => state.coinDBReducer
  );
  let coins1 = Object.values(coins);
  // console.log(coins1)
  const market_child =
    coins1 &&
    coins1.map((item, index) => {
      let usdtprize = coins1 && coins1.find((item) => item.symbol == "USDT");
      return (
        <>
          {item.symbol == "BTC" ||
          item.symbol == "BNB" ||
          item.symbol == "ETH" ||
          item.symbol == "TRX" ||
          item.symbol == "AMEXA" ? (
            <tr>
              <td>
                <img
                  src={item.icon}
                  alt="coin symbole"
                  className="market_coin_img"
                  //style={{ width: "50px" }}
                />
                {item.symbol}
                <span className="name_coin ms-2">{item.name}</span>
              </td>
              <td>${getRound(item.current_price / usdtprize.current_price)}</td>
              <td>
                <span
                  className={
                    item.direction_inr === "up" ? "text-green " : "text-red"
                  }
                >
                  {getRound(item.price_change_percentage_1h)}%{" "}
                  {item.direction === "up" ? (
                    <BsArrowUpShort />
                  ) : (
                    <BsArrowDownShort />
                  )}
                </span>
              </td>
              <td>{getRound(item.market_cap)}</td>
              <td>
                <a
                  href={`/exchange/${item.symbol.toLowerCase()}-${campare_currency}`}
                >
                  Trade
                </a>
              </td>
            </tr>
          ) : (
            ""
          )}
        </>
      );
    });
  return (
    <>
      <div className="spacer">
        <div className="container">
          <div className="para_heading">
            Capture Every <span className="text-gradient">Trading</span>
            <br /> Opportunity.
          </div>
          <div className="market_trend_section">
            <div className="overflow-auto">
              <div className="table_scroll_div">
                <table className="table home_table">
                  {/* <thead>
                    <tr>
                      <th>Name</th>
                      <th>Last Price</th>
                      <th>24h Change</th>
                      <th>Market Cap</th>
                      <th>Action</th>
                    </tr>
                  </thead> */}
                  <tbody>{market_child}
                    <img src="/img/dummy_trd.png" />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    

      <div className="secure_assets_section">
        <div className="container">
          <div className="para_heading text-center">
            Secure Your <span className="text-gradient">Assets</span>.
          </div>
          <div className="about_card_div">
            <div className="row">
              <div className="col-md-4">
                <div className="about_card about_card_border_right">
                  <div className="my-2">
                    <img style={{ width: "60px" }} src="/img/secure1.png" />
                  </div>
                  <div className="about_card_heading mt-4 mb-2">Compliance Matrix</div>
                  <div className="about_card_content">
                    A global digital services financial institution with branch
                    offices in Canada, the EURegulated business and services in
                    countries where it operates{" "}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="about_card about_card_border_right">
                  <div className="my-2">
                    <img style={{ width: "60px" }} src="/img/secure2.png" />
                  </div>
                  <div className="about_card_heading mt-4 mb-2">2FA Authentication</div>
                  <div className="about_card_content">
                    Robust identity verification, compliance and Know Your
                    Customer (KYC) with Sumsub as a partnerAuto-detection of
                    cybercrime-related risks with advanced AI technology{" "}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="about_card about_card_border_right">
                  <div className="my-2">
                    <img style={{ width: "60px" }} src="/img/secure3.png" />
                  </div>
                  <div className="about_card_heading mt-4 mb-2">Transparency </div>
                  <div className="about_card_content">
                    100% Proof-of-Reserves with top cybersecurity organizations
                    as partners Security audit approved by the leading
                    security-focused ranking platform CertiK{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" mt-4 app_section">
        <div className="container">
          <div className="row">
            <div className="para_heading text-center ">
              Accelerate your path to market & unlock{" "}<br className="d-none d-md-block" />
              <span className="text-gradient">your full revenue potential</span>
            </div>
            <div className="col-12 my-5">
              <div className="d-flex justify-content-center flex-column gap-5">
                <img className="px-5 move_img1" src="/img/dummy_slide1.png" />
                <img className="move_img2" src="/img/dummy_slide2.png" />
                <img className="px-5 move_img3" src="/img/dummy_slide3.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" key_features">
        <div className="container">
          <div className="para_heading text-center">
            Why <span className="text-gradient">Amexa ?</span>
          </div>
          <div className="row  mt-md-5 mt-2">
            <div className="col-md-4 mt-5">
              <div className="why_amexa_card">
                <img
                  style={{ width: "60px" }}
                  className="mb-4"
                  src="/img/why_amexa1.png"
                />
                <div className="why_amexa_card_heading">
                  Build beyond your imagination
                </div>
                <div className="why_amexa_card_content">
                  Quickly set up coins and markets on your exchange with Amexa
                  white label handling blockchain complexities. Focus on your
                  business.
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-1">
              <div className="why_amexa_card">
                <img
                  style={{ width: "60px" }}
                  className="mb-4"
                  src="/img/why_amexa2.png"
                />
                <div className="why_amexa_card_heading">
                  Automated trade matching engine
                </div>
                <div className="why_amexa_card_content">
                  Ideal for those seeking an accessible, autonomous exchange
                  solution without sacrificing depth.{" "}
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-5">
              <div className="why_amexa_card">
                <img
                  style={{ width: "60px" }}
                  className="mb-4"
                  src="/img/why_amexa3.png"
                />
                <div className="why_amexa_card_heading">
                  Backend exchange operator control panel
                </div>
                <div className="why_amexa_card_content">
                  Control everything from your operator panel: deploy crypto
                  capital, create tiered user accounts, and more.{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="spacer key_features">
        <div className="container">
          <div className="para_heading text-center">
            Ways to use <span className="text-gradient">AMEXA</span>
          </div>
          <div className="row  mt-5">
            <div className="col-md-6">
              <div className="ways_card">
                <div className="ways_heading ">Effortless Crypto Trading</div>

                <div className="ways_content custom_border_gradient pt-3 pb-2 pb-md-5">
                  <br />
                  <br />
                  Buy, sell, and exchange a wide variety of digital assets on a
                  secure, high-liquidity platform. Amexa’s intuitive interface
                  and powerful tools make trading accessible for everyone, from
                  beginners to advanced users.
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ways_card">
                <div className="ways_heading ">Portfolio Management</div>

                <div className="ways_content custom_border_gradient pt-3 pb-2 pb-md-5">
                  <br />
                  <br />
                  Easily track and manage your cryptocurrency investments with
                  real-time analytics and customizable dashboards. Get insights
                  into your asset performance and optimize your portfolio with
                  data-driven tools.{" "}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ways_card">
                <div className="ways_heading ">Advanced Trading Options</div>

                <div className="ways_content custom_border_gradient pt-3 pb-2 pb-md-5">
                  <br />
                  <br />
                  Access features like margin trading, limit orders, and
                  stop-loss functionality to enhance your trading strategies.
                  Amexa empowers you with professional tools to maximize your
                  trading potential.{" "}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ways_card">
                <div className="ways_heading ">
                  Secure Wallet and Asset Storage
                </div>

                <div className="ways_content custom_border_gradient pt-3 pb-2 pb-md-5">
                  <br />
                  <br />
                  Keep your assets safe with Amexa’s multi-layered security
                  protocols, cold storage options, and insurance-backed
                  protections, ensuring peace of mind as you trade and invest.{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
