import io from "socket.io-client";
import { SOCKET_URL } from "../../../constants";

function createSocketClient(access_token) {
  const socket = io(`${SOCKET_URL}`, {
    auth: {
      token: access_token, //kujgwvfq-a-ghosttown-z-1fhhup0p6
    },
  });
  return socket;
}
export default createSocketClient;
