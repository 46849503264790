import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import OrderSidebar from "./components/OrderSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdOutlineInfo } from "react-icons/md";

export default function Earn() {
  return (
    <>
      <Header />
      <div className="page-content">
        <main className="container">
          <div className="account_page">
            <div class="page-content-title">
              <h3 class="fw-normal">Earn Orders</h3>
            </div>

            <div className="row cstm_form mb-2">
              <div className="col-lg-3 mb-2">
                <select class="form-select" aria-label="Default select example">
                  <option selected>Coin</option>
                  <option value="1">All</option>
                  <option value="2">OXEN/BTC</option>
                  <option value="3">NRG/BTC</option>
                </select>
              </div>
              <div className="col-lg-3 mb-2">
                {" "}
                <select class="form-select" aria-label="Default select example">
                  <option selected>1 Month</option>
                  <option value="1">All</option>
                  <option value="2">Sell</option>
                  <option value="3">Buy</option>
                </select>
              </div>
              <div className="col-lg-3 mb-2">
                {" "}
                <select class="form-select" aria-label="Default select example">
                  <option selected>All Products</option>
                  <option value="1">All </option>
                  <option value="2">Limit Order</option>
                  <option value="3">Market Order</option>
                </select>
              </div>

              <div className="col-lg-3 mb-2">
                {" "}
                <select class="form-select" aria-label="Default select example">
                  <option selected>All</option>
                  <option value="1">All </option>
                  <option value="2">Limit Order</option>
                  <option value="3">Market Order</option>
                </select>
              </div>
            </div>

            <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
              <table className="table global_table">
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>Name</th>
                    <th>Product</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {/*============= Use this div when no data is found =============== */}

                  <tr>
                    <td
                      colSpan={5}
                      className="text-center border-bottom-0 text-muted"
                    >
                      <img src="/img/no-data.png" className="no-data-found" />
                      <p>No records</p>
                    </td>
                  </tr>

                  {/*============= Use this div when no data is found =============== */}
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
