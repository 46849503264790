import React, { useEffect, useState } from "react";
import Header from "./HomeComp/Header";
import ProfileSidebar from "./components/ProfileSidebar";
import { BiError, BiMobileAlt, BiSolidCheckShield } from "react-icons/bi";
import { AiOutlineCamera, AiOutlineCloudUpload } from "react-icons/ai";
import { N_checkKYCStatus, N_getCountry, N_profileData, N_uploadID } from "./redux/helpers/api_functions_new";
import { useSelector } from "react-redux";
import $ from "jquery"
// import Footer from "./HomeComp/Footer";
export default function AccountVerification(props) {
  const { user } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = React.useState(true);
  const [countryData, setCountry] = React.useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isverified, setIsverified] = useState(0)
  const [usercountry, setUserCountry] = useState('india');
  const [id_no, setIdNo] = useState('');
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [type, setType] = useState('');
  const [personalTab, setPersonalTab] = useState(0);
  const [isSubmitPersonalData, setSubmitPersonalData] = useState(0);
  const [id_verification, setIdVerifocation] = useState(0)
  const [filedata, setFileData] = useState();
  useEffect(() => {
    N_checkKYCStatus(user?.params ? user.params.user_id: user.user_id)
    .then((d)=>{
      if (d.status === 200) {
        if(d?.params.status == 2) {
          setPersonalTab(1);
        } else if(d?.params.status == -1) {
          setIsverified(2)
        } else if(d?.params.status == 1) {
          setIsverified(1)
        }
        if(d?.params.status == 0 || d?.params.status == 3) {
          N_getCountry(user?.params ? user.params.user_id : user.user_id)
          .then((d) => {
            if (d.status === 200) {
              setCountry(d?.params.country_data);
            }
           
          })
        }
      }
      setLoading(false);
    })
   
  }, []);


  const updatePersonal = (e) =>{
    e.preventDefault();
    N_profileData(user?.params ? user.params.user_id: user.user_id, type, id_no, first_name, last_name, usercountry)
    .then((res)=>{
      console.log(res);
      if(res.status == 200) {
        setSubmitPersonalData(2);
      }
    })
  }

  function readURL(input) {
    const file = input.target.files[0];
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      setFileData(file);
      setIdVerifocation(3)
      reader.onload = function (e) {
        $("#check_photo").attr("src", e.target.result);
      };
    }
  }


  
  return (
    <>
      <Header {...props} />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <ProfileSidebar />
        </div>

        <main className="s-layout__content">
          <div className="dashboard_header">
            <h3 className="header_title">Individual Identity Verification</h3>
          </div>
          <div className="container-fluid">
            <div className="account_page">
              <div class="row">
                <div class="col-lg-8">
                  <div className="col-lg-12 mb-5">
                    <h4 className="h5 fw-bold mb-0">
                      Verify your identity for enhanced account security and
                      expanded service access.
                    </h4>
                    <div className="text-lgray">
                      The verification only takes 1-2 minutes.
                    </div>
                    {/* <p className="">
                      <span className="fs-4"> 🎉 </span> Identity verification
                      passed. Enjoy enhanced account protection and services
                      now!
                    </p> */}

                    <div className="d-grid col-lg-2 col-12 my-4">
                      {isverified==0?
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#kyc_modal"
                        className="btn btn-pink btn-lg rounded-pill"
                      >
                        Verify
                      </button>:isverified == 2?<button disabled
                        className="btn btn-info btn-lg rounded-pill"
                      >
                        Pending
                      </button>:isverified == 1?<button disabled
                        className="btn btn-success btn-lg rounded-pill"
                      >
                        Verified
                      </button>:null}
                    </div>
                  </div>
                  <div
                    class="accordion verification_accrodion"
                    id="accordionExample"
                  >
                    <div class="timeline_container">
                      <div>
                        <div class="accor_timeline_no">
                          <i class="fa-solid fa-check"></i>
                        </div>
                        <div class="accor_timeline_line"></div>
                      </div>

                      <div class="accordion-item border-0">
                        <div class="">
                          <h2 class="accordion-header" id="headingOne">
                            <button
                              class="accordion-button p-0"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              <div> Successfully Signed Up</div>
                              <div className="small_heading">
                                {" "}
                                Daily withdrawal limit: 20,000 USDT.{" "}
                              </div>
                            </button>
                          </h2>

                          <div
                            id="collapseOne"
                            class="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body px-0">
                              <div className="jambotron mb-5">
                                <div className="big_heading">
                                  Account Features
                                </div>

                                <div className="col-md-12">
                                  <div className="row mt-4">
                                    <div className="col-lg-6 mb-3">
                                      <h5 className="mb-0">
                                        <i className="fa fa-copy me-1 fs-5 text-primary"></i>{" "}
                                        1,000,000 USDT
                                      </h5>

                                      <small className="text-lgray ms-4 ps-2">
                                        Daily Withdrawal Limit
                                      </small>
                                    </div>

                                    <div className="col-lg-6 mb-3">
                                      <h5 className="mb-0">
                                        <i className="fa fa-copy me-1 fs-5 text-primary"></i>{" "}
                                        Unavailable
                                      </h5>

                                      <small className="text-lgray ms-4 ps-2">
                                        Fiat Deposit
                                      </small>
                                    </div>

                                    <div className="col-lg-6 mb-3">
                                      <h5 className="mb-0">
                                        <i className="fa fa-copy me-1 fs-5 text-primary"></i>{" "}
                                        0 USDT
                                      </h5>

                                      <small className="text-lgray ms-4 ps-2">
                                        Daily P2P Trading
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="timeline_container">
                      <div>
                        <div class="accor_timeline_no">
                          {isverified ==1 || personalTab ==1 || isverified ==2? <i class="fa-solid fa-check"></i>:<i class="fa-solid">2</i>}
                        </div>
                        <div class="accor_timeline_line"></div>
                      </div>

                      <div class="accordion-item border-0">
                        <h2 class="accordion-header" id="headingTwo">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Provide Personal Information (Level 1)
                            <div className="small_heading">
                              Increase your daily withdrawal limit to 25,000
                              USDT.
                            </div>
                          </button>
                        </h2>

                        <div
                          id="collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body px-0">
                            <div className="jambotron mb-5">
                              <div className="big_heading">
                                Account Features
                              </div>

                              <div className="col-md-12">
                                <div className="row mt-4">
                                  <div className="col-lg-6 mb-3">
                                    <h5 className="mb-0">
                                      <i className="fa fa-copy me-1 fs-5 text-primary"></i>{" "}
                                      1,000,000 USDT
                                    </h5>

                                    <small className="text-lgray ms-4 ps-2">
                                      Daily Withdrawal Limit
                                    </small>
                                  </div>

                                  <div className="col-lg-6 mb-3">
                                    <h5 className="mb-0">
                                      <i className="fa fa-copy me-1 fs-5 text-primary"></i>{" "}
                                      Unavailable
                                    </h5>

                                    <small className="text-lgray ms-4 ps-2">
                                      Fiat Deposit
                                    </small>
                                  </div>

                                  <div className="col-lg-6 mb-3">
                                    <h5 className="mb-0">
                                      <i className="fa fa-copy me-1 fs-5 text-primary"></i>{" "}
                                      0 USDT
                                    </h5>

                                    <small className="text-lgray ms-4 ps-2">
                                      Daily P2P Trading
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="timeline_container">
                      <div>
                        <div class="accor_timeline_no">
                        {isverified ==1 || isverified ==2? <i class="fa-solid fa-check"></i>:<i class="fa-solid">3</i>}
                        </div>
                        <div class="accor_timeline_line"></div>
                      </div>

                      <div class="accordion-item border-0">
                        <h2 class="accordion-header" id="headingThree">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Upload Photo of ID (Level 2)
                            <div className="small_heading">
                              Increase your daily withdrawal limit to 1,000,000
                              USDT.
                            </div>
                          </button>
                        </h2>

                        <div
                          id="collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body px-0">
                            <div className="jambotron mb-5">
                              <div className="big_heading">
                                Account Features
                              </div>

                              <div className="col-md-12">
                                <div className="row mt-4">
                                  <div className="col-lg-6 mb-3">
                                    <h5 className="mb-0">
                                      <i className="fa fa-copy me-1 fs-5 text-primary"></i>{" "}
                                      1,000,000 USDT
                                    </h5>

                                    <small className="text-lgray ms-4 ps-2">
                                      Daily Withdrawal Limit
                                    </small>
                                  </div>

                                  <div className="col-lg-6 mb-3">
                                    <h5 className="mb-0">
                                      <i className="fa fa-copy me-1 fs-5 text-primary"></i>{" "}
                                      Unavailable
                                    </h5>

                                    <small className="text-lgray ms-4 ps-2">
                                      Fiat Deposit
                                    </small>
                                  </div>

                                  <div className="col-lg-6 mb-3">
                                    <h5 className="mb-0">
                                      <i className="fa fa-copy me-1 fs-5 text-primary"></i>{" "}
                                      0 USDT
                                    </h5>

                                    <small className="text-lgray ms-4 ps-2">
                                      Daily P2P Trading
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="timeline_container">
                      <div>
                        <div class="accor_timeline_no">
                        {isverified ==1 || isverified ==2? <i class="fa-solid fa-check"></i>:<i class="fa-solid">4</i>}
                        </div>
                        <div class="accor_timeline_line last_item"></div>
                      </div>

                      <div class="accordion-item border-0">
                        <h2 class="accordion-header">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            Complete Face Verification (Level 3)
                            <div className="small_heading">
                              Increase your daily withdrawal limit to 1,000,000
                              USDT.
                            </div>
                          </button>
                        </h2>

                        <div
                          id="collapseFour"
                          class="accordion-collapse collapse"
                          aria-labelledby="collapseFour"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body px-0">
                            <div className="jambotron mb-5">
                              <div className="big_heading">
                                Account Features
                              </div>

                              <div className="col-md-12">
                                <div className="row mt-4">
                                  <div className="col-lg-6 mb-3">
                                    <h5 className="mb-0">
                                      <i className="fa fa-copy me-1 fs-5 text-primary"></i>{" "}
                                      1,000,000 USDT
                                    </h5>

                                    <small className="text-lgray ms-4 ps-2">
                                      Daily Withdrawal Limit
                                    </small>
                                  </div>

                                  <div className="col-lg-6 mb-3">
                                    <h5 className="mb-0">
                                      <i className="fa fa-copy me-1 fs-5 text-primary"></i>{" "}
                                      Unavailable
                                    </h5>

                                    <small className="text-lgray ms-4 ps-2">
                                      Fiat Deposit
                                    </small>
                                  </div>

                                  <div className="col-lg-6 mb-3">
                                    <h5 className="mb-0">
                                      <i className="fa fa-copy me-1 fs-5 text-primary"></i>{" "}
                                      0 USDT
                                    </h5>

                                    <small className="text-lgray ms-4 ps-2">
                                      Daily P2P Trading
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 identity_accordion">
                  <div class="jambotron-outline">
                    <div class="account_page_subheading mb-2">FAQs</div>
                    <div
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                          <button
                            class="accordion-button px-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            What is identity verification?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            Identity verification is a crucial due diligence
                            process that verifies customer information and
                            background to ensure compliance with anti-money
                            laundering (AML) and combating the financing of
                            terrorism (CFT) regulations. This process enhances
                            account security and reduces the risk of fraudulent
                            and illegal activities.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingTwo">
                          <button
                            class="accordion-button px-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            Why do I need to complete identity verification?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            {" "}
                            Identity verification is crucial to safeguard the
                            security and integrity of your account. By verifying
                            your personal information and background, we can
                            effectively prevent unauthorized use of your
                            identity for fraudulent or illegal purposes.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                          <button
                            class="accordion-button px-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            Which documents and information are required for
                            identity verification?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            For identity verification, you usually need to
                            provide proof of identity (e.g., passport, driver's
                            license, or ID card) and personal photographs.
                            Additional documents may be required in certain
                            cases. Rest assured that your information is
                            protected and used only for legal and compliance
                            purposes. For any questions about the identity
                            verification requirements of specific institutions,
                            contact them for accurate information.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button
                            class="accordion-button px-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapse4"
                            aria-expanded="false"
                            aria-controls="flush-collapse4"
                          >
                            Where can I find help for problems with identity
                            verification?
                          </button>
                        </h2>
                        <div
                          id="flush-collapse4"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-collapse4"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            If you encounter any problems with identity
                            verification, you can get help by joining our
                            Telegram group. Our customer service team will be
                            happy to answer any questions you may have and
                            provide further support. Please provide us with
                            information about your problems so that we can help
                            resolve your issue as quickly as possible.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      {/*=========== Modal of deposit menu==================== */}

      <div
        id="kyc_modal"
        class="modal fade"
        tabindex="-1"
        data-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-lg modal-dialog-centered modal_600_width">
          <div class="modal-content">
            <div class="modal-header border-0">
              <h4 class="modal-title">Identity Verification</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="" style={{ minHeight: "375px" }}>
                {/* Choose Types of verfication */}
                {personalTab==0?
                <>
                <div id="verification_type" className={isSubmitPersonalData==0?'':'d-none'}>
                  <div class="mb-3 cstm_form">
                    <label className="text-muted fs-12 mb-1">
                      <span className="text-red">*</span>Country/Region
                    </label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e)=>{
                        setUserCountry(e.target.value)
                      }}
                    >
                      {countryData
                        ? countryData.map((country) => (
                            <option
                              value={country}
                              selected={
                                country == "India"
                                  ? "selected"
                                  : ""
                              }
                             
                            >
                              {country}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>

                  <div className="mb-5">
                    <label className="text-muted fs-12">
                      <span className="text-red">*</span>Verify With
                    </label>
                    {/* <a href="/AssetsDeposit" className="text-dark"> */}
                      <div class="modal_select_options p-3 mt-1"
                        onClick={(e)=>{
                          setSubmitPersonalData(1);
                          setType('passport')
                        }}
                      >
                        <img
                          src="/img/passport.svg"
                          alt="coin symbole"
                          class="deposit_imgs filter_gray"
                        />
                        <div class="content_div">
                          <div class="mb-0">Passport</div>
                        </div>{" "}
                        <span>
                          <i class="fa-solid fa-arrow-right-long ms-1"></i>
                        </span>
                      </div>
                    {/* </a> */}
                    {/* <a href="/AssetsFiat" className="text-dark"> */}
                      <div 
                        class="modal_select_options p-3" 
                        onClick={(e)=>{
                          setSubmitPersonalData(1);
                          setType('id')
                        }}>
                        <img
                          src="/img/id-card.svg"
                          alt="coin symbole"
                          class="deposit_imgs filter_gray"
                        />
                        <div class="content_div">
                          <div class="mb-0">ID Card</div>
                        </div>{" "}
                        <span>
                          <i class="fa-solid fa-arrow-right-long ms-1"></i>
                        </span>
                      </div>
                    {/* </a> */}

                    {/* <a href="/AssetsFiat" className="text-dark"> */}
                      <div class="modal_select_options p-3"
                      onClick={(e)=>{
                        setSubmitPersonalData(1);
                        setType('driverlicense')
                      }}>
                        <img
                          src="/img/dl.svg"
                          alt="coin symbole"
                          class="deposit_imgs filter_gray"
                        />
                        <div class="content_div">
                          <div class="mb-0">Driver's License</div>
                        </div>{" "}
                        <span>
                          <i class="fa-solid fa-arrow-right-long ms-1"></i>
                        </span>
                      </div>
                    {/* </a> */}
                  </div>
                </div>

                {/* Fill Personal Info and ID Number */}
                <div className={isSubmitPersonalData==1?'':'d-none'} id="personal_info">
                  <div class="steps_div">
                    <div class="d-flex align-items-center">
                      <div class="circle_no active">1</div>
                      <span class="text-lgray">Personal Information</span>
                    </div>
                    <div class="adjacent_line"></div>
                    <div class="d-flex align-items-center">
                      <div class="circle_no">2</div>
                      <span class="text-lgray">ID Verification</span>
                    </div>
                    <div class="adjacent_line"></div>
                    <div class="d-flex align-items-center">
                      <div class="circle_no">3</div>
                      <span class="text-lgray">Face Verification</span>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-6">
                      <div className="cstm_form">
                        <div class="form-group">
                          <label for="first_name">First Name</label>
                          <input type="text" class="form-control" id="first_name" name="first_name" 
                          onChange={(e)=>{
                            setFirstName(e.target.value)
                          }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div class="form-group">
                        <label for="last_name">Last Name</label>
                        <input type="text" class="form-control" id="last_name" name="last_name"
                        onChange={(e)=>{
                          setLastName(e.target.value);
                        }} />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    {" "}
                    <div class="form-group">
                      <label for="id_no">ID Number</label>
                      <input type="text" class="form-control" id="id_no" name="id_no" 
                      onChange={(e)=>{
                        setIdNo(e.target.value)
                      }}/>
                    </div>
                  </div>

                  <p className="fs-12 text-lgray mb-3">
                    Please read the User Identity Verification Statement and
                    Jumio Privacy Policy to understand how your personal
                    information and biometric data is collected and used for
                    verification purposes. Click the Continue button to indicate
                    that you have read and agree to the statement and policy.
                  </p>
                   <div className="d-grid">
                      <button className="btn btn-warning my-2"
                        onClick={(e)=>{
                          updatePersonal(e);
                        }}
                      >
                        Continue
                      </button>
                    </div>
                </div>

                {/* info Submitted succesfully*/}
                <div className={isSubmitPersonalData==2?'':'d-none'} id="submitted_successfully">
                  <div className="text-center">
                    <img src="/img/submitted.svg" alt="submitted" />
                  </div>
                  <h4 class="big_heading text-center">
                    Personal Information Submitted
                  </h4>

                  <div class="jambotron py-3 mb-5 mt-4">
                    <p className="text-lgray fs-12">
                      Your Lv. 1 identity verification has been completed.
                      Account security enhanced and withdrawal limit increased.
                    </p>

                    <div class="col-md-12">
                      <div class="horizontal_display_with_justify mb-2">
                        <div>
                          <small class="text-lgray ">Current Features</small>
                        </div>

                        <div>
                          <h5 class="mb-0 h6">499 USDT</h5>
                        </div>
                      </div>
                      <div class="horizontal_display_with_justify ">
                        <div>
                          <small class="text-lgray">Nex Level</small>
                        </div>

                        <div>
                          <h5 class="mb-0 h6">1,000,000 USDT</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                   <div className="d-grid">
                      <button className="btn btn-warning my-2"
                        onClick={()=>{
                          setPersonalTab(1);
                        }}
                      >
                        Continue
                      </button>
                    </div>
                </div>
                </>
                :null}
                {personalTab == 1?
                <>
                {/* Start Verification */}
                <div className={id_verification==0?'':'d-none'} id="start_verification">
                  <div class="steps_div">
                    <div class="d-flex align-items-center">
                      <div class="circle_no">1</div>
                      <span class="text-lgray">Personal Information</span>
                    </div>

                    <div class="adjacent_line"></div>
                    <div class="d-flex align-items-center">
                      <div class="circle_no active">2</div>
                      <span class="text-lgray">
                        ID Verification & Face Verification
                      </span>
                    </div>
                  </div>

                  <h4 class="big_heading text-center">
                    <BiSolidCheckShield className="me-1 text-green" /> Start
                    Verfication
                  </h4>
                  <div className="row mt-3">
                    <div className="col-lg-9 m-auto col-12 mb-3">
                      <div className="jambotron">
                        <p>
                          {" "}
                          This process is designed to verify your identity and
                          protect you from identity theft.
                        </p>

                        <p>Please have your ID ready and click 'Start'. </p>
                      </div>

                      <div className="d-flex align-items-center gap-1 my-3">
                        <div>
                          {" "}
                          <BiError className="text-warning fs-4" />{" "}
                        </div>
                        <div>
                          This page will expire in a few minutes if you do not
                          start.
                        </div>
                      </div>

                      <div className="d-grid mb-2">
                        <button className="btn btn-primary btn-lg"
                        onClick={(e)=>{
                          setIdVerifocation(1)
                        }}>
                          Start
                        </button>
                      </div>
                      <p className="small text-muted">
                        By clicking “Start” you consent to Jumio collecting and
                        disclosing your biometric data pursuant to its{" "}
                        <a href="#" className="text-info">
                          Privacy Policy
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </div>

                {/* choose method to upload Documents */}
                <div className={id_verification == 1?"":"d-none"} id="choose_method">
                  <div class="steps_div">
                    <div class="d-flex align-items-center">
                      <div class="circle_no">1</div>
                      <span class="text-lgray">Personal Information</span>
                    </div>

                    <div class="adjacent_line"></div>
                    <div class="d-flex align-items-center">
                      <div class="circle_no active">2</div>
                      <span class="text-lgray">
                        ID Verification & Face Verification
                      </span>
                    </div>
                  </div>

                  <h4 class="big_heading text-center">
                    Choose an Upload Method
                  </h4>
                  <div className="row mt-3">
                    <div className="col-lg-9 m-auto col-12 mb-3">
                      {/* <div className=" mb-2">
                        <a href="#" className="upload_btn active">
                          <BiMobileAlt className="me-1 fs-4" /> Continue On
                          mobile
                        </a>
                      </div>
                      <div className=" mb-2">
                        <a href="#" className="upload_btn">
                          <AiOutlineCamera className="me-1 fs-4" /> Take photo
                          using webcam
                        </a>
                      </div> */}
                      <div className=" mb-2">
                        <button className="upload_btn"
                        onClick={(e)=>{
                          setIdVerifocation(2)
                        }}>
                          <AiOutlineCloudUpload className="me-1 fs-4" /> Upload
                          file from this device
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Upload front of ID Card */}
                <div className={id_verification==2?'':'d-none'}  id="upload_doc">
                  <div class="steps_div">
                    <div class="d-flex align-items-center">
                      <div class="circle_no">1</div>
                      <span class="text-lgray">Personal Information</span>
                    </div>

                    <div class="adjacent_line"></div>
                    <div class="d-flex align-items-center">
                      <div class="circle_no active">2</div>
                      <span class="text-lgray">
                        ID Verification & Face Verification
                      </span>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-9 m-auto col-12 mb-3">
                      <div className="jambotron border-dashed text-center">
                        <div className="dummy-card-photo">
                          {" "}
                          <img
                            src="/img/placeholder-id-front.svg"
                            className="img-fluid"
                            alt="placeholder-id-front"
                          />
                        </div>
                        <h4 class="big_heading text-center">
                          Upload front of identity card
                        </h4>

                        <div className="badge bg-dark fs-12 my-2">
                          Step 1 of 3
                        </div>
                        <p>
                          Upload a color image of the entire document.
                          Screenshots are not allowed. JPG, JPEG or PNG format
                          only.
                        </p>
                        <div className="d-grid">
                        <input
                            id="upload_front_adhar"
                            type="file"
                            onChange={(e) => {
                              readURL(e);
                            }}
                            className="form-control kyc_image--"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                 {/* Preview of photo */}
                 <div className={id_verification==3?'':'d-none'} id="preview_photo">
                  <div class="steps_div">
                    <div class="d-flex align-items-center">
                      <div class="circle_no">1</div>
                      <span class="text-lgray">Personal Information</span>
                    </div>

                    <div class="adjacent_line"></div>
                    <div class="d-flex align-items-center">
                      <div class="circle_no active">2</div>
                      <span class="text-lgray">
                        ID Verification & Face Verification
                      </span>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-9 m-auto col-12 mb-3">
                      <div className="jambotron border-dashed">
                        <div className="photo-preview">
                          {" "}
                          <img
                            src="/img/placeholder-id-front.svg"
                            className="img-fluid"
                            id="check_photo"
                            alt="placeholder-id-front"
                          />
                        </div>
                        <h4 class="big_heading text-center">
                          Check Photo
                        </h4>

                        <ul className="my-3">
                          <li>Have you checked if your ID is supported? </li>
                          <li>Can you read the details?</li>
                          <li>Can you see the whole ID?</li>
                        </ul>
                     
                        <div className="text-center">
                          <button className="btn btn-secondary my-2 me-2"
                          onClick={(e)=>{
                            setIdVerifocation(2)
                          }}
                          >
                            Try again
                          </button>
                          <button className="btn btn-primary my-2"
                           onClick={(e) => {
                            N_uploadID(
                              e,
                              filedata,
                              "docf",
                              user?.params ? user.params.user_id : user.user_id
                            );
                          }}>
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </>
                :null}

              </div>
              {/* <div className="d-grid">
                <a href="#" className="btn btn-warning btn-lg">
                  Continue
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
