import React, { useState } from "react";
import Header from "./HomeComp/Header";
import { Link } from "react-router-dom";
import {
  isCpass,
  isEmail,
  isNum,
  isPass
} from "./redux/helpers/form-validator.functions";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { NotificationManager } from "react-notifications";
import { useGoogleLogin } from "@react-oauth/google";
// import { NotificationManager } from "react-notifications";
import { AUTH_LOGIN } from "./redux/constant";
// import { ConnectButton } from "@rainbow-me/rainbowkit";
import {
  N_sendOTP,
  N_userRegister,
  N_verifyOTPNew,
  socialLogin
} from "./redux/helpers/api_functions_new";
// import { namehash } from "viem";

export default function Register(props) {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState();
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState();
  const [password, setPassword] = useState();
  const [confPassword, setConfPassword] = useState();
  const [activetab, setActivetab] = useState(0);
  const [referral_code, setReferral] = useState(
    props?.match?.params?.id ? props?.match?.params?.id : ""
  );
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState();
  const [eye, seteye] = useState(false);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  if (isLoggedIn) props.history?.replace("/");
  const hsPassword = (_id) => {
    var _idv = document.querySelector("#" + _id);
    if (_idv.getAttribute("type") == "text") {
      _idv.setAttribute("type", "password");
      seteye(false);
    } else {
      _idv.setAttribute("type", "text");
      seteye(true);
    }
  };

  const otpSend = () => {
      if ((isEmail(email) || isNum(phone)) && isPass(password)) {
        if (agree) {
          $(".main_terms").removeClass("border-danger");
          setLoading(true);
          N_userRegister(email, phone, password, referral_code)
          .then((res) => {
            if (res.status === 200) {
              NotificationManager.success(res.message);
              setIsOTPSent(true);
            } else {
              NotificationManager.error(res.message);
            }
            setLoading(false);
          })
      .catch((e) => {
        console.log("n-user_reg err", e);
      });
        } else {
          NotificationManager.error("Please Agree with term condition !");
          $(".main_terms").addClass("border-danger");
        }
      } else {
        NotificationManager.error("Please fill all data!");
      }
    
  };

  const onSubmit = (e) => {
    e.preventDefault();
    N_verifyOTPNew(
      email,
      phone,
      otp
    ).then((res) => {
      if (res.status == 200) {
        NotificationManager.success(
          res.message
        );
        dispatch({ type: AUTH_LOGIN, data: res });

      } else {
        NotificationManager.error(
          res.message
        );
      }
    });
    
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      socialLogin(codeResponse.access_token).then((res) => {
        if (res.status) {
          NotificationManager.success(res.message);
          dispatch({ type: AUTH_LOGIN, data: res });
          props.history?.replace("/");
        } else {
          NotificationManager.success(res.message);
        }
      });
    },
    onError: (error) => console.log("Login Failed:", error)
  });

  return (
    <>
      <Header {...props} />

      <div class="container-fluid">
        <div className="page-content">
        <div class="row no-gutter">
          <div class="col-md-6 d-none d-lg-block login_left_bg p-0">
           
            <div className="d-flex justify-content-center align-items-center login_page_minheight">
              <div class="text-center">
                <img
                  src="/img/new-user-registration.png"
                  className="register-img"
                />
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="d-flex align-items-center login_page_minheight">
              <div class="container">
                <div class="row">
                  {isOTPSent?
                  <div class="m-auto login_window signupform cstm_form">
                    <div className="page-content-title">
                      <h3 className="mb-lg-5">Enter the 6-digit verification code we sent to {email}</h3>
                      <h6>For security, we need to verify it's you.</h6>
                    </div>
                    <form
                      className=""
                      method="post"
                      id="signupform"
                    >
                      <div className="form-group">
                        <small id="msg">Error Message</small>
                      </div>
                      <div className={`form-group`}>
                        
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            name="user_otp"
                            id="user_otp"
                            required=""
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            placeholder="Enter Verfication Code"
                          />
                          <span className="input-group-text">
                            <div className="">
                              <div className="">
                                <button
                                  className="btn border-0 text-primary border-start rounded-0"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    let dt = email?0:1;
                                    N_sendOTP(email, phone, dt).then(
                                      (res) => {
                                        if (res.status == 200) {
                                          NotificationManager.success(
                                            res.message
                                          );
                                        } else {
                                          NotificationManager.error(res.message);
                                        }
                                      }
                                    );
                                  }}
                                >
                                  Resend
                                </button>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="d-grid mt-3">
                        <button
                          type="button"
                          id="btn_submit"
                          className="btn login_btn"
                          onClick={(e)=>{
                            onSubmit(e);
                          }}
                        >
                          {loading ? (
                            <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                          ) : null}
                          <span id="reg">Verify</span>
                        </button>
                      </div>
                    </form>
                  </div>:
                   <div class="m-auto login_window signupform cstm_form">
                   <div className="page-content-title">
                     <h3 className="mb-lg-5">Create Account</h3>
                   </div>
                   {/* <div className="custom_pills">
                     <nav
                       className="nav nav-pills mb-3"
                       id="myTab"
                       role="tablist"
                     >
                       <button
                         className="nav-link active"
                         id="home-tab"
                         data-bs-toggle="tab"
                         data-bs-target="#home"
                         type="button"
                         role="tab"
                         aria-controls="home"
                         aria-selected="true"
                         style={{ height: "28px" }}
                         onClick={() => {
                           setActivetab(0);
                         }}
                       >
                         Email
                       </button>

                       <button
                         className="nav-link"
                         id="profile-tab"
                         data-bs-toggle="tab"
                         data-bs-target="#profile"
                         style={{ height: "28px" }}
                         type="button"
                         role="tab"
                         aria-controls="profile"
                         aria-selected="false"
                         onClick={() => {
                           setActivetab(1);
                         }}
                       >
                         Phone Number
                       </button>
                     </nav>
                   </div> */}

                   <form
                     className=""
                     method="post"
                     id="signupform"
                   >
                     <div className="form-group">
                       <small id="msg">Error Message</small>
                     </div>
                     {activetab == 0 ? (
                       <div className={`form-group`}>
                         <label htmlFor="user email" className="text-gradient mb-2 fw-bold">
                           E-mail
                         </label>
                         <input
                           type="email"
                           name="user_email"
                           autoComplete="off"
                           readOnly
                           onFocus={(e) => {
                             e.target.removeAttribute("readOnly");
                           }}
                           value={email}
                           onChange={(e) => setEmail(e.target.value)}
                           className="form-control"
                           id="user_email"
                           placeholder="E-mail"
                         />
                         {/* <i className="fas fa-check-circle"></i>
                         <i className="fas fa-exclamation-circle"></i>
                         <small></small> */}
                       </div>
                     ) : (
                       <div className={`form-group`}>
                         <label htmlFor="phone" className="fw-bold text-gradient mb-2">Phone Number</label>
                         <input
                           type="phone"
                           name="user_phone"
                           autoComplete="off"
                           readOnly
                           onFocus={(e) => {
                             e.target.removeAttribute("readOnly");
                           }}
                           value={phone}
                           onChange={(e) => setPhone(e.target.value)}
                           className="form-control"
                           id="user_phone"
                           placeholder="Mobile Number"
                         />
                         {/* <i className="fas fa-check-circle"></i>
                         <i className="fas fa-exclamation-circle"></i>
                         <small></small> */}
                       </div>
                     )}
                     {/* {!isOTPVerified ? (
                       <>
                         <div className={`form-group d-grid`}>
                           <button
                             className="btn btn-secondary mb-2"
                             onClick={(e) => {
                               e.preventDefault();
                               if (isEmail(email)) {
                                 N_sendOTP(email, phone, activetab).then(
                                   (res) => {
                                     console.log("res", res.status);
                                     if (res.status == 200) {
                                       setIsOTPSent(true);
                                       NotificationManager.success(
                                         res.message
                                       );
                                     } else {
                                       NotificationManager.error(res.message);
                                     }
                                   }
                                 );
                               } else {
                                 NotificationManager.error(
                                   "Enter Correct Email!"
                                 );
                               }
                             }}
                             disabled={isSendingOTP}
                           >
                              {isOTPSent
                                   ? "Didn't receive the OTP? Resend "
                                   : "Send Email Verification Code"}
                             </button>
                             {isOTPSent && (
                               <div className={`form-group`}>
                                
                                 <div className="input-group">
                                   <input
                                     type="number"
                                     className="form-control"
                                     name="user_otp"
                                     id="user_otp"
                                     required=""
                                     value={otp}
                                     onChange={(e) => setOtp(e.target.value)}
                                     placeholder="Enter Verfication Code"
                                   />

                                   <span className="input-group-text">
                                     {!isOTPVerified && (
                                       <div className="">
                                         <div className="">
                                           <button
                                             className="btn border-0 text-primary border-start rounded-0"
                                             onClick={(e) => {
                                               setIsSendingOTP(true);
                                               e.preventDefault();
                                               N_verifyOTPNew(
                                                 email,
                                                 phone,
                                                 otp
                                               ).then((res) => {
                                                 if (res.status == 200) {
                                                   setIsOTPVerified(true);
                                                   NotificationManager.success(
                                                     res.message
                                                   );
                                                 } else {
                                                   NotificationManager.error(
                                                     res.message
                                                   );
                                                 }
                                               });
                                             }}
                                           >
                                             Verify Code
                                           </button>
                                         </div>
                                       </div>
                                     )}
                                   </span>
                                 </div>
                               </div>
                             )}
                           </div>
                       </>
                     ) : null} */}

                     <div className={`form-group`}>
                       <label htmlFor="user_password" className="fw-bold text-gradient mb-2"> Password</label>
                       <div className="input-group">
                         <input
                           type="password"
                           className="form-control"
                           name="user_password"
                           value={password}
                           onChange={(e) => setPassword(e.target.value)}
                           id="pass"
                           placeholder="Choose Password"
                         />
                         <span className="input-group-text">
                           <a
                             href="#view_qr"
                             className=""
                             onClick={(e) => {
                               hsPassword("pass");
                             }}
                           >
                             {eye ? (
                               <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="16"
                                 height="16"
                                 fill="#666"
                                 className="bi bi-eye-slash-fill"
                                 viewBox="0 0 16 16"
                               >
                                 <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                 <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                               </svg>
                             ) : (
                               <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="16"
                                 height="16"
                                 fill="#666"
                                 className="bi bi-eye-fill"
                                 viewBox="0 0 16 16"
                               >
                                 <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                 <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                               </svg>
                             )}
                           </a>
                         </span>
                       </div>
                       {/* <i className="fas fa-check-circle"></i> */}
                       {/* <i className="fas fa-exclamation-circle"></i> */}
                       <div className="small text-gradient mt-1 mb-3 fs-10">
                         Password must be contain atleast 1 Capital charcter ,
                         1 digit , 1 symbol and length min 8 digits
                       </div>
                       <small
                         id="passerr"
                         className="text-danger"
                         // style={{ top: "53%" }}
                       ></small>
                     </div>
                     {/* <div className={`form-group`}>
                       <div className="input-group mb-3">
                         <input
                           type="password"
                           className="form-control"
                           name="user_cpassword"
                           id="cpass"
                           required=""
                           value={confPassword}
                           onChange={(e) => setConfPassword(e.target.value)}
                           placeholder="Confirm Password"
                         />
                         <span className="input-group-text">
                           <a
                             href="#view_qr"
                             className=""
                             onClick={(e) => {
                               hsPassword("cpass");
                             }}
                           >
                             <svg
                               xmlns="http://www.w3.org/2000/svg"
                               width="16"
                               height="16"
                               fill="#666"
                               className="bi bi-eye"
                               viewBox="0 0 16 16"
                             >
                               <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                               <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                             </svg>
                           </a>
                         </span>
                       </div>
                       <small id="cpasserr" className="text-danger"></small>
                     </div> */}
                     <div className="form-group referral_code">
                       <a
                         className="referral_code"
                         data-bs-toggle="collapse"
                         data-bs-target="#referral_code"
                         aria-expanded="false"
                       >
                         Referral Code (Optional)
                         <span class="fa-solid fa-chevron-down ms-2"></span>
                         <span class="fa-solid fa-chevron-up ms-2"></span>
                       </a>
                       <div className="collapse mt-2" id="referral_code">
                         <input
                           type="text"
                           className="form-control"
                           name="refercode"
                           id="refercode"
                           required=""
                           value={referral_code}
                           onChange={(e) => setReferral(e.target.value)}
                           placeholder="Referral code"
                         />
                       </div>
                     </div>
                     <div className="d-flex align-items-center mt-2">
                       <input
                         className=""
                         type="checkbox"
                         id="agree_terms"
                         name="agree_terms"
                         checked={agree}
                         onChange={(e) => setAgree(e.target.checked)}
                       />
                       <span className="terms">
                         I agree to {webData.website_title}'s
                         <span className="ms-1 terms_condition">
                           <a
                             href="/terms_&_conditions"
                             target="_blank"
                             className="text-gradient"
                           >
                             Terms of Service
                           </a>{" "}
                         </span>
                       </span>
                     </div>
                     <div className="d-grid mt-3">
                       <button
                         type="button"
                         id="btn_submit"
                         className="btn login_btn"
                         onClick={(e)=>{
                          otpSend();
                         }}
                       >
                         {loading ? (
                           <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                         ) : null}
                         <span id="reg">Create Account</span>
                       </button>
                     </div>

                     {/* <div className="my-2 text-center">OR</div> */}
                     <div className="d-grid my-3">
                       {/* <button
                         className="login-with-google-btn"
                         onClick={() => googleLogin()}
                       >
                         <img
                           src="./img/google.png"
                           width={20}
                           className="me-2"
                         />{" "}
                         Sign in with Google
                       </button> */}

                       {/* <ConnectButton.Custom>
                         {({
                           account,
                           chain,
                           openAccountModal,
                           openChainModal,
                           openConnectModal,
                           authenticationStatus,
                           mounted
                         }) => {
                           // Note: If your app doesn't use authentication, you
                           // can remove all 'authenticationStatus' checks
                           const ready =
                             mounted && authenticationStatus !== "loading";
                           const connected =
                             ready &&
                             account &&
                             chain &&
                             (!authenticationStatus ||
                               authenticationStatus === "authenticated");

                           return (
                             <div
                               className="d-grid"
                               {...(!ready && {
                                 "aria-hidden": true,
                                 style: {
                                   opacity: 0,
                                   pointerEvents: "none",
                                   userSelect: "none"
                                 }
                               })}
                             >
                               {(() => {
                                 if (!connected) {
                                   return (
                                     <div className="d-grid mt-3 text-center">
                                       <button
                                         class="btn login_btn_outline"
                                         onClick={openConnectModal}
                                         type="button"
                                       >
                                         <i className="fa fa-wallet fs-5 me-2 text-primary"></i>{" "}
                                         Sign in With Wallet
                                       </button>
                                     </div>
                                   );
                                 }

                                 if (chain.unsupported) {
                                   return (
                                     <button
                                       onClick={openChainModal}
                                       type="button"
                                     >
                                       Wrong network
                                     </button>
                                   );
                                 }

                                 return (
                                   <div style={{ display: "flex", gap: 12 }}>
                                     <button
                                       onClick={openChainModal}
                                       style={{
                                         display: "flex",
                                         alignItems: "center"
                                       }}
                                       type="button"
                                     >
                                       {chain.hasIcon && (
                                         <div
                                           style={{
                                             background: chain.iconBackground,
                                             width: 12,
                                             height: 12,
                                             borderRadius: 999,
                                             overflow: "hidden",
                                             marginRight: 4
                                           }}
                                         >
                                           {chain.iconUrl && (
                                             <img
                                               alt={chain.name ?? "Chain icon"}
                                               src={chain.iconUrl}
                                               style={{
                                                 width: 12,
                                                 height: 12
                                               }}
                                             />
                                           )}
                                         </div>
                                       )}
                                       {chain.name}
                                     </button>

                                     <button
                                       onClick={openAccountModal}
                                       type="button"
                                     >
                                       {account.displayName}
                                       {account.displayBalance
                                         ? ` (${account.displayBalance})`
                                         : ""}
                                     </button>
                                   </div>
                                 );
                               })()}
                             </div>
                           );
                         }}
                       </ConnectButton.Custom> */}

                       <div className="col-lg-12">
                         <div className=" text-center  mt-2">
                           Already have an account ?
                           <Link className="text-info " to="/login">
                             {" "}
                             Log In{" "}
                           </Link>
                         </div>
                       </div>
                     </div>
                   </form>
                 </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}
