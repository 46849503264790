import React from "react";
import { NavLink } from "react-router-dom";
import "./sidebar.css";
import { useSelector } from "react-redux";

import {  BsFiletypeCsv,  } from "react-icons/bs";

import { TbPlugConnected }from "react-icons/tb"
import { MdOutlineSwitchAccount, MdSecurity } from "react-icons/md";
import { FiPieChart, FiUserCheck } from "react-icons/fi";
import { HiBarsArrowDown } from "react-icons/hi2";

export default function ProfileSidebar(props) {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  if (!isLoggedIn) props.history?.replace("/login");
  return (
    <>
     <a class="s-sidebar__trigger" href="#0">
      <span><HiBarsArrowDown className="fs-2 text-pink"/>  All Menu </span>
      {/* <i className="fa fa-bars"></i> */}
      </a>

      <nav class="s-sidebar__nav">
        <li>
          <NavLink className="s-sidebar__nav-link" to="/profile">
           <FiPieChart className="fs-4 "/>  <span className="">Dashboard</span>
          </NavLink>
        </li>

        <li>
          <NavLink className="s-sidebar__nav-link" to="/AccountSecurity">
         <MdSecurity className="fs-4 "/><span className="">Security <sup className="upper_label"></sup></span>
          </NavLink>
        </li>

        <li>
          <NavLink className="s-sidebar__nav-link" to="/AccountVerification">
           <FiUserCheck className="fs-4 "/>
            <span className="">Identity Verification</span>
          </NavLink>
        </li>

        <li>
          <NavLink className="s-sidebar__nav-link" to="/ApiManagement">
           <TbPlugConnected className="fs-4 "/>
            <span className="">API Management</span>
          </NavLink>
        </li>

        <li>
          <NavLink className="s-sidebar__nav-link" to="/subAccount">
           <MdOutlineSwitchAccount className="fs-4 "/> <span className="">Sub-Accounts</span>
          </NavLink>
        </li>

        <li>
          <NavLink className="s-sidebar__nav-link" to="/DownloadCsv">
           <BsFiletypeCsv className="fs-4 "/> <span className="">Download CSV</span>
          </NavLink>
        </li>
        {/* <li>
          <NavLink className="s-sidebar__nav-link" to="/currency_preference">
            <i className="fa fa-usd " />{" "}
            <span className="">Currency preference</span>
          </NavLink>
        </li>
        <li>
          <NavLink className="s-sidebar__nav-link" to="/referral">
            <i className="fa fa-tree " /> <span className="">Referral</span>
          </NavLink>
        </li>
        <li>
          <NavLink className="s-sidebar__nav-link" to="/user_kyc">
            <i className="fa fa-book " />
            <span className=""> Verify KYC</span>
          </NavLink>
        </li>
        <li>
          <NavLink className="s-sidebar__nav-link" to="/payment_option">
            <i className="fa fa-usd " />
            <span className=""> Payment options</span>
          </NavLink>
        </li>
        <li>
          <NavLink className="s-sidebar__nav-link" to="/notification">
            <i className="fa fa-bell " />
            <span className=""> Notification preferences</span>
          </NavLink>
        </li>
        <li>
          <NavLink className="s-sidebar__nav-link" to="/fees">
            <i className="fa fa-inr" />
            <span className=""> Fees</span>
          </NavLink>
        </li>
        <li>
          <NavLink className="s-sidebar__nav-link" to="/2fa">
            <i className="fa fa-shield" />
            <span className=""> Two Factor Authontication</span>
          </NavLink>
        </li>
        <li>
          <NavLink className="s-sidebar__nav-link" to="/activity_log">
            <i className="fa fa-info" /> <span className="">Activity Logs</span>
          </NavLink>
        </li>
        <li>
          <NavLink className="s-sidebar__nav-link" to="/download_report">
            <i className="fa fa-file " />{" "}
            <span className="">Download trading report</span>
          </NavLink>
        </li>
        <li>
          <NavLink className="s-sidebar__nav-link" to="/privacy_control">
            <i className="fa fa-shield " />{" "}
            <span className="">Privacy Control</span>
          </NavLink>
        </li>
        <li>
          <NavLink className="s-sidebar__nav-link" to="/coupan">
            <i className="fa fa-gift " />{" "}
            <span className="">Coupon Rewards</span>
          </NavLink>
        </li>
        <li>
          <NavLink className="s-sidebar__nav-link" to="/upcoming_program">
            <i className="fa fa-lock " />
            <span className=""> Unlock Programs</span>
          </NavLink>
        </li> */}
      </nav>

      {/* <div className="modified_collapse sidenav_toggle">
        <button
          class="btn text-white border-0 text-left d-block d-lg-none sidebar_toggle collapsed"
          data-bs-toggle="collapse"
          href="#collapseExample"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          Sidebar
        </button>

        <div class="sidebar collapse" id="collapseExample">
          <NavLink to="/profile">
            <i className="fa fa-user " /> <span className="">Profile</span>
          </NavLink>

          <NavLink to="/currency_preference">
            <i className="fa fa-usd " /> <span className="">Currency preference</span>
          </NavLink>

          <NavLink to="/referral">
            <i className="fa fa-tree " /> <span className="">Referral</span>
          </NavLink>

          <NavLink to="/user_kyc">
            <i className="fa fa-book " /><span className=""> Verify KYC</span>
          </NavLink>

          <NavLink to="/payment_option">
            <i className="fa fa-usd " /><span className=""> Payment options</span>
          </NavLink>

          <NavLink to="/notification">
            <i className="fa fa-bell " /><span className=""> Notification preferences</span>
          </NavLink>

          <NavLink to="/fees">
            <i className="fa fa-inr " /><span className=""> Fees</span>
          </NavLink>

          <NavLink to="/2fa">
            <i className="fa fa-shield " /><span className=""> Two Factor Authontication</span>
            
          </NavLink>

          <NavLink to="/activity_log">
            <i className="fa fa-info " /> <span className="">Activity Logs</span>
          </NavLink>

          <NavLink to="/download_report">
            <i className="fa fa-file " /> <span className="">Download trading report</span>
            
          </NavLink>

          <NavLink to="/privacy_control">
            <i className="fa fa-shield " /> <span className="">Privacy Control</span>
          </NavLink>

          <NavLink to="/coupan">
            <i className="fa fa-gift " /> <span className="">Coupon Rewards</span>
          </NavLink>

          <NavLink to="/upcoming_program">
            <i className="fa fa-lock " /><span className=""> Unlock Programs</span>
          </NavLink>
        </div>
      </div> */}
    </>
  );
}
