import React, { useEffect, useState } from "react";
import Header from "./HomeComp/Header";
import ProfileSidebar from "./components/ProfileSidebar";
import { N_getProfile, N_updateName } from "./redux/helpers/api_functions_new";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./components/Loader";
import { Link } from "react-router-dom";

import {
  AiOutlineCloseCircle,
  AiOutlineEdit,
  AiOutlineRollback,
  AiOutlineUser
} from "react-icons/ai";
import { BsEye, BsEyeSlash, BsShieldCheck } from "react-icons/bs";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FBTCins, FaSortDown, FaSortUp } from "react-icons/fa";
import { Sparklines, SparklinesLine } from "react-sparklines";
import Footer from "./HomeComp/Footer";
import { checkName, getCoinRate, getRound } from "./redux/helpers/helper_functions";
import { NotificationManager } from "react-notifications";
import { FiRotateCcw } from "react-icons/fi";
export default function UserProfile(props) {

  
  const { isLoggedIn, user, profile  } = useSelector((state) => state.AuthReducer);
  const { wallet, paired_curency_price, coins, wallet_loading, campare_currency } = useSelector(
    (state) => state.coinDBReducer
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [hide, setHide] = useState(false);
  const [nodisplay, setDisplay] = useState(false);
  const [name, setName] = useState("");
  const [total_inr, setTotalInr] = useState(0);
  const [total_usdt, setTotalUsdt] = useState(0);
  const updateProfile = (e) => {
      e.preventDefault();
    if (checkName(name)) {
      N_updateName(
        user?.params ? user.params.user_id : user.user_id,
        name
      ).then((res) => {
        if (res.status.matchedCount == 1) {
          setName("");
          setDisplay(!nodisplay);
          N_getProfile(user?.params ? user.params.user_id : user.user_id)
        .then((d) => {
          if (d.status === 200) {
            dispatch({type:'PROFILE', data:d?.params.profile_info})
          }
        })
        .catch((e) => {
          console.log(e);
        });
          NotificationManager.success("Updated Successfully");
        } else {
          NotificationManager.error(res.message);
        }
      });
    } else {
      NotificationManager.error("Enter valid nick Name");
    }
  };

  useEffect(()=>{
    if (!wallet_loading) {
      getWallets();
    }
  }, [wallet, coins, paired_curency_price])

  function getWallets() {
      let total = 0;
      wallet && Object.keys(wallet).map((res, i) => {
        let wallet_type = wallet[res]?.symbol.toUpperCase();
        let rate = getCoinRate(coins, wallet_type);
        let inr_val =rate*(getRound(wallet[res]?.balance));
        total = total + parseFloat(inr_val);
       
      });
      setTotalInr(getRound(total));
      setTotalUsdt(getRound(total/paired_curency_price.USDT))
  }

  let coins1 = Object.values(coins);
  // console.log(coins1)
  const market_child =
  coins1 &&
  coins1.map((item, index) => {
    let usdtprize = coins1 && coins1.find((item) => item.symbol == "USDT");
    if(item.symbol !='INRX' && item.symbol !='USDT')
    return (
      <>
        <tr>
          <td>
            <div className="d-flex align-items-center">
              <div>
                <img
                  src={item.icon}
                  alt="coin symbole"
                  className="market_coin_img"
                  //style={{ width: "50px" }}
                />
              </div>
              <div className="product_name">
                <div className="mb-0 fw-bold">{item.symbol}</div>
                <div className="text-muted fs-12"> {item.name}</div>
              </div>{" "}
            </div>
          </td>
          <td className="text-end">
            $
            {getRound(
              item.current_price / usdtprize.current_price
            )}
          </td>
          <td className="text-end">
            <span
              className={
                item.direction === "up" ? "text-green " : "text-red"
              }
            >
              {getRound(item.price_change_percentage_1h)}%{" "}
              {item.direction === "up" ? (
                <BsArrowUpShort />
              ) : (
                <BsArrowDownShort />
              )}
            </span>
          </td>
          <td className="text-end">{getRound(item.market_cap)}</td>
          <td class="text-end">
            <Sparklines>
              <SparklinesLine
                color="#f705d9"
                data={item.h_trend}
              />
            </Sparklines>

            <Sparklines data={item.h_trend}>
              <SparklinesLine color="red" />
            </Sparklines>
            </td>
            <td class="text-end">
            <a
                href={`/exchange/${item.symbol.toLowerCase()}-${campare_currency}`}
                className="anchor_link"
              >
                Trade
              </a>
            </td>
            
        </tr>
      </>
    );
  });

  return (
    <>
      <Header {...props} />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <ProfileSidebar />
        </div>

        <main className="s-layout__content">
          <div className="container-fluid">
            <div className="account_page">
              <div className="col-lg-12">
                <div className="jambotron">
                  <div className="horizontal_display_with_justify">
                    <div className="flex_row">
                      {" "}
                      <div className="name_circle">
                        {profile?.name
                          ? profile?.name
                              ?.split("")
                              .slice(0, 2)
                              .join("")
                              .toUpperCase()
                          : profile?.email
                              ?.split("")
                              .slice(0, 2)
                              .join("")
                              .toUpperCase()}
                      </div>{" "}
                      <div className="big_heading text-capitalize me-2 text-truncate mob_mw_150">
                        {" "}
                        Hello,{" "}
                        {hide
                          ? "**********"
                          : profile?.name
                          ? profile.name
                          : profile?.email}{" "}
                      </div>
                      <div>
                        {nodisplay ?
                        <>
                          <div className="d-flex gap-1">
                              <div className="">
                                <input
                                  className="form-control text-capitalize"
                                  type="text"
                                  value={name}
                                  onChange={(e) => {
                                    setName(
                                      e.target.value.replace("/a-zA-z*/i", "")
                                    );
                                  }}
                                />
                              </div>

                              <button
                                className="btn btn-pink btn-sm fs-14"
                                onClick={(e) => {
                                  updateProfile(e);
                                }}
                              >
                                Update
                              </button>
                            </div></>
                        :
                         
                          <></>
                           
                          
                        
                        }
                      </div>
                      <div>
                        <span
                          className="cursor_pointer"
                          onClick={() => {
                            setDisplay(!nodisplay);
                            setName(profile?.name)
                          }}
                          // data-bs-toggle="modal"
                          // data-bs-target="#user_modal"
                        >
                          {!nodisplay ? (
                            <>
                              <AiOutlineEdit className="fs-3 ms-2"/>
                            </>
                          ) : (
                            <>
                              <AiOutlineCloseCircle className="fs-2 ms-2 text-danger"/> 
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                    <div>
                      <a
                        className="btn btn_light rounded-pill"
                        onClick={() => {
                          setHide(!hide);
                        }}
                      >
                        {!hide ? (
                          <>
                            <BsEye className="fs-5 me-2" /> Show Info
                          </>
                        ) : (
                          <>
                            <BsEyeSlash className="fs-5 me-2" /> Hide Info
                          </>
                        )}
                      </a>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="horizontal_display_without_justify mt-4">
                      <div className="dashboard_info">
                        <small className="text-lgray text-nowrap">UID</small>
                        <p>
                          {hide
                            ? "***"
                            : user?.params
                            ? user.params.user_id
                            : user.user_id}{" "}
                        </p>
                      </div>

                      <div className="dashboard_info">
                        <small className="text-lgray text-nowrap">
                          Identity Verification
                        </small>
                        <p>
                          <span className="text-pink">
                            {hide ? "***" : "LV3"}
                          </span>
                        </p>
                      </div>

                      <div className="dashboard_info">
                        <small className="text-lgray text-nowrap">
                          Security
                        </small>
                        <p>
                          <BsShieldCheck className="text-pink me-1" />
                          {hide ? "***" : profile.authenticator==2?"High":profile.authenticator==1?"Medium":"Low"}
                        </p>
                      </div>

                      <div className="dashboard_info">
                        <small className="text-lgray  text-nowrap">
                          {" "}
                          Time Zone{" "}
                        </small>
                        <p>
                          {hide ? "***" : Intl.DateTimeFormat().resolvedOptions().timeZone}
                          {/* <AiOutlineEdit /> */}
                        </p>
                      </div>

                      <div className="dashboard_info">
                        <small className="text-lgray text-nowrap">
                          {" "}
                          Last Login{" "}
                        </small>
                        <p className="text-end">
                          {hide
                            ? "***"
                            : new Date(profile.last_login).toString()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-8 mt-4">
                  <div className="jambotron-outline">
                    <div className="horizontal_display_with_justify">
                      <div className="small_heading">Total Assets</div>
                      <div>
                        <a href="" className="anchor_gray">
                          Assets Overview
                        </a>
                        <div className="verticle_line"></div>
                        <a href="" className="anchor_gray">
                          Assets Overview
                        </a>
                      </div>
                    </div>

                    <div className="horizontal_display_with_justify mt-4">
                      <div>
                        {" "}
                        <p>
                          {!hide?total_usdt:"*****"} USDT{" "}
                          <small className="text-lgray">≈ ₨{!hide?total_inr:"*****"}</small>
                        </p>
                      </div>
                      <div>
                        <Link to="/AssetsDeposit" className="btn btn-pink rounded-pill me-2">
                          Deposit
                        </Link>
                        <Link
                          to="/exchange/btc-inrx"
                          className="btn btn-outline-pink rounded-pill me-2"
                        >
                          Buy Crypto
                        </Link>{" "}
                        <Link
                          to="/AssetsWithdraw"
                          className="btn btn-outline-pink rounded-pill"
                        >
                          Withdraw
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <div className="account_page_subheading mb-3">Market</div>

                    <div class="custom_pills">
                      <div class="nav nav-pills" id="nav-tab" role="tablist">
                        <div
                          class="nav-item nav-link active me-4"
                          href="#favourites"
                          data-toggle="tab"
                        >
                          <i className="fa-regular fa-star me-1"></i>
                          Favourites
                        </div>
                        <div
                          class="nav-item nav-link me-4"
                          href="#favourites"
                          data-toggle="tab"
                        ></div>
                        <div
                          class="nav-item nav-link "
                          href="#hot"
                          data-toggle="tab"
                        >
                          🔥 Hot
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div className="tab-pane fade active show" id="favourites">
                      <div className="spacer">
                      <div className="overflow-auto">
                        <div className="table_scroll_div">
                        <table className="table global_table">
                      <thead>
                        <tr>
                          <th>
                            <div className="d-flex align-items-center">
                              <span className="">Product</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="">Price</div>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">24H Change</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">Market Cap</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>

                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">4H Trend</span>
                              
                            </div>
                          </th>


                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">Action</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{market_child}</tbody>
                    </table>
                        </div>
                      </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="hot">
                    <div className="spacer">
                      <div className="overflow-auto">
                        <div className="table_scroll_div">
                        <table className="table global_table">
                      <thead>
                        <tr>
                          <th>
                            <div className="d-flex align-items-center">
                              <span className="">Product</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="">Price</div>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">24H Change</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">Market Cap</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>

                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">4H Trend</span>
                              
                            </div>
                          </th>


                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">Action</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{market_child}</tbody>
                    </table>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 mt-4">
                  <div className="jambotron-outline">
                    <div className="d-flex justify-content-between mb-4">
                      <h5>
                        <img
                          src="https://assets.staticimg.com/ucenter-web/1.0.12/media/8705ed66120a3f345ebd.png"
                          width="30px"
                          alt=""
                        />
                        VIP 0
                      </h5>
                      <div>
                        {" "}
                        <a href="#" className="anchor_gray">
                          View More{" "}
                        </a>{" "}
                      </div>
                    </div>

                    <div className="horizontal_display_with_justify">
                      <div>
                        <span className="small_heading">Spot Fee Rate</span>
                        <div>
                          <span>0.08%</span>{" "}
                          <div className="verticle_line"></div>
                          <span>0.08%</span>
                        </div>
                      </div>

                      <div>
                        <span className="small_heading">Future Fee Rate</span>
                        <div>
                          <span>0.08%</span>{" "}
                          <div className="verticle_line"></div>
                          <span>0.08%</span>
                        </div>
                      </div>
                    </div>

                    <div class="progress mb-2 mt-2" style={{ height: "6px" }}>
                      <div
                        class="progress-bar"
                        role="progressbar"
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <span class="sr-only">70% Complete</span>
                      </div>
                    </div>

                    <div class="dropdown">
                      <button
                        class="btn border-0 fs-12 text-lgray px-0 bg-transparent dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        KCS Holdings
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a class="dropdown-item" href="#">
                            Action
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            Another action
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            Something else here
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="d-flex justify-content-between fs-12 text-lgray">
                      <span>Lv 0</span>
                      <span className="" style={{ width: "80%" }}>
                        Increase your KCS holdings by 999.99 KCS to upgrade to
                        the next level.
                      </span>
                      <span>Lv 1</span>
                    </div>
                  </div>

                  <div className="jambotron mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h5>Invite Friends for Rewards</h5>
                        <div className="text-lgray">
                          Invite friends and get 20% commissions.
                        </div>

                        <div className="mt-3">
                          <a href="#" className="anchor_link">
                            Apply Now
                            <i className="fa-solid fa-arrow-right-long ms-1"></i>{" "}
                          </a>{" "}
                        </div>
                      </div>
                      <div>
                        <img src="/img/steps.png" width="100px" alt="rewards" />
                      </div>
                    </div>
                  </div>

                  <div className="jambotron-outline">
                    <div className="d-flex justify-content-between mb-4">
                      <h5>Information</h5>
                      <div>
                        {" "}
                        <a href="#" className="anchor_gray">
                          View More{" "}
                        </a>{" "}
                      </div>
                    </div>

                    <div className="row">
                      <div className="rounded_box">
                        <h6 className="mb-0">
                          Bitcoin P2P: Get 10% Cashback on Your First USDT
                          Purchase in RUB！
                        </h6>
                        <small className="text-lgray">
                          06/21/2023 07:26:19
                        </small>
                      </div>

                      <div className="rounded_box">
                        <h6 className="mb-0">
                          Announcement of IMVU (VCORE) Token Sale on AMEXA
                          Spotlight
                        </h6>
                        <small className="text-lgray">
                          06/21/2023 07:26:19
                        </small>
                      </div>

                      <div className="rounded_box">
                        <h6 className="mb-0">
                          Join the Spotlight Fiesta: Grab Your Extra VCORE
                          Tickets Now!
                        </h6>
                        <small className="text-lgray">
                          06/15/2023 15:30:01
                        </small>
                      </div>

                      <div className="rounded_box">
                        <h6 className="mb-0">
                          Floki Promotion Campaigns, $14,000 in FLOKI To Share!
                        </h6>
                        <small className="text-lgray">
                          06/14/2023 16:46:13
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="jambotron-outline">
                    <h5 className="mb-4">Start your crypto journey today!</h5>
                    <div className="d-flex gap-1">
                    {" "}
                    {!isLoggedIn ? (
                      <>
                        <a
                          className="btn btn-pink px-3 rounded-pill"
                          to="/create"
                        >
                          Sign Up Now
                        </a>
                      </>
                    ) : (
                      <a
                        className="btn btn-pink px-3 rounded-pill"
                        href="/exchange/rbc-inr"
                      >
                      Trade Now
                      </a>
                    )}
                  


                    <a
                      href=""
                      className="btn btn-outline-secondary rounded-pill px-4"
                    >
                      Scan to Download <img src="/img/qrcode.svg" alt="" />{" "}
                    </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
